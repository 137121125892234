import { lazy } from 'react';

export const PromoContractPage = lazy(
  () =>
    import(
      /* webpackPreload: true */
      /* webpackChunkName: "PromoContractPage" */
      './PromoContractPage'
    ),
);
