import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import { useEffect, useMemo, useState } from 'react';
import { Tooltip, Typography } from '@mui/material';
import { TypeSingleFilterValue } from '@inovua/reactdatagrid-community/types';
import filter from '@inovua/reactdatagrid-community/filter';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';

import { DataGrid, DataGridProps, useDeselectDataGridRows, useResetDataGridFilters } from '@/components/DataGrid';
import { formatDateTime, formatNumberByThousands, highlightKeyword } from '@/helpers/format.helpers';
import { CustomTypography } from '@/components/CustomTypography';
import {
  usePromoContractsStoreActions,
  usePromoContractsStoreFilterValue,
  usePromoContractsStoreIsFilterVisible,
  usePromoContractsStoreSearchQuery,
} from '../promo-contracts.store';
import { PromoContractDataGridRowData } from './types';
import { StatusLabel } from '../components/StatusSelect/StatusLabel';
import { PromoContractsInlineToolbar } from '../toolbars/PromoContractsInlineToolbar';
import { CONTRACTS_PER_PAGE, usePromoContracts } from './usePromoContracts';
import { getFilterData } from './filter.helper';
import { customFilterTypes } from '@/components/DataGrid/data-grid.filter-types';

const INSTANCE_ID = 'promo-contracts-page';

export const defaultFilterValue: TypeSingleFilterValue[] = [
  {
    name: 'startDate',
    type: 'date',
    operator: 'inrange',
    value: '',
  },
  {
    name: 'endDate',
    type: 'date',
    operator: 'inrange',
    value: '',
  },
  {
    name: 'specificationNumber',
    type: 'select',
    operator: 'inlist',
    value: null,
  },
  {
    name: 'contractDocumentNumber',
    type: 'select',
    operator: 'inlist',
    value: null,
  },
  {
    name: 'organizationName',
    type: 'select',
    operator: 'inlist',
    value: null,
  },
  {
    name: 'templateName',
    type: 'select',
    operator: 'inlist',
    value: null,
  },
  {
    name: 'marketingOrgs',
    type: 'select-by-value',
    operator: 'contains',
    value: null,
  },
  {
    name: 'tin',
    type: 'select',
    operator: 'inlist',
    value: null,
  },
  {
    name: 'paymentSum',
    type: 'select',
    operator: 'inlist',
    value: null,
  },
  {
    name: 'recordStatusId',
    type: 'select',
    operator: 'inlist',
    value: null,
  },
];

// ----------------------------------------------------------------------

const SearchQueryAwareText = (props: { text: string }) => {
  const { text } = props;

  const searchQuery = usePromoContractsStoreSearchQuery();
  const preparedValue = searchQuery ? highlightKeyword(text, searchQuery) : text;

  return <>{preparedValue}</>;
};

// ----------------------------------------------------------------------

const getFilteredContracts = (
  promoContracts: PromoContractDataGridRowData[],
  filterValue: TypeSingleFilterValue[],
): PromoContractDataGridRowData[] => {
  const filterResult = filter(promoContracts, filterValue, customFilterTypes);

  return Array.isArray(filterResult) ? filterResult : promoContracts.filter(filterResult);
};

export const PromoContractsDataGrid = () => {
  const { isLoading, promoContracts, hasNextPage, fetchNextPage, loadedPagesLength } = usePromoContracts();

  const filterValue = usePromoContractsStoreFilterValue();
  const [dataSource, setDataSource] = useState<PromoContractDataGridRowData[]>([]);

  const { resetSelectedPromoContract, setSelectedPromoContract, setFilterValue } = usePromoContractsStoreActions();

  const {
    organizationNameFilterData,
    contractTemplateFilterData,
    tinFilterData,
    statusFilterData,
    specificationNumberFilterData,
    paymentSumFilterData,
    marketingOrgsFilterData,
    contractDocumentNumberFilterData,
  } = useMemo(() => getFilterData({ items: promoContracts }), [promoContracts]);

  // ----------------------------------------------------------------------

  const isFiltersPanelVisible = usePromoContractsStoreIsFilterVisible();
  useResetDataGridFilters(INSTANCE_ID, [isFiltersPanelVisible, promoContracts]);
  const { deselectAllRows } = useDeselectDataGridRows(INSTANCE_ID, [promoContracts]);

  const handleSelectionChange: DataGridProps<PromoContractDataGridRowData>['onSelectionChange'] = ({
    data: selectedContract,
  }) => {
    if (!selectedContract) {
      return;
    }

    setSelectedPromoContract(selectedContract);
  };

  useEffect(() => {
    if (promoContracts.length === 0) return;

    const data = getFilteredContracts(promoContracts, filterValue);
    setDataSource(data);
  }, [promoContracts, filterValue]);

  const handleFilterValueChange: DataGridProps<PromoContractDataGridRowData>['onFilterValueChange'] =
    newFilterValue => {
      if (!newFilterValue) return;
      setFilterValue(newFilterValue);

      const contracts = getFilteredContracts(promoContracts, newFilterValue);
      setDataSource(contracts);

      resetSelectedPromoContract();
      deselectAllRows();
    };

  // ----------------------------------------------------------------------

  return (
    <DataGrid
      paginationProps={{
        hasNextPage,
        pagesLength: loadedPagesLength,
        itemsPerPage: CONTRACTS_PER_PAGE,
        onShowMore: fetchNextPage,
      }}
      headerHeight={65}
      instanceId={INSTANCE_ID}
      loading={isLoading}
      onSelectionChange={handleSelectionChange}
      enableFiltering={isFiltersPanelVisible}
      onFilterValueChange={handleFilterValueChange}
      filterValue={filterValue}
      idProperty="id"
      dataSource={dataSource}
      showCellBorders="horizontal"
      columns={[
        {
          name: 'startDate',
          dateFormat: 'DD.MM.YYYY',
          defaultWidth: 100,
          header: 'Дата начала',
          filterEditor: DateFilter,
          filterEditorProps: (_props: React.ComponentProps<typeof DateFilter>, { index }: { index: number }) => ({
            dateFormat: 'DD.MM.YYYY',
            cancelButton: false,
            highlightWeekends: false,
            placeholder: index === 0 ? 'Дата, от' : 'Дата, до',
          }),
          render: ({ value }) => formatDateTime(value, 'dd.MM.yyyy'),
        },
        {
          name: 'endDate',
          dateFormat: 'DD.MM.YYYY',
          defaultWidth: 100,
          header: 'Дата завершения',
          filterEditor: DateFilter,
          filterEditorProps: (_props: React.ComponentProps<typeof DateFilter>, { index }: { index: number }) => ({
            dateFormat: 'DD.MM.YYYY',
            cancelButton: false,
            highlightWeekends: false,
            placeholder: index === 0 ? 'Дата, от' : 'Дата, до',
          }),
          render: ({ value }) => formatDateTime(value, 'dd.MM.yyyy'),
        },
        {
          name: 'specificationNumber',
          defaultWidth: 160,
          header: 'Номер спецификации',
          render: ({ value: name, data }) => (
            <PromoContractsInlineToolbar selectedContract={data}>
              <SearchQueryAwareText text={name} />
            </PromoContractsInlineToolbar>
          ),
          filterEditor: SelectFilter,
          filterEditorProps: {
            multiple: true,
            wrapMultiple: false,
            placeholder: 'Все',
            dataSource: specificationNumberFilterData,
          },
        },
        {
          name: 'contractDocumentNumber',
          defaultWidth: 160,
          header: 'Номер договора',
          render: ({ value }) => <SearchQueryAwareText text={value} />,

          filterEditor: SelectFilter,
          filterEditorProps: {
            multiple: true,
            wrapMultiple: false,
            placeholder: 'Все',
            dataSource: contractDocumentNumberFilterData,
          },
        },
        {
          name: 'organizationName',
          defaultWidth: 360,
          header: 'Название юр. лица',
          render: ({ value }) => {
            if (!value) {
              return null;
            }

            return (
              <CustomTypography noWrap lineClamp={2} variant="inherit">
                <SearchQueryAwareText text={String(value)} />
              </CustomTypography>
            );
          },
          filterEditor: SelectFilter,
          filterEditorProps: {
            multiple: true,
            wrapMultiple: false,
            placeholder: 'Все',
            dataSource: organizationNameFilterData,
          },
        },
        {
          name: 'templateName',
          textAlign: 'start',
          minWidth: 240,
          defaultFlex: 1,
          header: 'Название шаблона',
          filterEditor: SelectFilter,
          filterEditorProps: {
            multiple: true,
            wrapMultiple: false,
            placeholder: 'Все',
            dataSource: contractTemplateFilterData,
          },
        },
        {
          name: 'marketingOrgs',
          minWidth: 300,
          defaultFlex: 1,
          header: 'Маркетирующая организация',
          filterEditor: SelectFilter,
          filterEditorProps: {
            multiple: true,
            wrapMultiple: false,
            placeholder: 'Все',
            dataSource: marketingOrgsFilterData,
          },
          render: ({ value: marketingOrgs }) => (
            <Tooltip title={marketingOrgs.join(', ')}>
              <Typography variant="inherit" noWrap>
                {marketingOrgs.join(', ')}
              </Typography>
            </Tooltip>
          ),
        },
        {
          name: 'tin',
          textAlign: 'start',
          defaultWidth: 144,
          header: 'ИНН',
          render: ({ value: tin }) => <SearchQueryAwareText text={String(tin)} />,
          filterEditor: SelectFilter,
          filterEditorProps: {
            multiple: true,
            wrapMultiple: false,
            placeholder: 'Все',
            dataSource: tinFilterData,
          },
        },
        {
          name: 'paymentSum',
          textAlign: 'start',
          minWidth: 136,
          header: (
            <>
              Текущая сумма <br />
              выплаты
            </>
          ),
          filterEditor: SelectFilter,
          filterEditorProps: {
            multiple: true,
            wrapMultiple: false,
            placeholder: 'Все',
            dataSource: paymentSumFilterData,
          },
          render: ({ value }) => formatNumberByThousands(value),
        },
        {
          name: 'recordStatusId',
          defaultWidth: 180,
          header: 'Статус',
          filterEditor: SelectFilter,
          filterEditorProps: {
            multiple: true,
            wrapMultiple: false,
            placeholder: 'Все',
            dataSource: statusFilterData,
          },
          render: ({ value: status, data: rowData }) => <StatusLabel statusId={status} rowData={rowData} />,
        },
      ]}
    />
  );
};
