import {
  ConfirmationDialogActionCancelButton,
  ConfirmationDialogActionProceedButton,
  ConfirmationDialogActions,
  ConfirmationDialogBodyMutedText,
  ConfirmationDialogContainer,
  ConfirmationDialogContainerProps,
  ConfirmationDialogHeader,
} from '@/components/dialogs/elements.confirmation-dialog';

// ----------------------------------------------------------------------

export type ConfirmationCreateDialogProps = Omit<ConfirmationDialogContainerProps, 'subject'> & {
  isLoading: boolean;
  onProceed: VoidFunction;
};

// ----------------------------------------------------------------------

export const ConfirmationCreateDialog = (props: ConfirmationCreateDialogProps) => {
  const { isLoading, onClose, onProceed, ...restOfDialogProps } = props;

  return (
    <ConfirmationDialogContainer subject="warning" onClose={onClose} {...restOfDialogProps}>
      <ConfirmationDialogHeader>Создать запись?</ConfirmationDialogHeader>

      <ConfirmationDialogBodyMutedText>
        Сперва заполните раздел &#171;Основная информация&#187;.
      </ConfirmationDialogBodyMutedText>

      <ConfirmationDialogActions>
        <ConfirmationDialogActionCancelButton onClick={onClose} />
        <ConfirmationDialogActionProceedButton autoFocus loading={isLoading} onClick={onProceed}>
          Создать
        </ConfirmationDialogActionProceedButton>
      </ConfirmationDialogActions>
    </ConfirmationDialogContainer>
  );
};
