import { Controller, useFormContext } from 'react-hook-form';
import { Autocomplete, AutocompleteProps, TextField, TextFieldProps } from '@mui/material';

// ----------------------------------------------------------------------

export type RHFAutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> = Omit<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'renderInput'> & {
  name: string;
  label?: string;
  helperText?: React.ReactNode;
  TextFieldProps?: TextFieldProps;
};

// ----------------------------------------------------------------------

export const RHFAutocomplete = <
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>(
  props: RHFAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
) => {
  const { name, label, helperText, TextFieldProps, ...restOfProps } = props;

  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, onChange, ...restOfFieldProps }, fieldState: { error } }) => {
        const reassignedOnChange: RHFAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>['onChange'] = (
          _,
          value,
        ) => {
          onChange(value);
        };

        return (
          <Autocomplete
            {...restOfFieldProps}
            disablePortal
            onChange={reassignedOnChange}
            renderInput={params => (
              <TextField
                {...params}
                inputRef={ref}
                label={label}
                error={!!error}
                helperText={error ? error?.message : helperText}
                {...TextFieldProps}
                InputProps={{
                  ...params.InputProps,
                  ...TextFieldProps?.InputProps,
                }}
                inputProps={{
                  ...params.inputProps,
                  ...TextFieldProps?.inputProps,
                  autoComplete: 'nope',
                }}
              />
            )}
            {...restOfProps}
          />
        );
      }}
    />
  );
};
