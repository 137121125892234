import { Controller, ControllerRenderProps, useFormContext } from 'react-hook-form';
import { NumericFormat, NumericFormatProps, OnValueChange } from 'react-number-format';
import { isSafeNumeric } from '@/helpers/string.helpers';
import { CustomTextField, CustomTextFieldProps } from './CustomTextField';

// ----------------------------------------------------------------------

type ControllerPropKeys = keyof ControllerRenderProps;

export type RHFIntegerTextFieldProps = Omit<
  NumericFormatProps,
  'customInput' | 'onValueChange' | 'size' | 'color' | 'valueIsNumericString' | ControllerPropKeys
> &
  Omit<CustomTextFieldProps, 'name' | 'label' | 'error' | 'helperText' | ControllerPropKeys> & {
    name: string;
    label?: string;
    isClearableField?: boolean;
    isLoading?: boolean;
    isHelperTextVisible?: boolean;
    onClear?: VoidFunction;
  };

// ----------------------------------------------------------------------

export const RHFIntegerTextField = (props: RHFIntegerTextFieldProps) => {
  const { name, onClear, isHelperTextVisible = false, ...textFieldProps } = props;

  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, ref, value, ...fieldProps }, fieldState: { error } }) => {
        const handleValueChange: OnValueChange = ({ value }) => {
          onChange(value);
        };

        return (
          <NumericFormat
            inputRef={ref}
            customInput={CustomTextField}
            error={!!error}
            helperText={isHelperTextVisible ? '' : error?.message ?? ''}
            valueIsNumericString
            isAllowed={({ value }) => isSafeNumeric(value)}
            thousandSeparator=" "
            decimalScale={0}
            value={value}
            onClearButtonClick={() => {
              onClear?.();
              onChange('');
            }}
            {...fieldProps}
            {...textFieldProps}
            onValueChange={handleValueChange}
          />
        );
      }}
    />
  );
};
