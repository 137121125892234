import { LoadingButton } from '@mui/lab';
import { Stack, SvgIcon, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactComponent as AlertSVG } from '@/assets/icons/minimal/ic_alert.svg';
import { formatWordEnding } from '@/helpers/format.helpers';
import { PanelBase } from '@/layouts/PanelBase';

import type { DataGridPaginationProps } from './types';
import { useDataGridPagination } from './useDataGridPagination';

// ----------------------------------------------------------------------

const StyledRoot = styled(Stack)(({ theme }) => ({
  width: '100%',
  alignItems: 'center',
  padding: theme.spacing(1.5),
  backgroundColor: theme.palette.warning.lighter,
})) as typeof Stack;

// ----------------------------------------------------------------------

export const DataGridPagination = (props: DataGridPaginationProps) => {
  const { isLoading, hasNextPage, dataSource } = props;
  const dataSourceLength = dataSource.length;

  const { handleShowMore } = useDataGridPagination(props);

  if (!hasNextPage) {
    return null;
  }

  return (
    <PanelBase>
      <StyledRoot direction="row" spacing={1.5}>
        <SvgIcon component={AlertSVG} inheritViewBox color="warning" />
        <Typography component="p" variant="body2" color="warning.darker" sx={{ flexGrow: 1 }}>
          Отображаются не все записи, показаны
          {` ${dataSourceLength} ${formatWordEnding(dataSourceLength, ['запись', 'записи', 'записей'])}`}
        </Typography>

        <LoadingButton loading={isLoading} variant="contained" color="warning" size="small" onClick={handleShowMore}>
          Показать ещё
        </LoadingButton>
      </StyledRoot>
    </PanelBase>
  );
};
