import { useState } from 'react';
import { useToggle } from 'react-use';
import { CircularProgress, Stack } from '@mui/material';

import { StatusSelect } from '@/components/StatusSelect';
import { ConfirmationWarningDialog } from '@/components/dialogs';
import {
  PromoContractStatusNameByStatusId,
  PROMO_CONTRACT_STATUS_PAYMENT_CALCULATED,
  PROMO_CONTRACT_STATUS_PAYMENT_COMPLETED,
  PROMO_CONTRACT_STATUS_PAYMENT_EXPECTED,
  PromoContractStatusId,
  PROMO_CONTRACT_STATUS_CANCELLED,
  PROMO_CONTRACT_STATUS_CLARIFICATION,
  PROMO_CONTRACT_STATUS_PAYMENT_CALCULATION,
} from '@/constants/promo-contract-status.constants';
import { PromoContractDataGridRowData } from '../../data-grids';
import { getColorByPromoContractStatusId } from '../../helpers/status-select.helpers';
import { useUpdatePromoContract } from '../../data-grids/useUpdatePromoContract';
import { usePermissions } from '@/hooks/useCheckPermissions';

const useGetStatuses = (selectedStatusId: PromoContractStatusId): PromoContractStatusId[] => {
  const { canStatuses } = usePermissions();

  const isChangeToPaymentCompletedAllowed = canStatuses('write', 'agreements', [
    PROMO_CONTRACT_STATUS_PAYMENT_COMPLETED,
  ]);
  const isChangeFromClarificationAllowed = canStatuses('write', 'agreements', [
    PROMO_CONTRACT_STATUS_PAYMENT_CALCULATED,
    PROMO_CONTRACT_STATUS_CANCELLED,
  ]);
  const isChangeToPaymentCalculatedAllowed = canStatuses('write', 'agreements', [
    PROMO_CONTRACT_STATUS_PAYMENT_CALCULATED,
  ]);

  const statusOptions: PromoContractStatusId[] = [selectedStatusId];

  switch (selectedStatusId) {
    case PROMO_CONTRACT_STATUS_PAYMENT_EXPECTED: {
      if (isChangeToPaymentCompletedAllowed) {
        statusOptions.push(PROMO_CONTRACT_STATUS_PAYMENT_COMPLETED);
      }
      break;
    }
    case PROMO_CONTRACT_STATUS_CLARIFICATION: {
      if (isChangeFromClarificationAllowed) {
        statusOptions.push(PROMO_CONTRACT_STATUS_PAYMENT_CALCULATED, PROMO_CONTRACT_STATUS_CANCELLED);
      }
      break;
    }
    case PROMO_CONTRACT_STATUS_PAYMENT_CALCULATION: {
      if (isChangeToPaymentCalculatedAllowed) {
        statusOptions.push(PROMO_CONTRACT_STATUS_PAYMENT_CALCULATED);
      }
      break;
    }
    default: {
      break;
    }
  }

  return statusOptions;
};

type PromoContractsStatusSelectProps = {
  promoContractDataGridRowData: PromoContractDataGridRowData;
};

export const PromoContractStatusSelect = (props: PromoContractsStatusSelectProps) => {
  const { promoContractDataGridRowData } = props;

  const { recordStatusId: selectedRecordStatusId, id } = promoContractDataGridRowData;
  const statuses = useGetStatuses(selectedRecordStatusId);

  const [isConfirmationDialogOpen, toggleIsConfirmationDialogOpen] = useToggle(false);
  const openConfirmationDialog = () => toggleIsConfirmationDialogOpen(true);
  const closeConfirmationDialog = () => toggleIsConfirmationDialogOpen(false);
  const [newStatusId, setNewStatusId] = useState(selectedRecordStatusId);

  const { mutate: updatePromoContract, isLoading } = useUpdatePromoContract();
  const changeStatus = () => {
    updatePromoContract(
      {
        contractId: id,
        params: {
          record_status_id: newStatusId,
        },
        rowData: promoContractDataGridRowData,
      },
      {
        onSuccess: closeConfirmationDialog,
      },
    );
  };

  const handleSelectStatus = (statusId: PromoContractStatusId) => {
    setNewStatusId(statusId);
    openConfirmationDialog();
  };

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <StatusSelect
          selectedRecordStatusId={selectedRecordStatusId}
          statuses={statuses}
          onSelectStatus={handleSelectStatus}
          getLabelName={statusId => PromoContractStatusNameByStatusId[statusId]}
          getLabelColor={getColorByPromoContractStatusId}
          isChangeStatusDisabled={isLoading}
        />

        {isLoading && <CircularProgress size={20} color="inherit" />}
      </Stack>

      <ConfirmationWarningDialog
        open={isConfirmationDialogOpen}
        isLoading={isLoading}
        onProceed={changeStatus}
        onClose={closeConfirmationDialog}
        headerText="Смена статуса"
        bodyText={
          <>
            Изменить статус <b>{PromoContractStatusNameByStatusId[selectedRecordStatusId]}</b> на{' '}
            <b>{PromoContractStatusNameByStatusId[newStatusId]}</b>?
          </>
        }
        proceedButtonText="Изменить"
      />
    </>
  );
};
