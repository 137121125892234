import { Dialog, DialogProps } from '@mui/material';
import { ModalTabbarStoreProvider } from './modal-tabs/ModalTabbarProvider';

// ----------------------------------------------------------------------

export type ModalProps = Omit<DialogProps, 'onClose'> & {
  onClose?: VoidFunction;
};

// ----------------------------------------------------------------------

/**
 * @deprecated Use `import { Modal } from '@/components/Modal/Modal2'` instead
 */
export const Modal = (props: ModalProps) => {
  const { children, ...restOfProps } = props;

  return (
    <ModalTabbarStoreProvider>
      <Dialog fullWidth maxWidth="xl" {...restOfProps}>
        {children}
      </Dialog>
    </ModalTabbarStoreProvider>
  );
};
