import { useDebounce, useGetSet, useUnmount, useUpdateEffect } from 'react-use';
import { SvgIcon } from '@mui/material';
import { ReactComponent as BankingSVG } from '@/assets/icons/navbar/ic_banking.svg';
import {
  selectMakersStoreSearchQuery,
  useMakersStore,
  useMakersStoreActions,
  useMakersStoreIsContextWindowsVisible,
} from '@/components/makers/useMakersStore';
import { searchHintNames } from '@/constants/storage';
import { useSearchHints } from '@/hooks/useSearchHints';
import { Header, HeaderSearchInput, HeaderTitle, useSearchInputShortcuts } from '@/layouts/Header';

// ----------------------------------------------------------------------

export type MakersHeaderProps = {
  hasIcon?: boolean;
};

// ----------------------------------------------------------------------

const SearchForm = () => {
  const searchQuery = useMakersStore(selectMakersStoreSearchQuery);
  const [getInputValue, setInputValue] = useGetSet('');
  const inputValue = getInputValue();
  const { hintList, addToHintList } = useSearchHints(searchHintNames.makers);

  const { setSearchQuery, clearSearchQuery } = useMakersStoreActions();
  const [, cancel] = useDebounce(
    () => {
      setSearchQuery(inputValue);
      addToHintList(inputValue);
    },
    500,
    [inputValue],
  );

  const isContextWindowsVisible = useMakersStoreIsContextWindowsVisible();
  const inputRef = useSearchInputShortcuts(() => !isContextWindowsVisible);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handlePaste: React.ClipboardEventHandler<HTMLInputElement> = event => {
    event.preventDefault();

    const clipboardData = event.clipboardData.getData('text');

    setInputValue(clipboardData.trim());
  };

  const handleClear = () => {
    cancel();
    clearSearchQuery();
    setInputValue('');
  };

  useUnmount(clearSearchQuery);

  // Sync input value with search query
  useUpdateEffect(() => {
    const inputValue = getInputValue();

    if (inputValue !== searchQuery) {
      setInputValue(searchQuery);
    }
  }, [searchQuery]);

  return (
    <HeaderSearchInput
      inputRef={inputRef}
      value={inputValue}
      hints={hintList}
      placeholder="Поиск по производителю или стране"
      onChange={handleChange}
      onPaste={handlePaste}
      onClear={handleClear}
      setInputValue={setInputValue}
    />
  );
};

// ----------------------------------------------------------------------

export const MakersHeader = (props: MakersHeaderProps) => {
  const { hasIcon = true } = props;

  return (
    <Header
      leftSlot={
        <HeaderTitle icon={hasIcon ? <SvgIcon component={BankingSVG} inheritViewBox fontSize="small" /> : null}>
          Производители
        </HeaderTitle>
      }
    >
      <SearchForm />
    </Header>
  );
};
