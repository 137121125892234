import { useLocation } from 'react-router-dom';

import { RedirectWithoutAccess, RedirectWithoutAccessProps } from './RedirectWithoutAccess';
import { useRegistryFile } from '@/pages/registers/shared';
import { RegistersPaths } from '@/router';

type RequisitionFileGuardProps = Pick<RedirectWithoutAccessProps, 'children'>;

export const RegistryFileGuard = (props: RequisitionFileGuardProps) => {
  const { children } = props;
  const registryFile = useRegistryFile();

  const { pathname: from } = useLocation();

  return (
    <RedirectWithoutAccess hasAccess={!!registryFile} to={RegistersPaths.ROOT_PATH} state={{ from }}>
      {children}
    </RedirectWithoutAccess>
  );
};
