import { useState, Fragment } from 'react';
import { Button, type ButtonProps, Popover, PopoverOrigin, type PopoverPosition, Stack } from '@mui/material';
import { SubmenuButtonItem, SubmenuItemButtonProps } from './SubmenuButtonItem';
import { Scrollbar } from '@/components/Scrollbar';

const computePopoverPosition = (
  anchorEl: HTMLButtonElement,
  horizontalPosition: Extract<PopoverOrigin['horizontal'], 'left' | 'right'>,
): PopoverPosition => {
  const anchorDomRect = anchorEl.getBoundingClientRect();

  return {
    top: anchorDomRect.top + anchorDomRect.height + 4,
    left: anchorDomRect[horizontalPosition],
  };
};

type SubmenuButtonProps = ButtonProps & {
  title?: string;
  submenuHorizontalPosition?: Extract<PopoverOrigin['horizontal'], 'left' | 'right'>;
  submenuVerticalPosition?: PopoverOrigin['vertical'];
  submenu: Array<SubmenuItemButtonProps>;
};

export const SubmenuButton = (props: SubmenuButtonProps) => {
  const {
    title = 'Создать',
    submenu,
    submenuHorizontalPosition = 'left',
    submenuVerticalPosition = 'top',
    ...restOfButtonProps
  } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const open: React.MouseEventHandler<HTMLButtonElement> = event => setAnchorEl(event.currentTarget);
  const close = () => setAnchorEl(null);

  const hasSubmenu = submenu.length > 0;
  const isPopoverOpen = !!anchorEl;

  return (
    <Fragment>
      <Button {...restOfButtonProps} onClick={open}>
        {title}
      </Button>

      <Popover
        open={isPopoverOpen}
        onClose={close}
        anchorReference="anchorPosition"
        anchorPosition={anchorEl ? computePopoverPosition(anchorEl, submenuHorizontalPosition) : undefined}
        transformOrigin={{
          horizontal: submenuHorizontalPosition,
          vertical: submenuVerticalPosition,
        }}
      >
        {hasSubmenu && (
          <Scrollbar autoHide={false} sx={{ maxHeight: '190px', minWidth: '248px' }}>
            <Stack padding={1}>
              {submenu.map(({ title, onClick, ...restOfProps }) => (
                <SubmenuButtonItem
                  key={title}
                  title={title}
                  onClick={() => {
                    onClick();
                    close();
                  }}
                  {...restOfProps}
                />
              ))}
            </Stack>
          </Scrollbar>
        )}
      </Popover>
    </Fragment>
  );
};
