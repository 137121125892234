import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import type { Classification, ClassificationWithChildren } from '@/api/domains/classifications.types';
import { highlightKeyword } from '@/helpers/format.helpers';
import { TreeView, TreeViewItem, TreeViewItemContent, TreeViewItemIcon, TreeViewItemText } from './TreeView';
import { getClassificationName } from './utils/getClassificationName';

type ClassificationFieldSearchTreeItemProps = {
  searchQuery: string;
  value: ClassificationWithChildren;
  selectedValue: ClassificationWithChildren | Classification | null;
  onSelect: (value: ClassificationWithChildren | null) => void;
};

export const ClassificationFieldSearchTreeItem = (props: ClassificationFieldSearchTreeItemProps) => {
  const { searchQuery, value, selectedValue, onSelect } = props;
  const { has_children, children } = value;

  const hasChildren = has_children && children.length > 0;

  const handleSelect = () => onSelect(value);

  const isSelected = value.id === selectedValue?.id;

  return (
    <TreeViewItem>
      <TreeViewItemContent isExpanded={hasChildren} isSelected={isSelected}>
        {hasChildren && (
          <TreeViewItemIcon disableRipple disableTouchRipple disableFocusRipple>
            {hasChildren && <ExpandMoreIcon width={24} height={24} />}
          </TreeViewItemIcon>
        )}
        <TreeViewItemText onClick={handleSelect}>
          {highlightKeyword(getClassificationName(value), searchQuery)}
        </TreeViewItemText>
      </TreeViewItemContent>

      {hasChildren && (
        <TreeView>
          {children.map(classification => (
            <ClassificationFieldSearchTreeItem
              key={classification.id}
              searchQuery={searchQuery}
              value={classification}
              selectedValue={selectedValue}
              onSelect={onSelect}
            />
          ))}
        </TreeView>
      )}
    </TreeViewItem>
  );
};
