import { lazy } from 'react';

// ----------------------------------------------------------------------

const NomenclatureDeduplicationPage = lazy(
  () =>
    import(
      /* webpackPreload: true */
      /* webpackChunkName: "NomenclatureDeduplicationPage" */
      '@/pages/nomenclature/deduplication/NomenclatureDeduplicationPage'
    ),
);

export default NomenclatureDeduplicationPage;
