import { lazy } from 'react';

export const MarketingOrgAgreementPage = lazy(
  () =>
    import(
      /* webpackPreload: true */
      /* webpackChunkName: "MarketingOrgAgreementPage" */
      './MarketingOrgAgreementPage'
    ),
);
