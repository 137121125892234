import { useLocation } from 'react-router-dom';
import { useAuthState } from '@/contexts/AuthContext';
import { RedirectWithoutAccess, RedirectWithoutAccessProps } from '@/guards/RedirectWithoutAccess';

// ----------------------------------------------------------------------

type GuestGuardProps = Pick<RedirectWithoutAccessProps, 'children'>;

// ----------------------------------------------------------------------

export const GuestGuard = (props: GuestGuardProps) => {
  const { children } = props;

  const { isAuthenticated } = useAuthState();
  const { state } = useLocation();

  const from = (state as { from: string })?.from ?? '/';

  return (
    <RedirectWithoutAccess hasAccess={!isAuthenticated} to={from}>
      {children}
    </RedirectWithoutAccess>
  );
};
