import { lazy } from 'react';

// ----------------------------------------------------------------------

const NomenclaturePage = lazy(
  () =>
    import(
      /* webpackPreload: true */
      /* webpackChunkName: "NomenclaturePage" */
      '@/pages/nomenclature/NomenclaturePage'
    ),
);

export default NomenclaturePage;
