import { ExtractPathParams, generatePath, joinPath } from '@/libs/react-router';

const ROOT_PATH = '/e-imzo-documents';

const EIMZO_DOCUMENTS_DETAIL_PATH = joinPath(ROOT_PATH, ':uuid');
const EIMZO_DOCUMENTS_DOCUMENT_PATH = joinPath(EIMZO_DOCUMENTS_DETAIL_PATH, 'document');
const EIMZO_DOCUMENTS_SIGNATURE_PROTOCOL_PATH = joinPath(EIMZO_DOCUMENTS_DETAIL_PATH, 'signature-protocol');

export type EimzoDocumentsDetailPathParams = ExtractPathParams<typeof EIMZO_DOCUMENTS_DETAIL_PATH>;
export type EimzoDocumentPathParams = ExtractPathParams<typeof EIMZO_DOCUMENTS_DOCUMENT_PATH>;
export type EimzoSignatureProtocolPathParams = ExtractPathParams<typeof EIMZO_DOCUMENTS_SIGNATURE_PROTOCOL_PATH>;

export const EimzoDocumentsPath = {
  ROOT_PATH,
  EIMZO_DOCUMENTS_DETAIL_PATH,

  EIMZO_DOCUMENTS_DOCUMENT_PATH,
  generateDocumentPath: (params: EimzoDocumentPathParams) => generatePath(EIMZO_DOCUMENTS_DOCUMENT_PATH, params),

  EIMZO_DOCUMENTS_SIGNATURE_PROTOCOL_PATH,
  generateSignatureProtocolPath: (params: EimzoSignatureProtocolPathParams) =>
    generatePath(EIMZO_DOCUMENTS_SIGNATURE_PROTOCOL_PATH, params),
} as const;
