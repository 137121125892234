import { lazy } from 'react';

export const RegistersPage = lazy(
  () =>
    import(
      /* webpackPreload: true */
      /* webpackChunkRegistersMain: "RegistersPage" */
      './RegistersPage'
    ),
);
