import { lazy } from 'react';

export const SpecificationDocumentPage = lazy(
  () =>
    import(
      /* webpackPreload: true */
      /* webpackChunkName: "SpecificationtDocumentPage" */
      './SpecificationDocumentPage'
    ),
);
