import { Controller, useFormContext } from 'react-hook-form';
import { styled } from '@mui/material';
import { MuiFileInput, MuiFileInputProps } from 'mui-file-input';
import { Label } from '@/components/labels';

// ----------------------------------------------------------------------
type MultipleFileInput = false;
export type RHFFileFieldProps = MuiFileInputProps<MultipleFileInput> & {
  name: string;
  endAdornmentText?: string;
  accept?: string;
};

// ----------------------------------------------------------------------

const StyledFileInput = styled(MuiFileInput)(({ theme, disabled }) => ({
  '.MuiFileInput-TextField': {
    position: 'relative',
  },
  '.MuiInputBase-root': {
    padding: '0 !important',
  },
  label: {
    width: '100%',
  },
  'input + span': {
    padding: theme.spacing(1.5, 15, 1.5, 1.5),
    cursor: disabled ? 'default' : 'pointer',
  },
  '.MuiFileInput-placeholder': {
    fontSize: 12,
  },
  '.hint': {
    backgroundColor: '#FFFFFF',
    border: '1px solid #9AA7B5',
    position: 'absolute',
    top: '50%',
    right: 10,
    transform: 'translateY(-50%)',
  },
})) as typeof MuiFileInput;

// ----------------------------------------------------------------------

export const RHFFileField = (props: RHFFileFieldProps) => {
  const { name, helperText, endAdornmentText = 'Выберите файл', accept, ...restOfProps } = props;

  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, onBlur, onChange, ...restOfFieldProps }, fieldState: { error } }) => {
        const patchedOnChange: RHFFileFieldProps['onChange'] = file => {
          onChange(file);
          onBlur();
        };

        return (
          <StyledFileInput
            {...restOfFieldProps}
            inputRef={ref}
            fullWidth
            error={!!error}
            helperText={error ? error?.message : helperText}
            InputProps={{
              startAdornment: null,
              endAdornment: <Label className="hint">{endAdornmentText}</Label>,
            }}
            inputProps={{
              accept,
            }}
            onChange={patchedOnChange}
            {...restOfProps}
          />
        );
      }}
    />
  );
};
