import { ControlPointDuplicate as ControlPointDuplicateIcon } from '@mui/icons-material';
import { ActionButton, ActionButtonProps, ActionIconButtonProps } from '@/components/buttons';
import { DataGridActionDuplicate } from '@/components/DataGrid';
import {
  useMakersStoreActions,
  useMakersStoreContextWindowsState,
  useMakersStoreIsContextWindowsVisible,
} from '@/components/makers/useMakersStore';
import { useShortcut } from '@/hooks/useShortcut';

// ----------------------------------------------------------------------
// MAIN BUTTON

export const MakersMainDuplicateButton = (props: ActionIconButtonProps) => {
  const { disabled } = props;

  const isContextWindowsVisible = useMakersStoreIsContextWindowsVisible();
  const canOpenDuplicateModal = !disabled && !isContextWindowsVisible;

  const { openDuplicateModal } = useMakersStoreActions();

  useShortcut(
    'ctrl + d',
    () => {
      if (canOpenDuplicateModal) {
        openDuplicateModal();
      }
    },
    [canOpenDuplicateModal],
  );

  return <DataGridActionDuplicate {...props} onClick={openDuplicateModal} />;
};

// ----------------------------------------------------------------------
// INLINE BUTTON

export const MakersInlineDuplicateButton = (props: ActionIconButtonProps) => {
  const { onClick } = props;

  const { openDuplicateModal } = useMakersStoreActions();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onClick?.(event);
    openDuplicateModal();
  };

  return <DataGridActionDuplicate {...props} onClick={handleClick} />;
};

// ----------------------------------------------------------------------
// MODAL BUTTON

export const MakersModalDuplicateButton = (props: ActionButtonProps) => {
  const { disabled } = props;

  const { isDuplicateModalVisible, isDeleteDialogVisible } = useMakersStoreContextWindowsState();
  const canOpenDuplicateModal = !disabled && !isDuplicateModalVisible && !isDeleteDialogVisible;

  const { openDuplicateModal } = useMakersStoreActions();

  useShortcut(
    'ctrl + d',
    () => {
      if (canOpenDuplicateModal) {
        openDuplicateModal();
      }
    },
    [canOpenDuplicateModal],
  );

  return (
    <ActionButton startIcon={<ControlPointDuplicateIcon fontSize="small" />} onClick={openDuplicateModal} {...props}>
      Дублировать
    </ActionButton>
  );
};
