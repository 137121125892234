import { PaginatedList, PaginationParams } from '@/api/@types/pagination.types';
import { Inn } from '@/api/domains/inn.type';
import { httpClient } from '@/libs/axios/http-client';

// ----------------------------------------------------------------------

export type FetchInnsParams = PaginationParams & {
  query?: string;
  with_inactive?: boolean;
  with_one_element?: boolean;
};

export type InnFormData = {
  name: string;
  description: string | null;
};

// ----------------------------------------------------------------------

export const InnApi = {
  fetchInns: async (params?: FetchInnsParams) => {
    const { data } = await httpClient.get<PaginatedList<'items', Inn>>('inn', { params });

    return data;
  },

  // ----------------------------------------------------------------------

  fetchInn: async (innId: number) => {
    const { data } = await httpClient.get<Inn>(`inn/${innId}`);

    return data;
  },

  // ----------------------------------------------------------------------

  createInn: async (formData: InnFormData) => {
    const { data } = await httpClient.post<Inn>('inn', formData);

    return data;
  },

  // ----------------------------------------------------------------------

  updateInn: async (innId: number, formData: InnFormData) => {
    const { data } = await httpClient.put<Inn>(`inn/${innId}`, formData);

    return data;
  },

  // ----------------------------------------------------------------------

  deleteInn: (innId: number) => httpClient.delete<void>(`inn/${innId}`),
};
