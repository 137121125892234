import {
  FetchContractorConfigParams,
  FetchContractorsParams,
  SearchContractorsParams,
} from '@/api/domains/contractors.api';
import { ContractorRegisterDataParams } from '@/api/domains/contractors.api';
import { paramsToQueryKey } from '@/helpers/transformation.helpers';

const PREFIX = 'contractors' as const;

export const ContractorsQueryKeys = {
  rootKey: [PREFIX],

  getContractorsTreeKey: (params?: FetchContractorsParams) => paramsToQueryKey([PREFIX, 'list'], params),

  contractorChildrenKey: [PREFIX, 'contractor-children'],
  getContractorChildrenKey: (parentId: number) => [...ContractorsQueryKeys.contractorChildrenKey, parentId],

  getUseContractorsSelectKey: (params?: SearchContractorsParams) =>
    paramsToQueryKey([PREFIX, 'useContractorsSelect'], params),

  getUserMigrationContractorsRootKey: () => [PREFIX, 'user-migration-contractors'],

  getUserMigrationContractorsKey: (params?: FetchContractorsParams) =>
    paramsToQueryKey(ContractorsQueryKeys.getUserMigrationContractorsRootKey(), params),

  getSearchContractorsKey: (params?: SearchContractorsParams) => paramsToQueryKey([PREFIX, 'search'], params),

  getContractorKey: (contractorId?: string | number) => [PREFIX, contractorId?.toString(), 'view'],

  getDistributorExtraDataKey: (contractorId?: number) => [PREFIX, 'distributor-extra-data', contractorId?.toString()],

  getContractorsRegisterDataKey: (params: ContractorRegisterDataParams) =>
    paramsToQueryKey([PREFIX, 'contractors-registr-data'], params),

  getConfigKey: (params: FetchContractorConfigParams) => paramsToQueryKey([PREFIX, 'contractor-config'], params),
};

export const ContractorsMutationKeys = {
  rootKey: [PREFIX],

  getContractorRegisterDataKey: (params: ContractorRegisterDataParams) =>
    paramsToQueryKey([PREFIX, 'contractor-registr-data'], params),
};
