import { useQuery } from '@tanstack/react-query';

import {
  PromoContractsApi,
  type FetchMarketingOrgAgreementParams,
  type MarketingOrgAgreementResponse,
} from '@/api/domains/promo-contracts.api';
import { PromoContractsQueryKeys } from '@/api/domains/promo-contracts.query-keys';

type UseMarketingOrgAgreement<TData> = Partial<FetchMarketingOrgAgreementParams> & {
  select?: (marketingOrg: MarketingOrgAgreementResponse | undefined) => TData | undefined;
};

export const useMarketingOrgAgreement = <TData = MarketingOrgAgreementResponse | undefined>({
  agreementId,
  marketingOrgId,
  select,
}: UseMarketingOrgAgreement<TData>) => {
  const isEnabled = agreementId !== undefined && marketingOrgId !== undefined;

  return useQuery({
    queryKey: PromoContractsQueryKeys.getMarketingOrgAgreementKey({ agreementId, marketingOrgId }),
    queryFn: () => {
      if (isEnabled) {
        return PromoContractsApi.fetchMarketingOrgAgreement({ agreementId, marketingOrgId });
      }
    },
    select,
    refetchOnWindowFocus: false,
    enabled: isEnabled,
  });
};
