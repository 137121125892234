import { Auth } from '@/api/domains/auth.types';
import { httpClient } from '@/libs/axios/http-client';

// ----------------------------------------------------------------------

export type SignInFormData = {
  phone: string;
  password: string;
};

// ----------------------------------------------------------------------

export const AuthApi = {
  signIn: async (formData: SignInFormData) => {
    const { data } = await httpClient.post<Auth>('auth/sign-in', formData, {
      shouldRefreshTokenIfNeeded: false,
      shouldInjectAccessToken: false,
    });

    return data;
  },

  // ----------------------------------------------------------------------

  refreshToken: async (refreshToken: string) => {
    const { data } = await httpClient.post<Auth>(
      'auth/refresh',
      { refresh_token: refreshToken },
      {
        shouldRefreshTokenIfNeeded: false,
        shouldInjectAccessToken: false,
      },
    );

    return data;
  },
};
