import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { styled, type ButtonTypeMap } from '@mui/material';
import type { OverridableComponent } from '@mui/material/OverridableComponent';

// ----------------------------------------------------------------------

export type RoundedButtonProps = Omit<LoadingButtonProps, 'color'>;

// ----------------------------------------------------------------------

const StyledRoot = styled(LoadingButton)(({ theme }) => ({
  padding: '8px 24px',
  borderRadius: '20px',
  background: theme.palette.secondary.main,
  color: theme.palette.primary.contrastText,

  '&:hover': {
    backgroundColor: theme.palette.secondary.dark,
  },

  variants: [
    {
      props: { variant: 'outlined' },
      style: {
        border: `1px solid ${theme.palette.neutral[500]}`,
        backgroundColor: theme.palette.neutral[100],
        color: theme.palette.secondary.main,

        '&:hover': {
          backgroundColor: theme.palette.neutral[200],
          borderColor: theme.palette.neutral[500],
        },

        '&.Mui-disabled': {
          backgroundColor: theme.palette.neutral[100],
          borderColor: theme.palette.neutral[500],
        },
      },
    },
  ],
}));

// ----------------------------------------------------------------------

export const RoundedButton: OverridableComponent<ButtonTypeMap<RoundedButtonProps>> = (props: RoundedButtonProps) => (
  <StyledRoot variant="contained" color="secondary" {...props} />
);
