import { HttpStatusCode, isAxiosError } from 'axios';

type HandleAxiosErrorWithStatusOptions = {
  error: unknown;
  status: HttpStatusCode;
  handler: () => void;
};

export const isAxiosErrorWithStatus = (status: HttpStatusCode, error: unknown): boolean =>
  isAxiosError(error) && status === error.response?.status;

export const handleAxiosErrorWithStatus = ({ error, status, handler }: HandleAxiosErrorWithStatusOptions): boolean => {
  const isMatched = isAxiosErrorWithStatus(status, error);

  if (isMatched) handler();

  return isMatched;
};
