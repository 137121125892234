import { lazy } from 'react';

// ----------------------------------------------------------------------

const PromoPage = lazy(
  () =>
    import(
      /* webpackPreload: true */
      /* webpackChunkName: "PromoProductsPage" */
      '@/pages/promo/PromoProductsPage'
    ),
);

export default PromoPage;
