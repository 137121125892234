import type { ComponentProps, ElementType } from 'react';

import {
  Box,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  styled,
  SvgIcon,
  Typography,
  type TypographyProps,
  type SvgIconTypeMap,
  type DialogContentProps,
  DialogContent,
  type DialogActionsProps,
  DialogActions,
  StackProps,
  BoxProps,
} from '@mui/material';
import type { OverridableComponent } from '@mui/material/OverridableComponent';

import CloseIcon from '@mui/icons-material/Close';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { ReactComponent as DeleteIcon } from '@/assets/icons-2.0/delete.svg';
import { ReactComponent as WarningIcon } from '@/assets/icons-2.0/warning.svg';

// ------------------------------------------------------------------

const UploadHeaderIcon = () => <DialogHeaderIcon component={FileUploadOutlinedIcon} color="secondary" />;
const WarningHeaderIcon = () => <DialogHeaderIcon component={WarningIcon} color="warning" />;
const ErrorHeaderIcon = () => <DialogHeaderIcon component={ErrorOutlineOutlinedIcon} color="error" />;
const DoneHeaderIcon = () => <DialogHeaderIcon component={DoneOutlinedIcon} color="secondary" />;
const DeleteHeaderIcon = () => <DialogHeaderIcon component={DeleteIcon} color="error" />;

// ------------------------------------------------------------------

type IconColor = 'secondary' | 'warning' | 'error';

type Variant = 'warning' | 'delete' | 'error' | 'upload' | 'done';

export type DialogHeaderProps = {
  onClose?: VoidFunction;
  title: React.ReactNode;
} & (
  | { variant: Variant; icon?: never; color?: never }
  | { variant?: never; icon: ElementType<ComponentProps<'svg'>>; color: IconColor }
  | { variant?: never; icon?: never; color?: never }
);

const iconsByVariant: Record<
  Variant,
  ElementType<ComponentProps<'svg'>> | OverridableComponent<SvgIconTypeMap<{}, 'svg'>>
> = {
  warning: WarningHeaderIcon,
  done: DoneHeaderIcon,
  delete: DeleteHeaderIcon,
  error: ErrorHeaderIcon,
  upload: UploadHeaderIcon,
};

// ------------------------------------------------------------------

type HeaderIconProps = {
  variant: DialogHeaderProps['variant'];
  icon: DialogHeaderProps['icon'];
  color: DialogHeaderProps['color'];
};

const HeaderIcon = (props: HeaderIconProps) => {
  const { variant, color, icon } = props;
  if ('variant' in props && variant) {
    const IconSVG = iconsByVariant[variant];
    return <IconSVG height={40} width={40} />;
  } else if ('icon' in props && icon && color) {
    return <DialogHeaderIcon component={icon} color={color} />;
  }
  return null;
};

// ------------------------------------------------------------------

export const DialogHeader = (props: DialogHeaderProps) => {
  const { onClose, title, variant, color, icon } = props;

  const isIconRender = variant || (icon && color);

  return (
    <>
      <Stack direction="row" sx={{ padding: 1, justifyContent: 'space-between', alignItems: 'center', minHeight: 56 }}>
        <Stack spacing={1} alignItems="center" textAlign="center" direction="row">
          <HeaderIcon variant={variant} icon={icon} color={color} />
          <DialogTitle
            variant="Title/large"
            sx={{ padding: 0, minHeight: 'fit-content', paddingLeft: isIconRender ? 0 : 2 }}
          >
            {title}
          </DialogTitle>
        </Stack>

        <IconButton onClick={onClose}>
          <CloseIcon fontSize="medium" />
        </IconButton>
      </Stack>

      <Divider />
    </>
  );
};

// ------------------------------------------------------------------

type DialogHeaderIconProps = {
  component: React.ElementType<any>;
  color: 'secondary' | 'warning' | 'error';
};

const StyledIconContainer = styled(Box)({
  borderRadius: '50%',
  aspectRatio: 1,
  width: 40,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const DialogHeaderIcon = (props: DialogHeaderIconProps) => {
  const { component, color } = props;
  return (
    <StyledIconContainer sx={{ backgroundColor: `${color}.main` }}>
      <SvgIcon component={component} fontSize="medium" sx={{ color: 'secondary.contrastText' }} />
    </StyledIconContainer>
  );
};

// ------------------------------------------------------------------

export const DialogBodyMutedText = (props: TypographyProps) => (
  <Typography component="div" variant="body2" color="text.secondary" {...props} />
);

type DialogContentContainerProps = DialogContentProps & {
  spacing?: StackProps['spacing'];
  slotProps?: {
    stack?: Omit<StackProps, 'spacing'>;
  };
};

export const DialogContentContainer = (props: DialogContentContainerProps) => {
  const { children, slotProps, spacing = 3, sx, ...restProps } = props;

  return (
    <DialogContent sx={{ p: theme => theme.spacing(2, 3, 0), display: 'flex', ...sx }} {...restProps}>
      <Stack spacing={spacing} flexGrow={1} {...slotProps?.stack}>
        {children}
      </Stack>
    </DialogContent>
  );
};

export const DialogActionsContainer = (props: DialogActionsProps) => <DialogActions sx={{ p: 3 }} {...props} />;

const StyledBox = styled(Box)(({ theme }) => ({
  width: '100%',
  borderRadius: theme.spacing(1),
  border: `1px solid ${theme.palette.neutral[300]}`,
  backgroundColor: theme.palette.neutral[200],
  padding: theme.spacing(2),
  textAlign: 'left',
  maxHeight: '50vh',
  overflowY: 'auto',

  '&::-webkit-scrollbar': {
    width: '12px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.neutral[500],
    border: '4px solid transparent',
    backgroundClip: 'padding-box',
    borderRadius: '9px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent',
  },
})) as typeof Box;

type DialogCardProps = BoxProps &
  StackProps & {
    title?: string;
  };
export const DialogCard = (props: DialogCardProps) => {
  const { title, children, ...restProps } = props;

  return (
    <Stack spacing={1}>
      {title && (
        <Typography variant="Title/medium" color="neutral.700">
          {title}
        </Typography>
      )}
      <StyledBox component={Stack} divider={<Divider />} spacing={2} {...restProps}>
        {children}
      </StyledBox>
    </Stack>
  );
};
