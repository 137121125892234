import { IconButton, InputAdornment, InputAdornmentProps } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

type ClipboardAdornmentProps = InputAdornmentProps & {
  isClipboardButtonVisible: boolean;
  onCopy?: VoidFunction;
};

export const ClipboardAdornment = (props: ClipboardAdornmentProps) => {
  const { isClipboardButtonVisible, onCopy, ...restAdornmentProps } = props;

  if (!isClipboardButtonVisible) {
    return null;
  }

  return (
    <InputAdornment {...restAdornmentProps}>
      <IconButton onClick={onCopy} size="medium">
        <ContentCopyIcon fontSize="small" />
      </IconButton>
    </InputAdornment>
  );
};
