import { Search as SearchIcon } from '@mui/icons-material';
import { InputAdornment } from '@mui/material';

type StartAdornmentProps = {
  size?: 'small' | 'medium';
};

export const StartAdornment = (props: StartAdornmentProps) => {
  const { size = 'medium' } = props;

  return (
    <InputAdornment position="start">
      <SearchIcon fontSize={size} sx={{ color: 'neutral.700' }} />
    </InputAdornment>
  );
};
