import { Card, type CardProps, Stack, type StackProps, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { Assign } from 'utility-types';

import { ModalToolbar } from './ModalToolbar';

const StyledRoot = styled(Card)(({ theme }) => ({
  flexShrink: 0,
  display: 'flex',
  flexDirection: 'column',
  width: theme.dimensions.modal.sidebar.width,

  '.ModalSidebar-content': {
    overflowY: 'auto',
    marginBottom: theme.spacing(1),
    flexShrink: 0,
  },

  '.ModalSidebar-additional': {
    marginTop: 'auto',
    marginBottom: theme.spacing(1),
  },

  '.ModalSidebar-actions': {
    marginTop: 'auto',
    flexShrink: 0,
  },

  '.ModalSidebar-additional ~ .ModalSidebar-actions': {
    marginTop: 0,
  },

  '.ModalSidebar-container': {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2, 3),
    overflow: 'hidden',
  },
})) as typeof Card;

const ModalSidebarContainer = (props: React.PropsWithChildren) => <div className="ModalSidebar-container" {...props} />;

const StyledModalSidebarContent = styled(Stack)(({ theme }) => ({
  paddingBottom: theme.spacing(2),
  borderBottom: theme.shape.divider,
})) as typeof Stack;

export type ModalSidebarContentProps = Omit<StackProps, 'className'>;

export const ModalSidebarContent = (props: ModalSidebarContentProps) => (
  <StyledModalSidebarContent spacing={2} className="ModalSidebar-content" {...props} />
);

export const ModalSidebarAdditional = (props: React.PropsWithChildren) => (
  <div className="ModalSidebar-additional" {...props} />
);

export const ModalSidebarActions = (props: StackProps) => (
  <Stack {...props} className="ModalSidebar-actions" direction="row" spacing={2} />
);

export type ModalSidebarProps = Assign<
  CardProps,
  {
    title?: React.ReactNode;
  }
>;

export const ModalSidebar = (props: ModalSidebarProps) => {
  const { title = 'Дополнительные сведения', children, ...restProps } = props;

  return (
    <StyledRoot {...restProps}>
      {title && (
        <ModalToolbar>
          <Typography variant="body1" fontWeight={500} px={1}>
            {title}
          </Typography>
        </ModalToolbar>
      )}
      <ModalSidebarContainer>{children}</ModalSidebarContainer>
    </StyledRoot>
  );
};
