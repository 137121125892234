import { paramsToQueryKey } from '@/helpers/transformation.helpers';
import { DrugsSearchParams, FetchConflictingDeduplicationDrugsParams, FetchDrugImagesParams } from './drugs.api';

const PREFIX = 'drugs' as const;

// ----------------------------------------------------------------------

export const DrugsQueryKeys = {
  root: [PREFIX],

  searchDrugs: (params?: DrugsSearchParams) => paramsToQueryKey([PREFIX, 'search'], params),

  refreshCurrentSearch: () => [PREFIX, 'search'],

  drug: (drugId: string | number | undefined) => [PREFIX, drugId?.toString(), 'view'],

  receptionFeatures: (drugId?: string | number) => [PREFIX, drugId?.toString(), 'receptionFeatures'],

  drugStatuses: (drugId?: number) => [PREFIX, drugId?.toString()],

  fetchDosages: (drugId?: number) => [PREFIX, 'dosages', drugId?.toString()],

  getMakerDeduplicationConflictingDrugsKey: (mainMakerId: number, params: FetchConflictingDeduplicationDrugsParams) =>
    paramsToQueryKey([PREFIX, 'conflicting-drugs', mainMakerId.toString()], params),

  getInstructionKey: (drugId: number) => [PREFIX, drugId.toString(), 'instruction'],

  getImagesKey: (params: Partial<FetchDrugImagesParams>) => paramsToQueryKey([PREFIX, 'images'], params),
} as const;
