import {
  DOCUMENT_STATUS_DEACTIVATED,
  DOCUMENT_STATUS_NEW,
  DOCUMENT_STATUS_PUBLISHED,
  DOCUMENT_STATUS_REMOVED,
  DocumentStatusId,
} from '@/constants/document-status.constants';
import { RecordColorByStatusId, RecordStatusId } from '@/constants/record-status.constants';

type GetDorimEnvironment = () => Record<'isDev' | 'isStage' | 'isProd', boolean>;

export const getDorimEnvironment: GetDorimEnvironment = () => {
  const currentEnvironment = process.env.REACT_APP_DORIM_ENVIRONMENT;

  return {
    isDev: currentEnvironment === 'dev',
    isStage: currentEnvironment === 'stage',
    isProd: currentEnvironment === 'prod',
  };
};

export const ensureSwitchDefault = (value: never): never => {
  throw new Error(`Unexpected value: ${value}`);
};

export const getColorByRecordStatusId = (statusId: RecordStatusId) => RecordColorByStatusId[statusId];

export const getColorByDocumentStatusId = (statusId: DocumentStatusId) =>
  statusId === DOCUMENT_STATUS_DEACTIVATED || statusId === DOCUMENT_STATUS_REMOVED
    ? 'warning'
    : statusId === DOCUMENT_STATUS_NEW
    ? 'info'
    : statusId === DOCUMENT_STATUS_PUBLISHED
    ? 'success'
    : 'default';
