import { useQuery, type UseQueryOptions } from '@tanstack/react-query';
import type { PaginatedList } from '@/api/@types/pagination.types';
import { ClassificationsApi, type ClassificationsParams } from '@/api/domains/classifications.api';
import { ClassificationsQueryKeys } from '@/api/domains/classifications.query-keys';
import type { Classification } from '@/api/domains/classifications.types';

type UseClassificationsOptions = Pick<
  UseQueryOptions<PaginatedList<'items', Classification>>,
  'enabled' | 'onSuccess'
> & {
  alias: ClassificationsParams['alias'];
  parentId: ClassificationsParams['parent_id'];
};

export const useClassifications = (options: UseClassificationsOptions) => {
  const { alias, parentId, enabled, onSuccess } = options;

  const params = { alias, parent_id: parentId, limit: 50 };
  const { isFetching, data } = useQuery({
    queryKey: ClassificationsQueryKeys.classifications(params),
    queryFn: () => ClassificationsApi.fetchClassifications(params),
    enabled,
    staleTime: Infinity,
    onSuccess,
  });

  const classifications = data?.items || [];

  return { isFetching, classifications };
};
