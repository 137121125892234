import { useNavigate } from 'react-router-dom';

import { usePromoContractsStoreSelectedContract } from '../promo-contracts.store';
import { PromoContractDataGridRowData } from '../data-grids';
import {
  ViewActionIconButton,
  ViewMainActionButton,
  type ViewActionIconButtonProps,
  type ViewMainActionButtonProps,
} from '@/components/actions';
import { DorimPromoPaths } from '@/router/paths/dorim-promo.path';

type PromoContractsMainViewButtonProps = Omit<ViewMainActionButtonProps, 'startIcon'>;

export const PromoContractsMainViewButton = (props: PromoContractsMainViewButtonProps) => {
  const navigate = useNavigate();
  const selectedContract = usePromoContractsStoreSelectedContract();

  const handleClick = () => {
    if (!selectedContract?.id) {
      return;
    }

    navigate(DorimPromoPaths.generateContractPath({ contractId: String(selectedContract.id) }));
  };

  return <ViewMainActionButton disabled={!selectedContract?.id} {...props} onClick={handleClick} />;
};

export type PromoContractsInlineViewButtonProps = Omit<ViewActionIconButtonProps, 'children' | 'title'> & {
  selectedContract: PromoContractDataGridRowData;
};

export const PromoContractsInlineViewButton = (props: PromoContractsInlineViewButtonProps) => {
  const { selectedContract, ...restOfProps } = props;

  const navigate = useNavigate();

  const handleClick = () => {
    if (!selectedContract?.id) {
      return;
    }

    navigate(DorimPromoPaths.generateContractPath({ contractId: String(selectedContract.id) }));
  };

  return <ViewActionIconButton onClick={handleClick} {...restOfProps} />;
};
