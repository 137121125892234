import { lazy } from 'react';

export const EimzoDocumentsPage = lazy(
  () =>
    import(
      /* webpackPreload: true */
      /* webpackChunkName: "EimzoDocumentsPage" */
      './EimzoDocumentsPage'
    ),
);
