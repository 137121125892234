import {
  CalendarMonth as CalendarMonthIcon,
  LabelOutlined as LabelOutlinedIcon,
  Person as PersonIcon,
} from '@mui/icons-material';
import { Maker } from '@/api/domains/makers.types';
// ----------------------------------------------------------------------
import { ModalInfo, ModalSidebarContent } from '@/components/Modal';
import { NamesByRecordStatusId, RECORD_STATUS_UNKNOWN } from '@/constants/record-status.constants';
import { formatDateTime } from '@/helpers/format.helpers';

export type MakersModalInfoProps = {
  maker: Maker | null | undefined;
};

// ----------------------------------------------------------------------

export const MakersModalInfo = (props: MakersModalInfoProps) => {
  const { maker } = props;

  if (!maker) {
    return null;
  }

  return (
    <ModalSidebarContent>
      <ModalInfo
        title="Информация"
        data={[
          {
            icon: <PersonIcon fontSize="small" />,
            label: 'Автор',
            value: maker.author?.name || '',
          },
          {
            icon: <CalendarMonthIcon fontSize="small" />,
            label: 'Дата создания',
            value: formatDateTime(maker.created_at, 'dd.MM.yyyy hh:mm:ss'),
          },
          {
            icon: <LabelOutlinedIcon fontSize="small" />,
            label: 'Статус',
            value: NamesByRecordStatusId[maker.record_status_id] || RECORD_STATUS_UNKNOWN,
          },
        ]}
      />
    </ModalSidebarContent>
  );
};
