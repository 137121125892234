export const XLSX_FILE_MIME_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
export const XLSX_FILE_EXTENSION = '.xlsx';
export const XLS_FILE_MIME_TYPE = 'application/vnd.ms-excel';
export const XLS_FILE_EXTENSION = '.xls';

export const SPREADSHEET_FILE_TYPES = [
  XLSX_FILE_MIME_TYPE,
  XLS_FILE_MIME_TYPE,
  XLSX_FILE_EXTENSION,
  XLS_FILE_EXTENSION,
];

export const ACCEPTED_SPREADSHEET_FILE_TYPES = SPREADSHEET_FILE_TYPES.join(',');

export const IMAGE_FILE_TYPES = ['image/png', 'image/jpg', 'image/jpeg', 'image/webp'];

export const ACCEPTED_IMAGE_FILE_TYPES = IMAGE_FILE_TYPES.join(',');
