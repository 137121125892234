import { Link as RouterLink, To as RouterLinkTo } from 'react-router-dom';
import { Typography, TypographyTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { styled } from '@mui/material/styles';
import { useActiveRoute } from '@/hooks/useActiveRoute';
import { getLastPathElement } from '@/helpers/router.helpers';

// ----------------------------------------------------------------------

export type SidebarSubmenuButtonProps = {
  to: RouterLinkTo;
  title: string;
  onClick?: VoidFunction;
};

type StyledProps = {
  isActive?: boolean;
};

// ----------------------------------------------------------------------

const StyledRoot = styled(Typography, {
  shouldForwardProp: propName => propName !== 'isActive',
})<StyledProps>(
  ({ theme }) => ({
    display: 'block',
    width: '100%',
    padding: theme.spacing(1.5),
    borderRadius: theme.shape.borderRadius,
    textDecoration: 'none',
    color: '#637381',
    transition: theme.transitions.create(['color', 'backgroundColor']),

    ':hover': {
      backgroundColor: '#E6EBF0',
    },
  }),
  ({ isActive, theme }) =>
    isActive && {
      color: theme.palette.custom.blue,
    },
) as OverridableComponent<TypographyTypeMap<StyledProps>>;

// ----------------------------------------------------------------------

export const SidebarSubmenuButton = (props: SidebarSubmenuButtonProps) => {
  const { to, title, onClick } = props;

  const isActiveRoute = useActiveRoute(to);

  return (
    <StyledRoot
      isActive={isActiveRoute}
      component={RouterLink}
      to={to}
      fontSize={14}
      onClick={onClick}
      data-testid={`sidebar-item-${getLastPathElement(to)}`}
    >
      {title}
    </StyledRoot>
  );
};
