import { useUnmount } from 'react-use';
import { devtools } from 'zustand/middleware';
import { createStoreWithReset } from '@/libs/zustand';

type RegistryFileStore = {
  registryFile: File | null;

  actions: {
    setRegistryFile: (file: File) => void;
  };
};

const [useRegistryFileStore, resetRegistryFileStore] = createStoreWithReset<RegistryFileStore>()(
  devtools(
    set => ({
      registryFile: null,

      actions: {
        setRegistryFile: requisitionListFile =>
          set(
            {
              registryFile: requisitionListFile,
            },
            false,
            'setRegistryFile',
          ),
      },
    }),
    {
      name: 'registry-file-store',
      enabled: process.env.NODE_ENV === 'development',
    },
  ),
);

export const useRegistryFile = () => useRegistryFileStore(state => state.registryFile);

export const useRegistryFileStoreActions = () => useRegistryFileStore(state => state.actions);

export const useResetRegistryFileStoreOnUnmount = () => useUnmount(resetRegistryFileStore);

export { resetRegistryFileStore };
