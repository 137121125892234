import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { alpha, Theme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export default function Autocomplete2(theme: Theme) {
  return {
    MuiAutocomplete: {
      defaultProps: {
        loadingText: 'Загрузка...',
        noOptionsText: 'Нет данных',
        popupIcon: <ExpandMoreIcon fontSize="small" color="inherit" />,
        slotProps: {
          popper: {
            placement: 'bottom-start',
          },
        },
      },
      styleOverrides: {
        root: {
          '& span.MuiAutocomplete-tag': {
            ...theme.typography.body2,
            width: 24,
            height: 24,
            lineHeight: '24px',
            textAlign: 'center',
            borderRadius: theme.shape.borderRadius,
            backgroundColor: alpha(theme.palette.grey[500], 0.16),
          },
        },
        popper: {
          width: '100%',
          maxWidth: 660,
        },
        paper: {
          boxShadow: theme.customShadows.dropdown,
        },
        listbox: {
          padding: theme.spacing(0, 1),
        },
        option: {
          ...theme.typography.body2,
          padding: theme.spacing(1),
          margin: theme.spacing(0.75, 0),
          borderRadius: theme.shape.borderRadius,
        },
      },
    },
  };
}
