import { ClassificationsParams, ClassificationsSearchParams } from '@/api/domains/classifications.api';
import { paramsToQueryKey } from '@/helpers/transformation.helpers';

// ----------------------------------------------------------------------

const PREFIX = 'classifications' as const;

// ----------------------------------------------------------------------

export const ClassificationsQueryKeys = {
  root: [PREFIX],

  classification: (classId?: number | string) => [PREFIX, classId?.toString(), 'view'],

  classifications: (params?: ClassificationsParams) => paramsToQueryKey([PREFIX, 'list'], params),

  searchClassifications: (params?: ClassificationsSearchParams) => paramsToQueryKey([PREFIX, 'search'], params),

  classificationsTree: (params?: ClassificationsParams) => paramsToQueryKey([PREFIX, 'tree-list'], params),

  searchClassificationsTree: (params?: ClassificationsSearchParams) =>
    paramsToQueryKey([PREFIX, 'tree-search'], params),
};
