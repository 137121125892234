import { lazy } from 'react';

export const ContractDocumentPage = lazy(
  () =>
    import(
      /* webpackPreload: true */
      /* webpackChunkName: "ContractDocumentPage" */
      './ContractDocumentPage'
    ),
);
