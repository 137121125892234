import { lazy } from 'react';

export const ReferencePriceRegistryViewPage = lazy(
  () =>
    import(
      /* webpackPreload: true */
      /* webpackChunkName: "ReferencePriceRegistryViewPage" */
      './ReferencePriceRegistryViewPage'
    ),
);
