import { generatePath } from 'react-router';
import { joinPath, ExtractPathParams } from '@/libs/react-router';

const ROOT_PATH = '/dorim-promo';
const CONTRACT_TEMPLATES_PATH = joinPath(ROOT_PATH, 'contract-templates');
const CONTRACTS_PATH = joinPath(ROOT_PATH, 'contracts');
const PROMO_PRODUCTS_PATH = joinPath(ROOT_PATH, 'products');
const CONTRACT_PATH = joinPath(CONTRACTS_PATH, ':contractId');
const CONTRACT_DOCUMENT_PATH = joinPath(CONTRACT_PATH, 'contract-document');
const SPECIFICATION_DOCUMENT_PATH = joinPath(CONTRACT_PATH, 'specification-document');

const MARKETING_ORG_PATH = joinPath(CONTRACT_PATH, 'marketing-org/:marketingOrgId');
const ANALYTICS_PATH = joinPath(MARKETING_ORG_PATH, 'analytics/:drugId');

const PLANOGRAM_TASKS_CONTROL = joinPath(ROOT_PATH, 'planogram-tasks-control');
const PLANOGRAM_TASK_CONTROL_DETAIL_PATH = joinPath(PLANOGRAM_TASKS_CONTROL, ':taskId');

export type ContractDocumentPathParams = ExtractPathParams<typeof CONTRACT_DOCUMENT_PATH>;
export type SpecificationDocumentPathParams = ExtractPathParams<typeof SPECIFICATION_DOCUMENT_PATH>;
export type ContractPathParams = ExtractPathParams<typeof CONTRACT_PATH>;
export type ContractDrugAnalyticsPathParams = ExtractPathParams<typeof ANALYTICS_PATH>;
export type ContractMarketingOrgPathParams = ExtractPathParams<typeof MARKETING_ORG_PATH>;
export type PlanogramTaskControlDetailPathParams = ExtractPathParams<typeof PLANOGRAM_TASK_CONTROL_DETAIL_PATH>;

export const DorimPromoPaths = {
  ROOT_PATH,

  CONTRACT_DOCUMENT_PATH,
  generateContractDocumentPath: <TParams extends ContractDocumentPathParams>(params: TParams) =>
    generatePath(CONTRACT_DOCUMENT_PATH, params),

  SPECIFICATION_DOCUMENT_PATH,
  generateSpecificationDocumentPath: <TParams extends SpecificationDocumentPathParams>(params: TParams) =>
    generatePath(SPECIFICATION_DOCUMENT_PATH, params),

  CONTRACT_TEMPLATES_PATH,
  CONTRACTS_PATH,
  PROMO_PRODUCTS_PATH,

  CONTRACT_PATH,
  generateContractPath: <TParams extends ContractPathParams>(params: TParams) => generatePath(CONTRACT_PATH, params),

  MARKETING_ORG_PATH,
  generateMarketingOrgPath: <TParams extends ContractMarketingOrgPathParams>(params: TParams) =>
    generatePath(MARKETING_ORG_PATH, params),

  ANALYTICS_PATH,
  generateAnalyticsPath: <TParams extends ContractDrugAnalyticsPathParams>(params: TParams) =>
    generatePath(ANALYTICS_PATH, params),

  PLANOGRAM_TASKS_CONTROL,
  PLANOGRAM_TASK_CONTROL_DETAIL_PATH,
  generatePlanogramTaskControlDetailPath: (params: PlanogramTaskControlDetailPathParams) =>
    generatePath(PLANOGRAM_TASK_CONTROL_DETAIL_PATH, params),
} as const;
