import { useMemo } from 'react';
import { useLatest } from 'react-use';
import { some } from 'lodash';

import { Permissions, PermissionsAction, PermissionsStatus } from '@/api/domains/profile.types';
import { useProfilePermissions } from './useProfilePermissions';

const makeCannot =
  <T extends (...args: any[]) => boolean>(can: T) =>
  (...args: Parameters<T>) =>
    !can(...args);

export const usePermissions = () => {
  const { data: permissions, isLoading: isLoadingPermissions } = useProfilePermissions();

  const latestPermissions = useLatest(permissions);

  const methods = useMemo(() => {
    const can = (actionName: PermissionsAction['name'], entityName: Permissions['name']) => {
      const entity = latestPermissions.current?.[entityName];
      if (!entity) return false;

      return !!entity.actions[actionName];
    };

    const cannot = makeCannot(can);

    const canStatuses = (
      accessLevel: PermissionsStatus['access_level'],
      entityName: Permissions['name'],
      ids: PermissionsStatus['id'][],
    ) => {
      const statuses = latestPermissions.current?.[entityName]?.statuses;
      if (!statuses) return false;

      return ids.every(
        statusId =>
          statuses[statusId].access_level === accessLevel ||
          (accessLevel === 'read' && statuses[statusId].access_level === 'write'),
      );
    };

    const cannotStatuses = makeCannot(canStatuses);

    const isChangeStatusesAllowed = (entityName: Permissions['name']) => {
      const statuses = latestPermissions.current?.[entityName]?.statuses;
      if (!statuses) return false;

      return some(statuses, { access_level: 'write' });
    };

    return {
      can,
      cannot,
      canStatuses,
      cannotStatuses,
      isChangeStatusesAllowed,
    };
  }, []);

  return {
    ...methods,
    isLoadingPermissions,
  };
};
