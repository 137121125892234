import { lazy } from 'react';

export const RegistryImportLogErrorsPage = lazy(
  () =>
    import(
      /* webpackPreload: true */
      /* webpackChunkRegistersMain: "ErrorsPage" */
      './ErrorsPage'
    ),
);
