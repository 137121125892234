import { Fragment } from 'react';
import { Circle as CircleIcon } from '@mui/icons-material';
import { Stack, StackProps, Typography, TypographyProps } from '@mui/material';
import { has } from 'lodash';

type HeaderTitleProps = Omit<StackProps, 'component' | 'direction'> &
  TypographyProps & {
    icon?: React.ReactNode;
  };

type HeaderComposableTitleProps = Omit<HeaderTitleProps, 'children'> & {
  chunks: Array<string>;
};

export const HeaderComposableTitle = (props: HeaderComposableTitleProps) => {
  const { icon, chunks } = props;

  return (
    <Stack direction="row" alignItems="center" flexGrow={1} spacing={1}>
      {icon}

      {chunks.map((chunk, index, array) => (
        <Fragment key={index}>
          <Typography component="span" variant="h5" sx={{ color: index === 0 ? 'text.primary' : 'text.secondary' }}>
            {chunk}
          </Typography>
          {has(array, index + 1) && <CircleIcon sx={{ width: 8, height: 8, color: 'text.disabled' }} />}
        </Fragment>
      ))}
    </Stack>
  );
};
