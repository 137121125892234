import { devtools } from 'zustand/middleware';
import { Registry } from '@/api/domains/registers.types';
import { createStoreWithReset } from '@/libs/zustand';
import { useUnmount } from 'react-use';

export type RegistersStore = {
  selectedRegistry: Registry | null;
  isLoadFileModalOpen: boolean;

  actions: {
    setSelectedRegistry: (registry: Registry) => void;
    resetSelectedRegistry: VoidFunction;

    openLoadFileModal: () => void;
    closeLoadFileModal: () => void;
  };
};

const [useRegistersStore, resetRegistersStore] = createStoreWithReset<RegistersStore>()(
  devtools(
    set => ({
      selectedRegistry: null,
      isLoadFileModalOpen: false,

      actions: {
        setSelectedRegistry: registry => set({ selectedRegistry: registry }, false, 'setSelectedRegistry'),
        resetSelectedRegistry: () => set({ selectedRegistry: null }, false, 'resetSelectedRegistry'),

        openLoadFileModal: () => set({ isLoadFileModalOpen: true }, false, 'openLoadFileModal'),
        closeLoadFileModal: () => set({ isLoadFileModalOpen: false }, false, 'closeLoadFileModal'),
      },
    }),
    {
      name: 'registers-store',
      enabled: process.env.NODE_ENV === 'development',
    },
  ),
);

export const useResetRegistersStoreOnUnmount = () => useUnmount(resetRegistersStore);

export const useRegistersStoreActions = () => useRegistersStore(state => state.actions);
export const useRegistersStoreIsContextWindowsOpen = () =>
  useRegistersStore(state => ({
    isLoadFileModalOpen: state.isLoadFileModalOpen,
  }));

export const useSelectedRegistry = () => useRegistersStore(state => state.selectedRegistry);

export { resetRegistersStore, useRegistersStore };
