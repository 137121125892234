import { lazy } from 'react';

export const DocumentPage = lazy(
  () =>
    import(
      /* webpackPreload: true */
      /* webpackChunkName: "DocumentPage" */
      './DocumentPage'
    ),
);
