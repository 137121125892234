import { Theme } from '@mui/material/styles';
import { CalendarMonthOutlined as CalendarMonthOutlinedIcon } from '@mui/icons-material';

export default function DatePicker(theme: Theme) {
  return {
    MuiDatePicker: {
      defaultProps: {
        slotProps: {
          openPickerIcon: {
            component: CalendarMonthOutlinedIcon,
          },
          textField: {
            inputProps: {
              style: {
                caretColor: 'transparent',
                cursor: 'pointer',
              },
            },
          },
        },
      },
    },

    MuiPickersMonth: {
      styleOverrides: {
        monthButton: {
          '&&.Mui-selected': {
            backgroundColor: theme.palette.secondary.main,

            '&:hover': {
              backgroundColor: theme.palette.secondary.dark,
            },
          },
        },
      },
    },

    MuiPickersYear: {
      styleOverrides: {
        yearButton: {
          '&&.Mui-selected': {
            backgroundColor: theme.palette.secondary.main,

            '&:hover': {
              backgroundColor: theme.palette.secondary.dark,
            },
          },
        },
      },
    },
  };
}
