import {
  ConfirmationDialogActionCancelButton,
  ConfirmationDialogActionProceedButton,
  ConfirmationDialogActions,
  ConfirmationDialogBodyMutedText,
  ConfirmationDialogContainer,
  ConfirmationDialogContainerProps,
  ConfirmationDialogHeader,
} from '@/components/dialogs/elements.confirmation-dialog';

// ----------------------------------------------------------------------

export type ConfirmationEditDialogProps = Omit<ConfirmationDialogContainerProps, 'subject'> & {
  isLoading: boolean;
  onProceed: VoidFunction;
};

// ----------------------------------------------------------------------

export const ConfirmationEditDialog = (props: ConfirmationEditDialogProps) => {
  const { isLoading, onClose, onProceed, ...restOfDialogProps } = props;

  return (
    <ConfirmationDialogContainer subject="edit" onClose={onClose} {...restOfDialogProps}>
      <ConfirmationDialogHeader>Применить внесённые изменения?</ConfirmationDialogHeader>

      <ConfirmationDialogBodyMutedText>Вы внесли изменения и хотели бы их сохранить?</ConfirmationDialogBodyMutedText>

      <ConfirmationDialogActions>
        <ConfirmationDialogActionCancelButton onClick={onClose} />
        <ConfirmationDialogActionProceedButton autoFocus loading={isLoading} onClick={onProceed}>
          Сохранить
        </ConfirmationDialogActionProceedButton>
      </ConfirmationDialogActions>
    </ConfirmationDialogContainer>
  );
};
