import Autocomplete from '@mui/material/Autocomplete';

import type { SearchAutocompleteFieldProps } from './types';
import { SearchField } from './SearchField';

export const SearchAutocompleteField = (props: SearchAutocompleteFieldProps) => {
  const { value, hints, sx, setInputValue, onClear, ...restOfProps } = props;

  const handleChange = (_: React.SyntheticEvent, value: string) => {
    setInputValue?.(value);
  };

  return (
    <Autocomplete
      fullWidth
      disableClearable
      sx={sx}
      value={value}
      inputValue={value}
      openOnFocus={false}
      options={hints || []}
      onChange={handleChange}
      renderInput={params => <SearchField {...params} {...restOfProps} fullWidth value={value} onClear={onClear} />}
    />
  );
};
