import { ClipboardEventHandler, ReactNode } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { TextField, TextFieldProps } from '@mui/material';
import { MakerField, MakerFieldProps } from '@/components/form-controls/MakerField';

// ----------------------------------------------------------------------

export type RHFMakerFieldProps = Omit<MakerFieldProps, 'renderInput'> & {
  name: string;
  label?: string;
  helperText?: ReactNode;
  TextFieldProps?: Omit<TextFieldProps, 'label' | 'error' | 'helperText'>;
};

// ----------------------------------------------------------------------

export const RHFMakerField = (props: RHFMakerFieldProps) => {
  const { name, label, helperText, TextFieldProps, ...restOfProps } = props;
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, onChange, ...restOfFiledProps }, fieldState: { error } }) => {
        const reassignedOnChange: MakerFieldProps['onChange'] = (_, value) => {
          onChange(value);
        };

        const handlePaste: ClipboardEventHandler<HTMLInputElement> = event => {
          event.preventDefault();

          const clipboardData = event.clipboardData.getData('text');

          onChange(clipboardData.trim());
        };

        return (
          <MakerField
            {...restOfFiledProps}
            onChange={reassignedOnChange}
            onPaste={handlePaste}
            renderInput={inputParams => (
              <TextField
                error={!!error}
                label={label}
                placeholder="Поиск"
                helperText={error ? error?.message : helperText}
                {...TextFieldProps}
                {...inputParams}
              />
            )}
            {...restOfProps}
          />
        );
      }}
    />
  );
};
