import { Suspense } from 'react';
import { Navigate } from 'react-router';

import { ProgressBar } from '@/components/ProgressBar';
import { BreadcrumbLink, LazyBreadcrumb, RouteObjectWithBreadcrumbs } from '@/components/Breadcrumbs';
import { EimzoDocumentsPage, DocumentPage, SignatureProtocolPage, useEimzoDocument } from '@/pages/e-imzo-documents';
import { EimzoDocumentLayout } from '@/layouts/EimzoLayouts';
import { EimzoDocumentsPath, EimzoDocumentPathParams } from '../paths/e-imzo-documents.paths';
import { ActionAccessGuard } from '@/guards/access-guards';

export const EimzoDocumentsRoute: RouteObjectWithBreadcrumbs = {
  path: EimzoDocumentsPath.ROOT_PATH,
  handle: {
    breadcrumb: {
      Component: () => <BreadcrumbLink to={EimzoDocumentsPath.ROOT_PATH}>Документы для подписи</BreadcrumbLink>,
    },
  },
  children: [
    {
      index: true,
      element: (
        <Suspense fallback={<ProgressBar />}>
          <ActionAccessGuard actionName="view" entityName="e_imzo">
            <EimzoDocumentsPage />
          </ActionAccessGuard>
        </Suspense>
      ),
    },
    {
      path: EimzoDocumentsPath.EIMZO_DOCUMENTS_DETAIL_PATH,
      element: (
        <ActionAccessGuard actionName="view" entityName="e_imzo">
          <EimzoDocumentLayout />
        </ActionAccessGuard>
      ),
      children: [
        {
          index: true,
          element: <Navigate replace to={EimzoDocumentsPath.ROOT_PATH} />,
        },
        {
          path: EimzoDocumentsPath.EIMZO_DOCUMENTS_DOCUMENT_PATH,
          element: (
            <Suspense fallback={<ProgressBar />}>
              <DocumentPage />,
            </Suspense>
          ),
          handle: {
            breadcrumb: (params: EimzoDocumentPathParams) => ({
              Component: () => {
                const { data: eimzoDocument, isInitialLoading } = useEimzoDocument();

                return (
                  <LazyBreadcrumb isLoading={isInitialLoading}>
                    <BreadcrumbLink to={EimzoDocumentsPath.generateDocumentPath(params)}>
                      {eimzoDocument?.document_type || ''} №{eimzoDocument?.document_number}
                    </BreadcrumbLink>
                  </LazyBreadcrumb>
                );
              },
            }),
          },
        },
        {
          path: EimzoDocumentsPath.EIMZO_DOCUMENTS_SIGNATURE_PROTOCOL_PATH,
          element: (
            <Suspense fallback={<ProgressBar />}>
              <SignatureProtocolPage />,
            </Suspense>
          ),
          handle: {
            breadcrumb: (params: EimzoDocumentPathParams) => ({
              Component: () => {
                const { data: eimzoDocument, isInitialLoading } = useEimzoDocument();

                return (
                  <LazyBreadcrumb isLoading={isInitialLoading}>
                    <BreadcrumbLink to={EimzoDocumentsPath.generateDocumentPath(params)}>
                      {eimzoDocument?.document_type || ''} №{eimzoDocument?.document_number}
                    </BreadcrumbLink>
                  </LazyBreadcrumb>
                );
              },
            }),
          },
        },
      ],
    },
  ],
};
