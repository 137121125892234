import { paramsToQueryKey } from '@/helpers/transformation.helpers';
import type { PaginationParams } from '../@types/pagination.types';
import { FetchEimzoDocumentParams } from './e-imzo-documents.api';

const PREFIX = 'e-imzo-document';

export const EimzoDocumentsQueryKeys = {
  rootKey: [PREFIX] as const,
  getDocumentsKey: (params?: PaginationParams) => paramsToQueryKey([PREFIX, 'list'], params),
  getDocumentKey: (params: Partial<FetchEimzoDocumentParams>) => paramsToQueryKey([PREFIX, 'document'], params),
};
