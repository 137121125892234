import { useQuery } from '@tanstack/react-query';

import { ProfileApi } from '@/api/domains/profile.api';
import { ProfileQueryKeys } from '@/api/domains/profile-query.keys';

export const useProfile = () => {
  const {
    isLoading,
    isFetching,
    data: profile,
  } = useQuery({
    queryKey: ProfileQueryKeys.rootKey,
    queryFn: () => ProfileApi.fetchProfile(),
    staleTime: Infinity,
    retry: false,
  });

  const contractor = profile?.contractor;
  const user = profile?.user;
  const fullName = `${profile?.user.first_name} ${profile?.user.last_name}`.trim();

  return {
    contractor,
    fullName,
    isFetching,
    isLoading,
    profile,
    user,
  };
};
