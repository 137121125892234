import {
  DeduplicateActionButton,
  DeleteActionIconButton,
  DuplicateActionIconButton,
  EditActionIconButton,
} from '../actions';

/**
 * @deprecated Use `import { DuplicateActionIconButton } from '@/components/actions';` instead
 */
export const DataGridActionDuplicate = DuplicateActionIconButton;

/**
 * @deprecated Use `import { EditActionIconButton } from '@/components/actions';` instead
 */
export const DataGridActionEdit = EditActionIconButton;

/**
 * @deprecated Use `import { DeleteActionIconButton } from '@/components/actions';` instead
 */
export const DataGridActionDelete = DeleteActionIconButton;

/**
 * @deprecated Use `import { DeduplicateActionButton } from '@/components/actions';` instead
 */
export const DataGridActionDeduplicate = DeduplicateActionButton;
