import { EditOutlined as EditOutlinedIcon } from '@mui/icons-material';
import { type OverridableComponent } from '@mui/material/OverridableComponent';
import { type ButtonTypeMap } from '@mui/material';
import {
  ActionButton,
  type ActionButtonProps,
  ActionIconButton,
  type ActionIconButtonProps,
  MainActionButton,
  type MainActionButtonProps,
} from '@/components/buttons';

export type EditMainActionButtonProps = MainActionButtonProps;

export const EditMainActionButton: OverridableComponent<ButtonTypeMap<EditMainActionButtonProps>> = (
  props: EditMainActionButtonProps,
) => {
  const { children = 'Редактировать', ...restProps } = props;

  return (
    <MainActionButton startIcon={<EditOutlinedIcon fontSize="small" />} {...restProps}>
      {children}
    </MainActionButton>
  );
};

export type EditActionButtonProps = ActionButtonProps;

export const EditActionButton: OverridableComponent<ButtonTypeMap<EditActionButtonProps>> = (
  props: EditActionButtonProps,
) => {
  const { children = 'Редактировать', ...restProps } = props;

  return (
    <ActionButton startIcon={<EditOutlinedIcon fontSize="small" />} {...restProps}>
      {children}
    </ActionButton>
  );
};

export type EditActionIconButtonProps = ActionIconButtonProps;

export const EditActionIconButton: OverridableComponent<ButtonTypeMap<EditActionIconButtonProps>> = (
  props: EditActionIconButtonProps,
) => (
  <ActionIconButton title="Редактировать" {...props}>
    <EditOutlinedIcon fontSize="medium" />
  </ActionIconButton>
);
