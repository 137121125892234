import { isPossiblePhoneNumber } from 'libphonenumber-js';
import { isNil } from 'lodash';
import * as Yup from 'yup';

const EMAIL_REGEX =
  // eslint-disable-next-line max-len
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

Yup.addMethod(Yup.string, 'isPossibleEmailAddress', function (message?: string) {
  return this.test('test-email-address', (value, testContext) => {
    const { createError } = testContext;

    if (!value) {
      return true;
    }

    const isValidEmailAddress = EMAIL_REGEX.test(value);

    return isValidEmailAddress ? true : createError({ message: message ?? 'Введите корректный адрес почты' });
  });
});

Yup.addMethod(Yup.string, 'isPossiblePhoneNumber', function (message?: string) {
  return this.test('test-phone-number', (value, testContext) => {
    const { createError } = testContext;

    if (!value) {
      return true;
    }

    return isPossiblePhoneNumber(`+${value}`)
      ? true
      : createError({ message: message ?? 'Введите корректный номер телефона' });
  });
});

export type FileValidationOptions = {
  correctTypes: string[];
  maxSize: number;
  typeErrorMessage?: string;
  sizeErrorMessage?: string;
};
Yup.addMethod(Yup.mixed<File>, 'fileValidation', function (options: FileValidationOptions) {
  return this.test('file-validation', function (value, { createError }) {
    if (isNil(value)) return true;

    if (!options.correctTypes.includes(value.type)) {
      return createError({ message: options.typeErrorMessage ?? 'Выбран недопустимый формат файла' });
    }

    if (value.size > options.maxSize) {
      return createError({
        message: options.sizeErrorMessage ?? `Максимальный размер файла ${options.maxSize / (1024 * 1024)} Мб`,
      });
    }

    return true;
  });
});

const REQUIRED_FIELD_MESSAGE = 'Обязательное поле';

Yup.addMethod(Yup.ObjectSchema, 'initialNullable', function () {
  return this.test('initialNullable', REQUIRED_FIELD_MESSAGE, value => !isNil(value));
});
Yup.addMethod(Yup.DateSchema, 'initialNullableDate', function () {
  return this.test('initialNullable', REQUIRED_FIELD_MESSAGE, value => !isNil(value));
});

export { Yup };
