import { RemoveRedEyeOutlined as RemoveRedEyeOutlinedIcon } from '@mui/icons-material';
import {
  ActionButton,
  type ActionButtonProps,
  ActionIconButton,
  type ActionIconButtonProps,
  MainActionButton,
  type MainActionButtonProps,
} from '@/components/buttons';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { ButtonTypeMap } from '@mui/material';

export type ViewMainActionButtonProps = MainActionButtonProps;
export const ViewMainActionButton: OverridableComponent<ButtonTypeMap<ViewMainActionButtonProps>> = (
  props: ViewMainActionButtonProps,
) => {
  const { children = 'Просмотреть' } = props;

  return (
    <MainActionButton startIcon={<RemoveRedEyeOutlinedIcon fontSize="small" />} {...props}>
      {children}
    </MainActionButton>
  );
};

export type ViewActionButtonProps = ActionButtonProps;
export const ViewActionButton: OverridableComponent<ButtonTypeMap<ViewActionButtonProps>> = (
  props: ViewActionButtonProps,
) => {
  const { children = 'Просмотреть' } = props;

  return (
    <ActionButton startIcon={<RemoveRedEyeOutlinedIcon fontSize="small" />} {...props}>
      {children}
    </ActionButton>
  );
};

export type ViewActionIconButtonProps = ActionIconButtonProps;
export const ViewActionIconButton: OverridableComponent<ButtonTypeMap<ViewActionIconButtonProps>> = (
  props: ViewActionIconButtonProps,
) => (
  <ActionIconButton title="Просмотреть" {...props}>
    <RemoveRedEyeOutlinedIcon fontSize="medium" />
  </ActionIconButton>
);
