import { useMemo } from 'react';
import { has } from 'lodash';
import { MakersApi } from '@/api/domains/makers.api';
import { MakersQueryKeys } from '@/api/domains/makers.query-keys';
import type { TFilterItem } from '@/components/DataGrid';
import type { MakersDataGridRowData } from '@/components/makers/types';
import { selectMakersStoreSearchQuery, useMakersStore } from '@/components/makers/useMakersStore';
import { NamesByRecordStatusId, RECORD_STATUS_UNKNOWN } from '@/constants/record-status.constants';
import { useInfiniteLoader } from '@/hooks/useInfiniteLoader';
import { compareString } from '@/helpers/string.helpers';

// ----------------------------------------------------------------------
type UseMakersDataGridParams = {
  searchQuery?: string;
};

const initialMakers: Array<MakersDataGridRowData> = [];

export const useMakersDataGrid = (params?: UseMakersDataGridParams) => {
  const { searchQuery: passedQuery } = params ?? {};

  const defaultQuery = useMakersStore(selectMakersStoreSearchQuery);

  const query = passedQuery ?? defaultQuery;

  const trimmedQuery = query.trim();
  const ensuredQuery = trimmedQuery.length >= 3 ? trimmedQuery : undefined;

  const { isFetching, hasNextPage, data, fetchNextPage } = useInfiniteLoader({
    queryKey: MakersQueryKeys.makers({ query: ensuredQuery }),
    queryFn: ({ offset }) => MakersApi.fetchMakers({ query: ensuredQuery, offset }),
    offsetPerPage: 50,
    staleTime: Infinity,
  });

  const pageData = data?.pages;
  const loadedPagesLength = pageData?.length ?? 0;

  const { makers = initialMakers, filterData } = useMemo(() => {
    if (!pageData) {
      return {
        makers: initialMakers,
        filterData: {
          makerFilterData: [],
          countryFilterData: [],
          notesFilterData: [],
          initiatorFilterData: [],
          statusFilterData: [],
          idsFilterData: [],
        },
      };
    }

    const items = pageData.flatMap(({ items }) => items);

    const idsFilterData: Array<TFilterItem> = [];

    // MAKER FILTER DATA
    const makerUniqueEntries: Record<string, boolean> = {};
    const makerFilterData: Array<TFilterItem<string>> = [];

    // COUNTRY FILTER DATA
    const countryUniqueEntries: Record<string, boolean> = {};
    const countryFilterData: Array<TFilterItem<string>> = [];

    // NOTES FILTER DATA
    const notesUniqueEntries: Record<string, boolean> = {};
    const notesFilterData: Array<TFilterItem<string>> = [];

    // INITIATOR FILTER DATA
    const initiatorUniqueEntries: Record<number, boolean> = {};
    const initiatorFilterData: Array<TFilterItem> = [];

    // STATUS FILTER DATA
    const statusUniqueEntries: Record<number, boolean> = {};
    const statusFilterData: Array<TFilterItem> = [];

    const preparedMakers: Array<MakersDataGridRowData> = items.map(item => {
      const { id, name, country_name, description, updated_at, initiator, record_status_id } = item;

      idsFilterData.push({ id, label: String(id) });

      // MAKER FILTER DATA
      if (!has(makerUniqueEntries, name)) {
        makerUniqueEntries[name] = true;
        makerFilterData.push({ id: name, label: name });
      }

      // COUNTRY FILTER DATA
      if (!has(countryUniqueEntries, country_name)) {
        countryUniqueEntries[country_name] = true;
        countryFilterData.push({ id: country_name, label: country_name });
      }

      // NOTES FILTER DATA
      if (!has(notesUniqueEntries, description)) {
        notesUniqueEntries[description] = true;
        notesFilterData.push({ id: description, label: description });
      }

      // INITIATOR FILTER DATA
      if (initiator && !has(initiatorUniqueEntries, initiator.id)) {
        initiatorUniqueEntries[initiator.id] = true;
        initiatorFilterData.push({ id: initiator.id, label: initiator.name });
      }

      // STATUS FILTER DATA
      if (!has(statusUniqueEntries, record_status_id)) {
        statusUniqueEntries[record_status_id] = true;
        statusFilterData.push({
          id: record_status_id,
          label: NamesByRecordStatusId[record_status_id] || RECORD_STATUS_UNKNOWN,
        });
      }

      return {
        // data grid fields
        maker: name,
        country: country_name,
        notes: description,
        updateDate: updated_at,
        initiator,
        status: record_status_id,

        // meta
        id,
        originalMaker: item,
      };
    });

    return {
      makers: preparedMakers,
      filterData: {
        idsFilterData: idsFilterData.sort(({ id: a }: TFilterItem, { id: b }: TFilterItem) => a - b),
        makerFilterData: makerFilterData.sort(compareString),
        countryFilterData: countryFilterData.sort(compareString),
        notesFilterData: notesFilterData.sort(compareString),
        initiatorFilterData: initiatorFilterData.sort(compareString),
        statusFilterData: statusFilterData.sort(compareString),
      },
    };
  }, [pageData]);

  // ----------------------------------------------------------------------
  return {
    isFetching,
    hasNextPage,
    loadedPagesLength,
    makers,
    filterData,
    fetchNextPage,
  };
};
