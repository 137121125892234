import { ReactNode } from 'react';
import { Link, Stack } from '@mui/material';

import { ReactComponent as TelegramSVG } from '@/assets/icons-2.0/telegram.svg';
import { ReactComponent as CallSVG } from '@/assets/icons-2.0/call.svg';
import { ReactComponent as MenuBookSVG } from '@/assets/icons-2.0/menu_book.svg';
import { ReactComponent as NewReleasesSVG } from '@/assets/icons-2.0/new_releases.svg';
import {
  popoverSxStyle,
  StyledSupportMenuPopover,
  StyledSupportMenuPopoverButton,
  StyledSupportMenuPopoverHeader,
} from '@/modules/support/components/SupportMenu/SupportMenuPopover/styles';
import { SUPPORT_PHONE_CALLTO, SUPPORT_PHONE_FORMATTED, TELEGRAM_CHAT_LINK } from '@/constants/dorim.constants';

type SupportLinkProps = {
  href: string;
  children: ReactNode;

  target?: string;
};

const SupportMenuLink = (props: SupportLinkProps) => {
  const { href, children, target } = props;
  return (
    <Link href={href} target={target} underline="none" color="inherit">
      {children}
    </Link>
  );
};

export const SupportMenuPopover = () => (
  <StyledSupportMenuPopover>
    <StyledSupportMenuPopoverHeader>
      <Stack sx={popoverSxStyle.title}>Поддержка</Stack>
    </StyledSupportMenuPopoverHeader>
    <Stack spacing={1}>
      <SupportMenuLink href={TELEGRAM_CHAT_LINK} target={'_blank'}>
        <StyledSupportMenuPopoverButton
          fullWidth
          disabled={false}
          startIcon={<TelegramSVG width={24} />}
          sx={{ justifyContent: 'left' }}
        >
          Telegram
        </StyledSupportMenuPopoverButton>
      </SupportMenuLink>

      <SupportMenuLink href={`tel:${SUPPORT_PHONE_CALLTO}`}>
        <StyledSupportMenuPopoverButton
          fullWidth
          disabled={false}
          startIcon={<CallSVG width={24} />}
          sx={{ justifyContent: 'left' }}
        >
          {SUPPORT_PHONE_FORMATTED}
        </StyledSupportMenuPopoverButton>
      </SupportMenuLink>

      <SupportMenuLink
        href={'https://dorim-uz.notion.site/5984b04e34e14881bd795dd223a5bb4f?v=7cf30e3c2c604f29be509c61752d0d39'}
        target={'_blank'}
      >
        <StyledSupportMenuPopoverButton
          fullWidth
          disabled={false}
          startIcon={<MenuBookSVG width={24} />}
          sx={{ justifyContent: 'left' }}
        >
          Инструкция пользователя
        </StyledSupportMenuPopoverButton>
      </SupportMenuLink>

      <SupportMenuLink
        href={'https://dorim-uz.notion.site/Release-Notes-c261fad3bb804ac09340e1da148ce9ed?pvs=4'}
        target={'_blank'}
      >
        <StyledSupportMenuPopoverButton
          fullWidth
          disabled={false}
          startIcon={<NewReleasesSVG width={24} />}
          sx={{ justifyContent: 'left' }}
        >
          Информация о версиях
        </StyledSupportMenuPopoverButton>
      </SupportMenuLink>
    </Stack>
  </StyledSupportMenuPopover>
);
