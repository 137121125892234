import { Controller, useFormContext } from 'react-hook-form';
import { CustomTextField, CustomTextFieldProps } from './CustomTextField';

// ----------------------------------------------------------------------

export type RHFTextFieldProps = Omit<CustomTextFieldProps, 'onChange' | 'value'> & {
  name: string;
  isLoading?: boolean;
  isClearableField?: boolean;
  isCopyableField?: boolean;
  onClear?: VoidFunction;
};

// ----------------------------------------------------------------------

export const RHFTextField = (props: RHFTextFieldProps) => {
  const { name, helperText, onClear, ...restOfProps } = props;

  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, value, onChange, ...restOfFieldProps }, fieldState: { error } }) => (
        <CustomTextField
          value={value}
          fullWidth
          inputRef={ref}
          error={!!error}
          helperText={error ? error?.message : helperText}
          onChange={onChange}
          onClearButtonClick={() => {
            onClear?.();
            onChange('');
          }}
          {...restOfFieldProps}
          {...restOfProps}
        />
      )}
    />
  );
};
