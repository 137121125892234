import { createContext, useContext, useMemo, useState } from 'react';
import { useEffectOnce, useGetSet } from 'react-use';
import { useQueryClient } from '@tanstack/react-query';
import { AuthApi, SignInFormData } from '@/api/domains/auth.api';
import { useGlobalErrorStore } from '@/components/dialogs';
import { applyJwtInterceptors } from '@/contexts/AuthContext/jwt-interceptors';
import { TokenPair, TokenPairStorageItem, tokensStorage } from '@/contexts/AuthContext/token-storage';
import { isAccessToken, isAccessTokenExpired } from '@/contexts/AuthContext/utils';
import { httpClient } from '@/libs/axios/http-client';

// ----------------------------------------------------------------------

export type AuthState = {
  isAuthenticated: boolean;
  accessToken: string | null;
};

export type AuthMethods = {
  signIn: (formData: SignInFormData) => Promise<void>;
  signOut: () => Promise<void>;
  getAuthenticatedState: () => boolean;
  getTokenPair: () => TokenPair;
};

export type AuthProviderProps = React.PropsWithChildren<{}>;

// ----------------------------------------------------------------------

export const AuthContextMethods = createContext<AuthMethods | null>(null);

if (process.env.NODE_ENV !== 'production') {
  AuthContextMethods.displayName = 'AuthContextMethods';
}

// ----------------------------------------------------------------------

export const AuthContextState = createContext<AuthState | null>(null);

if (process.env.NODE_ENV !== 'production') {
  AuthContextState.displayName = 'AuthContextState';
}

// ----------------------------------------------------------------------

export const AuthProvider = (props: AuthProviderProps) => {
  const { children } = props;

  const [isInitialized, setIsInitialized] = useState(false);
  const [getTokenPair, setTokenPair] = useGetSet<TokenPairStorageItem>(tokensStorage.getTokenPair);

  const queryClient = useQueryClient();
  const { setGlobalError } = useGlobalErrorStore();

  const methods = useMemo(() => {
    const updateTokens = (tokenPair: TokenPair) => {
      const { access_token } = tokenPair;

      try {
        if (isAccessToken(access_token)) {
          setTokenPair(tokenPair);
          tokensStorage.setTokenPair(tokenPair);
        }
      } catch (error) {
        setGlobalError(error);
      }
    };

    // ----------------------------------------------------------------------

    const removeToken = () => {
      tokensStorage.removeTokenPair();
      setTokenPair(tokensStorage.INITIAL_TOKEN_PAIR);
    };

    // ----------------------------------------------------------------------

    const resetToUnauthenticated = () => {
      removeToken();
      queryClient.removeQueries();
    };

    // ----------------------------------------------------------------------

    const signIn = async (formData: SignInFormData) => {
      const { access_token, refresh_token } = await AuthApi.signIn(formData);

      updateTokens({ access_token, refresh_token });
    };

    // ----------------------------------------------------------------------

    const signOut = async () => {
      resetToUnauthenticated();
    };

    // ----------------------------------------------------------------------

    const refreshToken = async () => {
      const { refresh_token } = getTokenPair();

      if (refresh_token) {
        const { access_token, refresh_token: updateRefreshToken } = await AuthApi.refreshToken(refresh_token);

        updateTokens({ access_token, refresh_token: updateRefreshToken });
      }
    };

    // ----------------------------------------------------------------------

    const getAuthenticatedState = (): boolean => {
      const { access_token, refresh_token } = getTokenPair();

      return isAccessToken(access_token) && refresh_token !== null;
    };

    // ----------------------------------------------------------------------

    const initialize = async () => {
      const { access_token } = getTokenPair();

      if (isAccessToken(access_token)) {
        if (isAccessTokenExpired(access_token)) {
          try {
            await refreshToken();
          } catch (error) {
            setGlobalError(error);
          }
        }

        setIsInitialized(true);
      } else {
        resetToUnauthenticated();
        setIsInitialized(true);
      }
    };

    return {
      signIn,
      signOut,
      refreshToken,
      getTokenPair,
      resetToUnauthenticated,
      getAuthenticatedState,
      initialize,
    };
  }, [queryClient, setTokenPair, getTokenPair, setIsInitialized, setGlobalError]);

  useEffectOnce(() => {
    applyJwtInterceptors(httpClient, {
      getAuthenticatedState: methods.getAuthenticatedState,
      getAccessToken: () => methods.getTokenPair().access_token,
      tokenRefresher: methods.refreshToken,
      onRefreshError: methods.resetToUnauthenticated,
    });

    methods.initialize();
  });

  if (!isInitialized) {
    return null;
  }

  const state: AuthState = {
    isAuthenticated: methods.getAuthenticatedState(),
    accessToken: methods.getTokenPair().access_token,
  };

  return (
    <AuthContextMethods.Provider value={methods as AuthMethods}>
      <AuthContextState.Provider value={state}>{children}</AuthContextState.Provider>
    </AuthContextMethods.Provider>
  );
};

// ----------------------------------------------------------------------

export const useAuthMethods = (): AuthMethods => {
  const context = useContext(AuthContextMethods);

  if (!context) {
    throw new Error('useAuthMethods must be used inside a AuthProvider.');
  }

  return context;
};

export const useAuthState = (): AuthState => {
  const context = useContext(AuthContextState);

  if (!context) {
    throw new Error('useAuthState must be used inside a AuthProvider.');
  }

  return context;
};
