import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import type { Theme } from '@mui/material/styles';

export default function Accordion(theme: Theme) {
  return {
    MuiAccordion: {
      styleOverrides: {
        root: {
          border: theme.shape.divider,
          backgroundColor: theme.palette.neutral['200'],
          overflow: 'hidden',

          '&&': {
            borderRadius: theme.shape.borderRadius,
          },

          '&:not(:first-child)': {
            margin: theme.spacing(1, 0, 0, 0),
          },

          '&:before': {
            display: 'none',
          },

          '&.Mui-expanded': {
            boxShadow: 'none',
            margin: 0,
          },

          '&:not(:first-child).Mui-expanded': {
            margin: theme.spacing(1, 0, 0, 0),
          },
        },
      },
    },
    MuiAccordionSummary: {
      defaultProps: {
        expandIcon: ExpandMoreIcon,
      },
      styleOverrides: {
        root: {
          borderBottom: theme.shape.divider,
          borderBottomColor: 'transparent',
          transition: `border-bottom-color ${theme.transitions.duration.shortest}ms`,

          '.MuiAccordionSummary-content, .MuiAccordionSummary-content.Mui-expanded': {
            margin: theme.spacing(1, 0),
            paddingRight: theme.spacing(1),
          },

          '&:before': {
            display: 'none',
          },
        },
        expandIconWrapper: {
          color: theme.palette.primary.main,
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: 0,
          margin: theme.spacing(0, 2, 1),

          '&:before': {
            display: 'none',
          },
        },
      },
    },
  };
}
