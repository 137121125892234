import { Fragment } from 'react';
import { Tab, Tabs } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ModalTabbarConfirmationCreateDialog, ModalTabbarConfirmationEditDialog } from './modal-tabbar-dialogs';
import { useDialogType, useShouldInterceptTabChange, useStoreActions } from './ModalTabbarProvider';

export type ModalTabProps<TValue extends string> = {
  disabled?: boolean;
  value?: TValue;
  label: React.ReactNode;
};

export type ModalTabbarProps<TValue extends string> = {
  tabs: Array<ModalTabProps<TValue>>;
  value?: TValue;
  onChange?: (value: TValue) => void;
};

const StyledRoot = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  borderBottom: theme.shape.divider,
  height: theme.dimensions.modal.tabbar.height,
}));

export const ModalTabbar = <TValue extends string>(props: ModalTabbarProps<TValue>) => {
  const { tabs, value: currentValue, onChange } = props;

  const shouldInterceptTabChange = useShouldInterceptTabChange();
  const actions = useStoreActions();

  const handleChange = (_: React.SyntheticEvent, value: TValue) => {
    if (shouldInterceptTabChange) {
      actions.setChangeTabHandler(() => onChange?.(value));
      actions.openDialog();

      return;
    }

    onChange?.(value);
  };

  const dialogType = useDialogType();

  return (
    <Fragment>
      <StyledRoot>
        <Tabs value={currentValue} textColor="secondary" indicatorColor="secondary" onChange={handleChange}>
          {tabs.map(({ disabled, value, label }, index) => (
            <Tab
              key={value ?? index}
              disabled={disabled}
              value={value}
              label={label}
              data-testid={`modal-tab-${value}`}
            />
          ))}
        </Tabs>
      </StyledRoot>

      {dialogType === 'create' && <ModalTabbarConfirmationCreateDialog />}
      {dialogType === 'edit' && <ModalTabbarConfirmationEditDialog />}
    </Fragment>
  );
};
