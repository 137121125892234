import { CreateButton } from '@/components/buttons';
import { useMakersStoreActions, useMakersStoreIsContextWindowsVisible } from '@/components/makers/useMakersStore';
import { useShortcut } from '@/hooks/useShortcut';

// ----------------------------------------------------------------------

export const MakersCreateButton = () => {
  const { openCreateModal } = useMakersStoreActions();
  const isContextWindowsVisible = useMakersStoreIsContextWindowsVisible();

  useShortcut(
    'ctrl + n',
    () => {
      if (!isContextWindowsVisible) {
        openCreateModal();
      }
    },
    [isContextWindowsVisible],
  );

  return <CreateButton onClick={openCreateModal} />;
};
