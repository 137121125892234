import { InputAdornment, InputAdornmentProps } from '@mui/material';
import { CircularProgress } from '@mui/material';

type CircularProgressAdornmentProps = Omit<InputAdornmentProps, 'children'>;

export const CircularProgressAdornment = (props: CircularProgressAdornmentProps) => (
  <InputAdornment {...props}>
    <CircularProgress color="inherit" size={24} />
  </InputAdornment>
);
