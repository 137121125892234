import { lazy } from 'react';

export const ContractDrugAnalyticsPage = lazy(
  () =>
    import(
      /* webpackPreload: true */
      /* webpackChunkName: "ContractDrugAnalyticsPage" */
      './ContractDrugAnalyticsPage'
    ),
);
