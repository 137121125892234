import type { Auth } from '@/api/domains/auth.types';
import type { Nullable } from '@/@types/app';

// ----------------------------------------------------------------------

export type TokenPair = Pick<Auth, 'access_token' | 'refresh_token'>;

export type TokenPairStorageItem = Nullable<TokenPair>;

// ----------------------------------------------------------------------

const STORAGE_KEY_TOKENS = 'dorim-base:tokens';

// ----------------------------------------------------------------------

export const tokensStorage = {
  INITIAL_TOKEN_PAIR: {
    access_token: null,
    refresh_token: null,
  } as TokenPairStorageItem,

  // ----------------------------------------------------------------------

  setTokenPair: (tokenPair: TokenPair): void => {
    localStorage.setItem(STORAGE_KEY_TOKENS, JSON.stringify(tokenPair));
  },

  // ----------------------------------------------------------------------

  getTokenPair: (): TokenPairStorageItem => {
    const tokenPair = localStorage.getItem(STORAGE_KEY_TOKENS);

    if (!tokenPair) {
      return tokensStorage.INITIAL_TOKEN_PAIR;
    }

    try {
      return JSON.parse(tokenPair) as TokenPairStorageItem;
    } catch {
      return tokensStorage.INITIAL_TOKEN_PAIR;
    }
  },

  // ----------------------------------------------------------------------

  removeTokenPair: () => {
    localStorage.removeItem(STORAGE_KEY_TOKENS);
  },
};
