import { useState } from 'react';
import { useDebounce } from 'react-use';
import { Autocomplete, AutocompleteProps, Box, Typography } from '@mui/material';
import { Drug } from '@/api/domains/drugs.types';
import { formatDrugName, highlightKeyword } from '@/helpers/format.helpers';
import { useDrugs } from '@/hooks/useDrugs';
import { isNull } from 'lodash';

// ----------------------------------------------------------------------

export type DrugFieldProps = Omit<
  AutocompleteProps<Drug, false, false, false>,
  'freeSolo' | 'autoComplete' | 'includeInputInList' | 'filterSelectedOptions' | 'loading' | 'noOptionsText' | 'options'
> & {
  forSpecificationTemplate?: boolean;
  marketingOrgIds?: number[] | null;
};

// ----------------------------------------------------------------------

const getDrugFullInfo = (drug: Drug) => {
  const { name, form, number, maker } = drug;

  const drugInfo = formatDrugName(name, form, number);
  const makerInfo = maker ? `, ${maker.name} (${maker.country_name})` : '';

  return drugInfo.concat(makerInfo);
};

// ----------------------------------------------------------------------

export const DrugField = (props: DrugFieldProps) => {
  const { forSpecificationTemplate, marketingOrgIds, ...restOfProps } = props;
  const [inputValue, setInputValue] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  useDebounce(() => setSearchQuery(inputValue), 500, [inputValue]);

  const { isInitialLoading, drugs } = useDrugs(
    {
      for_specification_template: forSpecificationTemplate,
      marketing_org_ids: marketingOrgIds ?? undefined,
      query: searchQuery,
      limit: 500,
    },
    {
      enabled: searchQuery.length >= 2 && !isNull(marketingOrgIds),
    },
  );

  const handleInputChange = (_event: React.SyntheticEvent, inputValue: string) => {
    setInputValue(inputValue);
  };

  const handleInputPaste: React.ClipboardEventHandler<HTMLInputElement> = event => {
    event.preventDefault();

    const clipboardData = event.clipboardData.getData('text');

    setInputValue(clipboardData.trim());
  };

  return (
    <Autocomplete
      {...restOfProps}
      inputValue={inputValue}
      autoComplete
      includeInputInList
      loading={isInitialLoading}
      options={drugs}
      filterOptions={option => option}
      getOptionLabel={option => (typeof option === 'string' ? option : getDrugFullInfo(option))}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onInputChange={handleInputChange}
      onPaste={handleInputPaste}
      renderOption={(optionProps, option) => (
        <Box {...optionProps} component="li" sx={{ display: 'block !important' }}>
          <Typography component="div" variant="body2">
            {highlightKeyword(formatDrugName(option.name, option.form, option.number), searchQuery)}
          </Typography>
          {option.maker && (
            <Typography component="div" variant="body2" color="text.secondary">
              {`${option.maker?.name} (${option.maker?.country_name})`}
            </Typography>
          )}
        </Box>
      )}
    />
  );
};
