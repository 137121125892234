import { KeyboardEvent, useRef } from 'react';
import { useBoolean } from 'react-use';
import { ProfileMenuPopover } from './ProfileMenuPopover/ProfileMenuPopover';
import { Popover, Stack } from '@mui/material';

import { useProfile } from '../../queries/useProfile';
import { ProfileLinks } from '@/api/domains/profile.types';
import { Avatar } from '../Avatar';
import { ProfileMenuSkeleton } from './ProfileMenuSkeleton';
import { StyledProfileMenu, ProfileMenuSxStyles } from './styles';

type Props = ProfileLinks;

export const ProfileMenu = (props: Props) => {
  const { user, fullName, isLoading } = useProfile();
  const [isOpenPopover, triggerOpenPopover] = useBoolean(false);
  const anchorEl = useRef(null);

  const handleAvatarKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.code === 'Enter') {
      triggerOpenPopover();
    }
  };

  if (isLoading) {
    return <ProfileMenuSkeleton />;
  }

  return (
    <>
      <StyledProfileMenu
        direction="row"
        onClick={triggerOpenPopover}
        onKeyDown={handleAvatarKeyDown}
        ref={anchorEl}
        role="button"
        tabIndex={1}
      >
        <Avatar fullName={fullName} imgLink={user?.avatar_url} size={32} isAura={isOpenPopover} />

        <Stack sx={ProfileMenuSxStyles.userName}>
          <Stack>{user?.first_name}</Stack>
          <Stack>{user?.last_name}</Stack>
        </Stack>
      </StyledProfileMenu>

      <Popover
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        anchorEl={anchorEl.current}
        onClose={triggerOpenPopover}
        open={isOpenPopover}
      >
        <ProfileMenuPopover {...props} />
      </Popover>
    </>
  );
};
