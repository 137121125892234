import { DialogContent, type DialogContentProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledRoot = styled(DialogContent)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(3),
})) as typeof DialogContent;

export type ModalBodyContentProps = DialogContentProps;

export const ModalBodyContent = (props: ModalBodyContentProps) => <StyledRoot {...props} />;
