import React from 'react';

import {
  ConfirmationDialogActionCancelButton,
  ConfirmationDialogActionProceedButton,
  ConfirmationDialogActions,
  ConfirmationDialogBodyMutedText,
  ConfirmationDialogContainer,
  ConfirmationDialogContainerProps,
  ConfirmationDialogHeader,
} from '@/components/dialogs/elements.confirmation-dialog';

// ----------------------------------------------------------------------

type Props = Omit<ConfirmationDialogContainerProps, 'subject'> & {
  isLoading: boolean;
  onProceed: VoidFunction;
  headerText?: string;
  bodyText?: React.ReactNode;
  proceedButtonText?: string;
};

// ----------------------------------------------------------------------

export const ConfirmationWarningDialog = (props: Props) => {
  const {
    isLoading,
    onClose,
    onProceed,
    headerText = 'Есть несохранённые данные, сбросить?',
    bodyText = 'Вы уверены, что хотите сбросить несохранённые данные и покинуть страницу?',
    proceedButtonText = 'Сбросить',
    ...restOfDialogProps
  } = props;

  return (
    <ConfirmationDialogContainer subject="warning" onClose={onClose} {...restOfDialogProps}>
      <ConfirmationDialogHeader>{headerText}</ConfirmationDialogHeader>

      <ConfirmationDialogBodyMutedText>{bodyText}</ConfirmationDialogBodyMutedText>

      <ConfirmationDialogActions>
        <ConfirmationDialogActionCancelButton onClick={onClose} />
        <ConfirmationDialogActionProceedButton autoFocus loading={isLoading} color="error" onClick={onProceed}>
          {proceedButtonText}
        </ConfirmationDialogActionProceedButton>
      </ConfirmationDialogActions>
    </ConfirmationDialogContainer>
  );
};
