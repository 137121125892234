import { FilterAltOutlined as FilterAltOutlinedIcon } from '@mui/icons-material';
import { ToggleButton, ToggleButtonProps } from '@/components/buttons/ToggleButton';

// ----------------------------------------------------------------------

export const FilterButton = (props: ToggleButtonProps) => (
  <ToggleButton {...props} startIcon={<FilterAltOutlinedIcon fontSize="medium" />}>
    Фильтры
  </ToggleButton>
);
