import { useRef } from 'react';
import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
  Done as DoneIcon,
} from '@mui/icons-material';
import { Box, ListItemIcon, ListItemText, Menu, MenuItem, Typography, type MenuProps } from '@mui/material';
import { usePopupState, bindTrigger, bindMenu } from 'material-ui-popup-state/hooks';

import { Label, LabelColor } from '@/components/labels';
import { ActionButton } from '../buttons';

type StatusSelectProps<T extends number> = {
  selectedRecordStatusId: T;
  onSelectStatus: (recordStatusId: T) => void;
  statuses: T[];
  getLabelColor?: (recordStatusId: T) => LabelColor;
  getLabelName: (recordStatusId: T) => string;
  isChangeStatusDisabled?: boolean;
  isButtonActivator?: boolean;
};

export const StatusSelect = <T extends number>(props: StatusSelectProps<T>) => {
  const {
    selectedRecordStatusId,
    onSelectStatus,
    statuses,
    getLabelColor,
    getLabelName,
    isChangeStatusDisabled,
    isButtonActivator = false,
  } = props;

  const popupState = usePopupState({ variant: 'popover', popupId: 'status-select' });
  const { isOpen: isMenuOpen, close: closeMenu } = popupState;

  const activatorRef = useRef(null);

  const statusName = getLabelName(selectedRecordStatusId);
  const color = getLabelColor?.(selectedRecordStatusId);

  const handleItemClick = (recordStatusId: T) => {
    onSelectStatus(recordStatusId);

    closeMenu();
  };

  const isDisabled = isChangeStatusDisabled || statuses.length < 2;
  const endIcon = !isDisabled && (isMenuOpen ? <ArrowDropUpIcon width={16} /> : <ArrowDropDownIcon width={16} />);
  const menuProps: MenuProps = isDisabled ? { open: false } : bindMenu(popupState);

  return (
    <>
      {isButtonActivator ? (
        <ActionButton
          ref={activatorRef}
          endIcon={endIcon}
          sx={{ cursor: isDisabled ? 'default' : 'pointer' }}
          {...bindTrigger(popupState)}
        >
          {statusName}
        </ActionButton>
      ) : (
        <Label
          color={color}
          ref={activatorRef}
          endIcon={endIcon}
          sx={{ cursor: isDisabled ? 'default' : 'pointer' }}
          {...bindTrigger(popupState)}
        >
          {statusName}
        </Label>
      )}

      <Menu anchorEl={activatorRef.current} {...menuProps}>
        {statuses.map(status => {
          const isActive = status === selectedRecordStatusId;

          return (
            <MenuItem onClick={() => handleItemClick(status)} key={status} sx={isActive ? { cursor: 'default' } : {}}>
              <ListItemText sx={{ paddingRight: '24px' }}>
                {isButtonActivator ? (
                  <Box py={0.5}>
                    <Typography noWrap component="p" variant="Body/medium">
                      {getLabelName(status)}
                    </Typography>
                  </Box>
                ) : (
                  <Label color={getLabelColor?.(status)} sx={{ pointerEvents: 'none' }}>
                    {getLabelName(status)}
                  </Label>
                )}
              </ListItemText>
              <ListItemIcon sx={{ margin: 0, minWidth: '16px !important', color: 'custom.blue' }}>
                {isActive && <DoneIcon sx={{ fontSize: 'medium' }} />}
              </ListItemIcon>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};
