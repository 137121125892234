import type { ComponentProps } from 'react';
import { alpha, styled } from '@mui/material/styles';

type StyledProps = {
  isSelected?: boolean;
  isExpanded?: boolean;
};

export type TreeViewItemContentProps = Omit<ComponentProps<'div'>, 'className'> & StyledProps;

const StyledRoot = styled('div', {
  shouldForwardProp: propName => propName !== 'isSelected' && propName !== 'isExpanded',
})<StyledProps>(
  ({ theme }) => ({
    display: 'flex',
    minHeight: theme.spacing(6),
    paddingLeft: theme.spacing(5),
    marginLeft: `${theme.spacing(-2)} !important`,
    borderRadius: theme.shape.borderRadius,
    border: '1px solid transparent',
  }),
  ({ isExpanded, theme }) =>
    isExpanded && {
      backgroundColor: alpha(theme.palette.secondary.main, 0.08),
    },
  ({ isSelected, theme }) =>
    isSelected && {
      borderColor: theme.palette.secondary.main,
      backgroundColor: alpha(theme.palette.secondary.main, 0.16),
    },
);

export const TreeViewItemContent = (props: TreeViewItemContentProps) => (
  <StyledRoot {...props} className="TreeViewItem-content" />
);
