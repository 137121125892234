import { Card, CardProps, Stack, StackProps } from '@mui/material';
import { styled } from '@mui/material/styles';

// ----------------------------------------------------------------------

export type DataGridActionbarProps = Omit<StackProps, 'direction'> & CardProps;

// ----------------------------------------------------------------------

const StyledRoot = styled(Card)(({ theme }) => ({
  display: 'inline-flex',
  padding: 2,
  borderRadius: theme.shape.borderRadius,
})) as typeof Card;

// ----------------------------------------------------------------------

export const DataGridActionbar = (props: DataGridActionbarProps) => (
  <StyledRoot
    component={Stack}
    variant="elevation"
    elevation={3}
    direction="row"
    spacing="2px"
    className="DataGrid-actionbar"
    {...props}
  />
);
