import {
  PromoContractsSelectableStatusesIds,
  PromoContractStatusId,
} from '@/constants/promo-contract-status.constants';
import { RECORD_STATUS_UNKNOWN } from '@/constants/record-status.constants';
import { Label } from '@/components/labels';
import { PromoContractStatusSelect } from './PromoContractsStatusSelect';
import { PromoContractDataGridRowData } from '../../data-grids/types';
import { getPromoContractRecordStatus } from '../../helpers/status-select.helpers';
import { usePermissions } from '@/hooks/useCheckPermissions';

type StatusLabelProps = {
  statusId: PromoContractStatusId;
  rowData: PromoContractDataGridRowData;
};

export const StatusLabel = (props: StatusLabelProps) => {
  const { statusId, rowData } = props;
  const { name, color } = getPromoContractRecordStatus(statusId);
  const { isChangeStatusesAllowed } = usePermissions();

  const isSelectableStatus =
    PromoContractsSelectableStatusesIds.includes(statusId) && isChangeStatusesAllowed('agreements');

  return isSelectableStatus ? (
    <PromoContractStatusSelect promoContractDataGridRowData={rowData} />
  ) : (
    <Label color={color}>{name || RECORD_STATUS_UNKNOWN}</Label>
  );
};
