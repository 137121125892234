import { pick } from 'lodash';
import { httpClient } from '@/libs/axios/http-client';

import type { PaginatedList, PaginationParams } from '../@types/pagination.types';
import type { EimzoDocument } from './e-imzo-documents.types';

export type SignDocumentParams = {
  documentUuid: string;
  pkcs7: string;
};

export type FetchEimzoDocumentParams = {
  document_uuid: string;
};

export const EimzoDocumentsApi = {
  fetchDocument: async ({ document_uuid }: FetchEimzoDocumentParams) => {
    const { data } = await httpClient.get<EimzoDocument>(`document/${document_uuid}`);

    return data;
  },

  fetchDocuments: async (params?: PaginationParams) => {
    const { data } = await httpClient.get<PaginatedList<'items', EimzoDocument>>('document', { params });

    return data;
  },

  signDocument: (params: SignDocumentParams) =>
    httpClient.post<void>(`document/${params.documentUuid}/sign`, pick(params, ['pkcs7'])),
};
