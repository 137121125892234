import { useState } from 'react';
import { TextField, type TextFieldProps } from '@mui/material';

import { EndAdornment } from './components/EndAdornment';
import { StartAdornment } from './components/StartAdornment';
import type { SearchFieldProps } from './types';

export const SearchField = (props: SearchFieldProps) => {
  const { value, size, onClear, isVisibleLabel = true, ...restOfProps } = props;
  const hasInputValue = value.length > 0;

  const [isFocused, setIsFocused] = useState(false);

  const handleFocus: TextFieldProps['onFocus'] = event => {
    setIsFocused(true);

    if (restOfProps.onFocus) {
      restOfProps.onFocus(event);
    }
  };

  const handleBlur: TextFieldProps['onBlur'] = event => {
    setIsFocused(false);

    if (restOfProps.onBlur) {
      restOfProps.onBlur(event);
    }
  };

  return (
    <TextField
      variant="filled"
      autoComplete="off"
      value={value}
      size={size}
      {...restOfProps}
      onFocus={handleFocus}
      onBlur={handleBlur}
      InputProps={{
        ...restOfProps.InputProps,
        startAdornment: <StartAdornment size={size} />,
        endAdornment: (
          <EndAdornment
            size={size}
            isVisibleClearButton={hasInputValue}
            isVisibleLabel={isVisibleLabel && (!value || !isFocused)}
            onClear={onClear}
          />
        ),
      }}
    />
  );
};
