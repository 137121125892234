import { PaginatedList, PaginationParams } from '@/api/@types/pagination.types';
import { Maker } from '@/api/domains/makers.types';
import { httpClient } from '@/libs/axios/http-client';

// ----------------------------------------------------------------------

export type MakersParams = PaginationParams & {
  query?: string;
};

export type MakerFormData = {
  name: string;
  country_id: string;
  country_name: string;
  description?: string;
  logo_url_token?: string;
};

export type MakerDeleteParams = {
  ids: Array<number>;
};

type DeduplicateMakerParams = {
  main_maker_id: number;
  slave_maker_ids: Array<number>;
};
// ----------------------------------------------------------------------

export const MakersApi = {
  fetchMakers: async (params?: MakersParams) => {
    const { data } = await httpClient.get<PaginatedList<'items', Maker>>('makers', { params });

    return data;
  },

  // ----------------------------------------------------------------------

  fetchMaker: async (makerId: number) => {
    const { data } = await httpClient.get<Maker>(`makers/${makerId}`);

    return data;
  },

  // ----------------------------------------------------------------------

  createMaker: async (formData: MakerFormData) => {
    const { data } = await httpClient.post<Maker>('makers', formData);

    return data;
  },

  // ----------------------------------------------------------------------

  updateMaker: async (makerId: number, formData: MakerFormData) => {
    const { data } = await httpClient.put<Maker>(`makers/${makerId}`, formData);

    return data;
  },

  // ----------------------------------------------------------------------

  deleteMaker: (params: MakerDeleteParams) => httpClient.delete<void>('makers', { data: params }),

  // ----------------------------------------------------------------------

  deduplicateMaker: (params: DeduplicateMakerParams) => httpClient.post<void>('makers/deduplicate', params),
};
