import { Drug } from '@/api/domains/drugs.types';
import { CustomTypography } from '@/components/CustomTypography';
import { Box, Stack, Typography } from '@mui/material';

type DialogDrugCardProps = {
  drug: Drug;
};

export const DialogDrugCard = (props: DialogDrugCardProps) => {
  const { drug } = props;

  if (!drug) {
    return null;
  }

  const { id, full_name, maker } = drug;

  return (
    <Stack spacing={1}>
      <Stack direction="row" spacing={2} justifyContent="space-between">
        <CustomTypography noWrap textAlign="left" variant="subtitle2">
          {full_name}
        </CustomTypography>
        <Typography variant="body2">{id}</Typography>
      </Stack>

      {maker && (
        <Box textAlign="left">
          <Typography variant="body2">{maker.name}</Typography>
          <Typography component="p" variant="caption" color="text.secondary">
            {maker.country_name}
          </Typography>
        </Box>
      )}
    </Stack>
  );
};
