import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useNetworkState } from 'react-use';

export const useOfflineSnackbar = () => {
  const { online } = useNetworkState();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    const ENQUEUE_KEY = 'no-internet';
    if (!online) {
      enqueueSnackbar('Нет интернета', { variant: 'error', key: ENQUEUE_KEY, autoHideDuration: null });
    } else {
      closeSnackbar(ENQUEUE_KEY);
    }
  }, [online]);
};
