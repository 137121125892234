import {
  MakersCreateModal,
  MakersDeleteDialog,
  MakersDuplicateDialog,
  MakersDuplicateModal,
  MakersEditModal,
  MakersHeader,
  MakersMainToolbar,
} from '@/components/makers';
import { PageContainer, PageContent } from '@/layouts/Page';
import { MakersPageDataGrid } from '../MakersPageDataGrid';
import { DataGridContainer } from '@/components/DataGrid';

const MakersCatalogPage = () => {
  return (
    <>
      <PageContainer title="Производители">
        <MakersHeader />

        <PageContent>
          <DataGridContainer>
            <MakersMainToolbar />
            <MakersPageDataGrid />
          </DataGridContainer>
        </PageContent>
      </PageContainer>

      <MakersCreateModal />
      <MakersDuplicateModal />
      <MakersEditModal />

      <MakersDeleteDialog />
      <MakersDuplicateDialog />
    </>
  );
};

export default MakersCatalogPage;
