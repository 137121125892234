import { SearchAutocompleteField } from '@/components/form-controls/SearchField';
import { SearchAutocompleteFieldProps } from '@/components/form-controls/SearchField/types';

const styleSearchAutocompleteField = {
  width: '100%',
  maxWidth: 540,
};

export const HeaderSearchInput = (props: SearchAutocompleteFieldProps) => (
  <SearchAutocompleteField sx={styleSearchAutocompleteField} {...props} />
);
