import { ComponentProps, ElementType } from 'react';
import { LoadingButtonProps } from '@mui/lab';
import { styled } from '@mui/material/styles';
import {
  type ButtonProps,
  Card,
  type CardProps,
  Dialog,
  DialogContent,
  type DialogProps,
  IconButton,
  Stack,
  type StackProps,
  Typography,
  type TypographyProps,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { ReactComponent as CreateSVG } from '@/assets/icons/dialog/create.svg';
import { ReactComponent as DeleteSVG } from '@/assets/icons/dialog/delete.svg';
import { ReactComponent as EditSVG } from '@/assets/icons/dialog/edit.svg';
import { ReactComponent as ErrorSVG } from '@/assets/icons/dialog/error.svg';
import { ReactComponent as PublishSVG } from '@/assets/icons/dialog/publish.svg';
import { ReactComponent as WarningSVG } from '@/assets/icons/dialog/warning.svg';
import { RoundedButton } from '../buttons';

// ----------------------------------------------------------------------

type Subject = 'warning' | 'create' | 'edit' | 'delete' | 'duplicate' | 'publish' | 'error';

export type ConfirmationDialogContainerProps = Omit<DialogProps, 'onClose'> & {
  subject: Subject;
  onClose?: VoidFunction;
};

export type ConfirmationDialogDoubleContainerProps = Omit<DialogProps, 'onClose'> & {
  onClose?: VoidFunction;
};

// ----------------------------------------------------------------------

const iconsBySubject: Record<Subject, ElementType<ComponentProps<'svg'>>> = {
  warning: WarningSVG,
  duplicate: WarningSVG,
  create: CreateSVG,
  edit: EditSVG,
  delete: DeleteSVG,
  publish: PublishSVG,
  error: ErrorSVG,
};

// ----------------------------------------------------------------------

const StyledCloseButtonContainer = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
}));

export const ConfirmationDialogContainer = (props: ConfirmationDialogContainerProps) => {
  const { subject = 'warning', children, onClose, ...restOfDialogProps } = props;
  const IconSVG = iconsBySubject[subject];

  return (
    <Dialog fullWidth maxWidth="xs" onClose={onClose} {...restOfDialogProps}>
      <DialogContent sx={{ p: 3 }}>
        <StyledCloseButtonContainer>
          <IconButton onClick={onClose}>
            <CloseIcon fontSize="medium" />
          </IconButton>
        </StyledCloseButtonContainer>

        <Stack spacing={3} alignItems="center" textAlign="center">
          <IconSVG height={80} width={80} />
          {children}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

// ----------------------------------------------------------------------

export const ConfirmationDialogHeader = (props: TypographyProps) => <Typography variant="h4" {...props} />;

// ----------------------------------------------------------------------

export const ConfirmationDialogBodyCard = (props: CardProps & StackProps) => {
  const { sx, ...restOfProps } = props;

  return (
    <Card
      component={Stack}
      spacing={2}
      sx={{
        width: '100%',
        textAlign: 'left',
        padding: theme => theme.spacing(2),
        backgroundColor: theme => theme.palette.background.neutral,
        ...sx,
      }}
      {...restOfProps}
    />
  );
};

// ----------------------------------------------------------------------

export const ConfirmationDialogBodyText = (props: TypographyProps) => <Typography variant="body1" {...props} />;

// ----------------------------------------------------------------------

export const ConfirmationDialogBodyMutedText = (props: TypographyProps) => (
  <Typography component="div" variant="body2" color="text.secondary" {...props} />
);

// ----------------------------------------------------------------------

export const ConfirmationDialogActions = (props: StackProps) => (
  <Stack direction="row" width="100%" spacing={1.5} pt={1.5} {...props} />
);

// ----------------------------------------------------------------------

export const ConfirmationDialogActionCancelButton = (props: ButtonProps) => {
  const { children = 'Отмена' } = props;

  return (
    <RoundedButton fullWidth variant="outlined" color="inherit" {...props}>
      {children}
    </RoundedButton>
  );
};

// ----------------------------------------------------------------------

export const ConfirmationDialogActionProceedButton = (props: LoadingButtonProps) => {
  const { color = 'secondary', fullWidth = true, children = 'Применить' } = props;

  return (
    <RoundedButton variant="contained" color={color} fullWidth={fullWidth} {...props}>
      {children}
    </RoundedButton>
  );
};
