import { Grow, Paper, Popper, type PopperProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Scrollbar } from '@/components/Scrollbar';

export type ClassificationPopperProps = Omit<PopperProps, 'children'> & {
  children: Exclude<PopperProps['children'], Function>;
};

const StyledRoot = styled(Popper)(({ theme }) => ({
  width: '100%',
  maxWidth: 660,
  zIndex: theme.zIndex.modal + 1,

  '.MuiPaper-root': {
    width: '100%',
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
  },

  '.ClassificationPopper-scrollable': {
    maxHeight: '40vh',
  },
}));

const StyledGrow = styled(Grow)({
  transformOrigin: '0 0 0',
}) as typeof Grow;

export const ClassificationPopper = (props: ClassificationPopperProps) => {
  const { children, anchorEl } = props;

  return (
    <StyledRoot transition placement="bottom-start" anchorEl={anchorEl} {...props}>
      {({ TransitionProps }) => (
        <StyledGrow {...TransitionProps} timeout={0}>
          <Paper elevation={8}>
            <Scrollbar className="ClassificationPopper-scrollable">{children}</Scrollbar>
          </Paper>
        </StyledGrow>
      )}
    </StyledRoot>
  );
};
