import { generatePath, joinPath, ExtractPathParams } from '@/libs/react-router';

const ROOT_PATH = '/contractors';
const SEARCH_PATH = joinPath(ROOT_PATH, ':contractorId');

export type SearchPathParams = ExtractPathParams<typeof SEARCH_PATH>;

export const ContractorsPaths = {
  ROOT_PATH,
  SEARCH_PATH,

  generateSearchContractorPath: <TParams extends SearchPathParams>(params: TParams) =>
    generatePath(SEARCH_PATH, params),
} as const;
