import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Scrollbar } from '@/components/Scrollbar';
import { SidebarLogo } from '@/layouts/Sidebar/SidebarLogo';

// ----------------------------------------------------------------------

export type SidebarBaseProps = React.PropsWithChildren<{}>;

// ----------------------------------------------------------------------

const StyledRoot = styled(Stack)(({ theme }) => ({
  alignSelf: 'stretch',
  width: 88,
  flexShrink: 0,
  borderRight: '1px solid #E6EBF0',

  '.SidebarContentContainer': {
    flexGrow: 1,
    width: '100%',
    position: 'relative',
  },

  '.SidebarInnerContainer': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    padding: theme.spacing(2, 0.5),
  },

  '.SidebarSignOutButtonContainer': {
    padding: theme.spacing(0.5),
  },
})) as typeof Stack;

// ----------------------------------------------------------------------

export const SidebarBase = (props: SidebarBaseProps) => {
  const { children } = props;

  return (
    <StyledRoot component="aside">
      <SidebarLogo />

      <div className="SidebarContentContainer">
        <Scrollbar className="SidebarInnerContainer">
          <Stack spacing={2}>{children}</Stack>
        </Scrollbar>
      </div>
    </StyledRoot>
  );
};
