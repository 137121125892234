import { useRef } from 'react';
import { useShortcut } from '@/hooks/useShortcut2';

// ----------------------------------------------------------------------

type SearchInputPredicate = () => boolean;

export const useSearchInputShortcuts = (predicate: SearchInputPredicate = () => true) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useShortcut({
    combination: ['ctrl+f', 'f3'],
    onPressed: () => {
      const shouldFocusOnInput = predicate;

      if (!shouldFocusOnInput) {
        return;
      }

      const inputElement = inputRef.current;

      if (inputElement) {
        inputElement.focus();

        const { length } = inputElement.value;

        if (length > 0) {
          inputElement.setSelectionRange(0, length);
        }
      }
    },
  });

  return inputRef;
};
