import { Link, useParams } from 'react-router-dom';
import { Tab, Tabs } from '@mui/material';

import { useMatchLinks } from '@/hooks/useMatchLinks';
import { HeaderTabs } from '@/layouts/Header';
import { RegistersPaths, RegistryImportLogBasePathParams } from '@/router';

export const RegistryImportLogTabs = () => {
  const { registryId } = useParams<RegistryImportLogBasePathParams>();

  const matchedLink = useMatchLinks(RegistersPaths.IMPORT_LOG_BASE_INFO_PATH, RegistersPaths.IMPORT_LOG_ERRORS_PATH);

  if (!matchedLink || !registryId) return null;

  return (
    <HeaderTabs>
      <Tabs value={matchedLink.pathname} textColor="secondary" indicatorColor="secondary">
        <Tab
          component={Link}
          to={RegistersPaths.generateImportLogBasePath({ registryId })}
          value={RegistersPaths.IMPORT_LOG_BASE_INFO_PATH}
          label="Основная информация"
        />
        <Tab
          component={Link}
          to={RegistersPaths.generateImportLogErrorPath({ registryId })}
          value={RegistersPaths.IMPORT_LOG_ERRORS_PATH}
          label="Строки с ошибками"
        />
      </Tabs>
    </HeaderTabs>
  );
};
