import { Outlet } from 'react-router';
import { Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import backgroundSRC from '@/assets/image/auth/background.jpg';

const StyledContainer = styled('div')({
  width: '100%',
  height: '100%',
  position: 'relative',
  background: `url(${backgroundSRC}) no-repeat center/cover`,
});

const StyledInnerContainer = styled('div')({
  width: '100%',
  position: 'absolute',
  top: '50%',
  left: 0,
  transform: 'translateY(-50%)',
});

export const AuthLayout = () => (
  <StyledContainer>
    <StyledInnerContainer>
      <Container maxWidth="sm">
        <Outlet />
      </Container>
    </StyledInnerContainer>
  </StyledContainer>
);
