import { Stack, Typography } from '@mui/material';
import { DataGridActionbar } from '@/components/DataGrid';
import {
  MakersInlineDeleteButton,
  MakersInlineDuplicateButton,
  MakersInlineEditButton,
} from '@/components/makers/actions';

// ----------------------------------------------------------------------

export type MakersInlineToolbarProps = React.PropsWithChildren<{
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}>;

// ----------------------------------------------------------------------

export const MakersInlineToolbar = (props: MakersInlineToolbarProps) => {
  const { children, onClick } = props;

  return (
    <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
      <Typography noWrap component="p" variant="body2" fontWeight={500}>
        {children}
      </Typography>
      <DataGridActionbar>
        <MakersInlineDuplicateButton onClick={onClick} />
        <MakersInlineEditButton onClick={onClick} />
        <MakersInlineDeleteButton onClick={onClick} />
      </DataGridActionbar>
    </Stack>
  );
};
