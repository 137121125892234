import { styled } from '@mui/material/styles';
import { Stack, StackProps } from '@mui/material';

type Header = StackProps & {
  hasProfile?: boolean;
};

export const HeaderStyled = styled(Stack, { shouldForwardProp: prop => prop !== 'hasProfile' })<Header>(
  ({ theme, hasProfile }) => ({
    position: 'sticky',
    top: 0,
    zIndex: theme.zIndex.appBar,
    width: '100%',
    flexShrink: 0,
    alignItems: 'center',
    height: theme.spacing(7),
    padding: theme.spacing(0, 2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
    justifyContent: hasProfile ? 'space-between' : 'inherit',
  }),
);

export const HeaderActionStyled = styled('div')(({ theme }) => ({
  display: 'inline-block',
  marginLeft: theme.spacing(2),
}));
