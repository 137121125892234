import type { Assign } from 'utility-types';
import { Maker } from '@/api/domains/makers.types';
import { DataGridActionDeduplicate } from '../../DataGrid/data-grid.actions';
import { ActionButtonProps } from '@/components/buttons';
import { MakersPaths } from '@/router';
import { useNavigate } from 'react-router';

type MakersDeduplicationButtonProps = Omit<
  Assign<
    ActionButtonProps,
    {
      selectedRow: Maker | null;
    }
  >,
  'onClick'
>;

export const MakersDeduplicateButton = (props: MakersDeduplicationButtonProps) => {
  const { selectedRow, ...restOfProps } = props;

  const navigate = useNavigate();

  const handleClick = () => {
    if (!selectedRow?.id) {
      return;
    }

    navigate(MakersPaths.generateDeduplicationPath({ makerId: String(selectedRow.id) } as const), {
      state: {
        maker: selectedRow,
      },
    });
  };

  return <DataGridActionDeduplicate onClick={handleClick} {...restOfProps} />;
};
