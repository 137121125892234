import type { OverridableStringUnion } from '@mui/types';
import type { TypographyPropsVariantOverrides } from '@mui/material';

declare module '@mui/material/Typography' {
  type UiKitTypographyVariant = {
    [Variant in keyof typeof UiKitTypography]: true;
  };

  interface TypographyPropsVariantOverrides extends UiKitTypographyVariant {}
}

declare module '@mui/material/styles/createTypography' {
  interface FontStyle {
    fontFamilyMono: React.CSSProperties['fontFamily'];
  }

  interface Typography
    extends Record<OverridableStringUnion<Variant | 'inherit', TypographyPropsVariantOverrides>, TypographyStyle>,
      FontStyle,
      TypographyUtils {}
}

export function pxToRem(value: number) {
  return `${value / 16}rem`;
}

const FONT_PRIMARY = '"Inter Tight", sans-serif';
const FONT_MONO = '"Roboto Mono", monospace';

// UI kit Typography variants
const UiKitTypography = {
  'Display/large': {
    fontFamily: FONT_PRIMARY,
    fontSize: pxToRem(57),
    fontWeight: 400,
    lineHeight: 64 / 57,
  },
  'Display/medium': {
    fontFamily: FONT_PRIMARY,
    fontSize: pxToRem(45),
    fontWeight: 400,
    lineHeight: 52 / 45,
  },
  'Display/small': {
    fontFamily: FONT_PRIMARY,
    fontSize: pxToRem(36),
    fontWeight: 400,
    lineHeight: 44 / 36,
  },

  'Display/Bold/large': {
    fontFamily: FONT_PRIMARY,
    fontSize: pxToRem(57),
    fontWeight: 700,
    lineHeight: 64 / 57,
  },
  'Display/Bold/medium': {
    fontFamily: FONT_PRIMARY,
    fontSize: pxToRem(45),
    fontWeight: 700,
    lineHeight: 52 / 45,
  },
  'Display/Bold/small': {
    fontFamily: FONT_PRIMARY,
    fontSize: pxToRem(36),
    fontWeight: 700,
    lineHeight: 44 / 36,
  },

  'Headline/large': {
    fontFamily: FONT_PRIMARY,
    fontSize: pxToRem(32),
    fontWeight: 400,
    lineHeight: 40 / 32,
  },
  'Headline/medium': {
    fontFamily: FONT_PRIMARY,
    fontSize: pxToRem(28),
    fontWeight: 400,
    lineHeight: 36 / 28,
  },
  'Headline/small': {
    fontFamily: FONT_PRIMARY,
    fontSize: pxToRem(24),
    fontWeight: 400,
    lineHeight: 32 / 24,
  },

  'Headline/Bold/large': {
    fontFamily: FONT_PRIMARY,
    fontSize: pxToRem(32),
    fontWeight: 700,
    lineHeight: 40 / 32,
  },
  'Headline/Bold/medium': {
    fontFamily: FONT_PRIMARY,
    fontSize: pxToRem(28),
    fontWeight: 700,
    lineHeight: 36 / 28,
  },
  'Headline/Bold/small': {
    fontFamily: FONT_PRIMARY,
    fontSize: pxToRem(24),
    fontWeight: 700,
    lineHeight: 32 / 24,
  },

  'Title/large': {
    fontFamily: FONT_PRIMARY,
    fontSize: pxToRem(22),
    fontWeight: 400,
    lineHeight: 28 / 22,
  },
  'Title/medium': {
    fontFamily: FONT_PRIMARY,
    fontSize: pxToRem(16),
    fontWeight: 500,
    lineHeight: 24 / 16,
  },
  'Title/small': {
    fontFamily: FONT_PRIMARY,
    fontSize: pxToRem(14),
    fontWeight: 500,
    lineHeight: 20 / 14,
  },

  'Title/Bold/large': {
    fontFamily: FONT_PRIMARY,
    fontSize: pxToRem(22),
    fontWeight: 700,
    lineHeight: 28 / 22,
  },
  'Title/Bold/medium': {
    fontFamily: FONT_PRIMARY,
    fontSize: pxToRem(16),
    fontWeight: 700,
    lineHeight: 24 / 16,
  },
  'Title/Bold/small': {
    fontFamily: FONT_PRIMARY,
    fontSize: pxToRem(14),
    fontWeight: 700,
    lineHeight: 20 / 14,
  },

  'Body/large': {
    fontFamily: FONT_PRIMARY,
    fontSize: pxToRem(16),
    fontWeight: 400,
    lineHeight: 24 / 16,
  },
  'Body/medium': {
    fontFamily: FONT_PRIMARY,
    fontSize: pxToRem(14),
    fontWeight: 400,
    lineHeight: 20 / 14,
  },
  'Body/small': {
    fontFamily: FONT_PRIMARY,
    fontSize: pxToRem(12),
    fontWeight: 400,
    lineHeight: 16 / 12,
  },

  'Body/Bold/large': {
    fontFamily: FONT_PRIMARY,
    fontSize: pxToRem(16),
    fontWeight: 600,
    lineHeight: 24 / 16,
  },
  'Body/Bold/medium': {
    fontFamily: FONT_PRIMARY,
    fontSize: pxToRem(14),
    fontWeight: 600,
    lineHeight: 20 / 14,
  },
  'Body/Bold/small': {
    fontFamily: FONT_PRIMARY,
    fontSize: pxToRem(12),
    fontWeight: 600,
    lineHeight: 16 / 12,
  },

  'Label/large': {
    fontFamily: FONT_PRIMARY,
    fontSize: pxToRem(14),
    fontWeight: 500,
    lineHeight: 20 / 14,
  },
  'Label/medium': {
    fontFamily: FONT_PRIMARY,
    fontSize: pxToRem(12),
    fontWeight: 500,
    lineHeight: 16 / 12,
  },
  'Label/small': {
    fontFamily: FONT_PRIMARY,
    fontSize: pxToRem(10),
    fontWeight: 400,
    lineHeight: 12 / 10,
  },

  'Label/Bold/large': {
    fontFamily: FONT_PRIMARY,
    fontSize: pxToRem(14),
    fontWeight: 700,
    lineHeight: 20 / 14,
  },
  'Label/Bold/medium': {
    fontFamily: FONT_PRIMARY,
    fontSize: pxToRem(12),
    fontWeight: 700,
    lineHeight: 16 / 12,
  },
  'Label/Bold/small': {
    fontFamily: FONT_PRIMARY,
    fontSize: pxToRem(10),
    fontWeight: 700700,
    lineHeight: 12 / 10,
  },
};

export const typography = {
  fontFamily: FONT_PRIMARY,
  fontFamilyMono: FONT_MONO,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,

  h1: {
    fontWeight: 800,
    lineHeight: 80 / 64,
    fontSize: pxToRem(64),
  },
  h2: {
    fontWeight: 800,
    lineHeight: 64 / 48,
    fontSize: pxToRem(48),
  },
  h3: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(32),
  },
  h4: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(24),
  },
  h5: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(20),
  },
  h6: {
    fontWeight: 700,
    lineHeight: 28 / 18,
    fontSize: pxToRem(18),
  },
  subtitle1: {
    fontWeight: 600,
    lineHeight: 1.5,
    fontSize: pxToRem(16),
  },
  subtitle2: {
    fontWeight: 600,
    lineHeight: 22 / 14,
    fontSize: pxToRem(14),
  },
  body1: {
    lineHeight: 1.5,
    fontSize: pxToRem(16),
  },
  body2: {
    lineHeight: 22 / 14,
    fontSize: pxToRem(14),
  },
  caption: {
    lineHeight: 1.5,
    fontSize: pxToRem(12),
  },
  overline: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(12),
    textTransform: 'uppercase',
  },
  button: {
    fontWeight: 700,
    lineHeight: 24 / 14,
    fontSize: pxToRem(14),
    textTransform: 'capitalize',
  },
  ...UiKitTypography,
} as const;
