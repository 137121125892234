import { lazy } from 'react';

// ----------------------------------------------------------------------

const ContractorsPage = lazy(
  () =>
    import(
      /* webpackPreload: true */
      /* webpackChunkName: "ContractorsPage" */
      '@/pages/contractors/ContractorsPage'
    ),
);

export default ContractorsPage;
