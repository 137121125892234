import { createContext, useContext } from 'react';

type ModalTabContentContextValue = string;

const ModalTabContentContext = createContext<ModalTabContentContextValue | null>(null);

if (process.env.NODE_ENV !== 'production') {
  ModalTabContentContext.displayName = 'ModalTabContentContext';
}

type ModalTabContentProviderProps = React.PropsWithChildren<{
  value: ModalTabContentContextValue;
}>;

export const ModalTabContentProvider = (props: ModalTabContentProviderProps) => {
  const { value, children } = props;

  return <ModalTabContentContext.Provider value={value}>{children}</ModalTabContentContext.Provider>;
};

export const useModalTabContentContext = () => {
  const context = useContext(ModalTabContentContext);

  if (!context) {
    throw new Error('useModalTabContentContext must be used within a ModalTabContentProvider');
  }

  return context;
};
