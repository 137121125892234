import { generatePath as reactRouterGeneratePath } from 'react-router-dom';
import { GeneratedPath } from './path.types';

export const generatePath = <TPath extends string, TParams extends Record<string, string>>(
  path: TPath,
  params: TParams,
) => reactRouterGeneratePath(path, params as any) as GeneratedPath<TPath, TParams>;

export const joinPath = <THead extends string, TTail extends string>(head: THead, tail: TTail) =>
  `${head}/${tail}`.replace(/(\/){2,}/g, '/') as `${THead}/${TTail}`;
