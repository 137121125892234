import { PropsWithChildren } from 'react';
import { styled } from '@mui/material/styles';
import { Sidebar } from '@/layouts/Sidebar';

const StyledRoot = styled('div')({
  alignSelf: 'stretch',
  display: 'flex',
  height: '100vh',
  width: '100%',
  minWidth: 1280,
  overflow: 'hidden',
  backgroundColor: '#FCFBFC',
});

export const BaseLayout = ({ children }: PropsWithChildren) => (
  <StyledRoot>
    <Sidebar />

    {children}
  </StyledRoot>
);
