import { To } from 'react-router-dom';
import { useMatchLinks } from '@/hooks/useMatchLinks';

// ----------------------------------------------------------------------

export const useActiveRoutes = (...links: Array<To>) => {
  const matches = useMatchLinks(...links);

  return !!matches;
};
