import { MouseEvent } from 'react';
import { useCopyToClipboard, useToggle, useUpdateEffect } from 'react-use';
import { Button, ButtonProps } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { ReactComponent as DoneSVG } from '@/assets/icons-2.0/done.svg';
import { ActionIconButton, ActionIconButtonProps } from '@/components/buttons/ActionIconButton';

// ----------------------------------------------------------------------

type Props = ActionIconButtonProps & {
  valueToCopy: string;
  label?: string;
};

// ----------------------------------------------------------------------

export const ClipboardButton = (props: Props) => {
  const { valueToCopy, onClick: passedOnClick, label, ...restOfProps } = props;

  const [, copyToClipboard] = useCopyToClipboard();
  const [isCopied, toggleIsCopied] = useToggle(false);

  const showCopiedIcon = () => toggleIsCopied(true);
  const hideCopiedIcon = () => toggleIsCopied(false);

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    copyToClipboard(valueToCopy);
    passedOnClick?.(e);
    showCopiedIcon();
  };

  useUpdateEffect(() => {
    if (isCopied) {
      const timeout = setTimeout(hideCopiedIcon, 1000);

      return () => clearTimeout(timeout);
    }
  }, [isCopied]);

  const ButtonIcon = isCopied ? <DoneSVG fontSize="medium" /> : <ContentCopyIcon fontSize="medium" />;

  return (
    <ActionIconButton title="Скопировать" onClick={handleClick} {...restOfProps}>
      {ButtonIcon}
      {label}
    </ActionIconButton>
  );
};

type ClipboardTextButtonProps = ButtonProps & {
  valueToCopy: string;
  label?: string;
  copiedLabel?: string;
};

export const ClipboardTextButton = (props: ClipboardTextButtonProps) => {
  const { valueToCopy, onClick: passedOnClick, label, copiedLabel, ...restOfProps } = props;

  const [, copyToClipboard] = useCopyToClipboard();
  const [isCopied, toggleIsCopied] = useToggle(false);

  const showCopiedIcon = () => toggleIsCopied(true);
  const hideCopiedIcon = () => toggleIsCopied(false);

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    copyToClipboard(valueToCopy);
    passedOnClick?.(e);
    showCopiedIcon();
  };

  useUpdateEffect(() => {
    if (isCopied) {
      const timeout = setTimeout(hideCopiedIcon, 1000);

      return () => clearTimeout(timeout);
    }
  }, [isCopied]);

  const buttonIcon = isCopied ? <DoneSVG fontSize="medium" /> : <ContentCopyIcon fontSize="medium" />;
  const buttonLabel = copiedLabel && isCopied ? copiedLabel : label;

  return (
    <Button variant="contained" color="secondary" startIcon={buttonIcon} onClick={handleClick} {...restOfProps}>
      {buttonLabel}
    </Button>
  );
};
