import { MakersModalDeleteButton, MakersModalDuplicateButton } from '@/components/makers/actions';
import { ModalToolbar } from '@/components/Modal';

// ----------------------------------------------------------------------

type MakersModalToolbarProps = {
  disabled?: boolean;
};

export const MakersModalToolbar = (props: MakersModalToolbarProps) => {
  const { disabled } = props;

  return (
    <ModalToolbar>
      <MakersModalDuplicateButton disabled={disabled} />
      <MakersModalDeleteButton disabled={disabled} />
    </ModalToolbar>
  );
};
