import { lazy } from 'react';

// ----------------------------------------------------------------------

const ClassificationsPage = lazy(
  () =>
    import(
      /* webpackPreload: true */
      /* webpackChunkName: "ClassificationsPage" */
      '@/pages/classifications/ClassificationsPage'
    ),
);

export default ClassificationsPage;
