import { SvgIcon } from '@mui/material';

import { ReactComponent as DeduplicateSVG } from '@/assets/icons/common/de-duplicate.svg';
import {
  ActionButton,
  type ActionButtonProps,
  ActionIconButton,
  type ActionIconButtonProps,
  MainActionButton,
  type MainActionButtonProps,
} from '@/components/buttons';

export type DeduplicateMainActionButtonProps = MainActionButtonProps;

export const DeduplicateMainActionButton = (props: DeduplicateMainActionButtonProps) => {
  const { children = 'Убрать дубли' } = props;

  return (
    <MainActionButton startIcon={<SvgIcon component={DeduplicateSVG} inheritViewBox fontSize="small" />} {...props}>
      {children}
    </MainActionButton>
  );
};

export type DeduplicateActionButtonProps = ActionButtonProps;

export const DeduplicateActionButton = (props: DeduplicateActionButtonProps) => {
  const { children = 'Убрать дубли' } = props;

  return (
    <ActionButton startIcon={<SvgIcon component={DeduplicateSVG} inheritViewBox fontSize="small" />} {...props}>
      {children}
    </ActionButton>
  );
};

export type DeduplicateActionIconButtonProps = ActionIconButtonProps;

export const DeduplicateActionIconButton = (props: DeduplicateActionIconButtonProps) => (
  <ActionIconButton title="Убрать дубли" {...props}>
    <SvgIcon component={DeduplicateSVG} inheritViewBox fontSize="medium" />
  </ActionIconButton>
);
