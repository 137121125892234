import {
  ConfirmationDialogActionProceedButton,
  ConfirmationDialogActions,
  ConfirmationDialogBodyMutedText,
  ConfirmationDialogContainer,
  type ConfirmationDialogContainerProps,
  ConfirmationDialogHeader,
} from './elements.confirmation-dialog';

export type WarningDialogProps = Omit<ConfirmationDialogContainerProps, 'subject'> &
  React.PropsWithChildren<{
    title: string;
  }>;

export const WarningDialog = (props: WarningDialogProps) => {
  const { title, children, onClose, ...restProps } = props;

  return (
    <ConfirmationDialogContainer subject="warning" onClose={onClose} {...restProps}>
      <ConfirmationDialogHeader>{title}</ConfirmationDialogHeader>
      <ConfirmationDialogBodyMutedText>{children}</ConfirmationDialogBodyMutedText>
      <ConfirmationDialogActions>
        <ConfirmationDialogActionProceedButton onClick={onClose}>Закрыть</ConfirmationDialogActionProceedButton>
      </ConfirmationDialogActions>
    </ConfirmationDialogContainer>
  );
};
