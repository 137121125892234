import { Link, useParams } from 'react-router-dom';
import { Tab, Tabs } from '@mui/material';

import { HeaderTabs } from '@/layouts/Header';
import { EimzoDocumentsDetailPathParams, EimzoDocumentsPath } from '@/router/paths/e-imzo-documents.paths';
import { useMatchLinks } from '@/hooks/useMatchLinks';

export const LayoutTabs = () => {
  const { uuid } = useParams<EimzoDocumentsDetailPathParams>();
  const stringifiedUUID = String(uuid);

  const documentPath = EimzoDocumentsPath.generateDocumentPath({ uuid: stringifiedUUID });
  const signatureProtocolPath = EimzoDocumentsPath.generateSignatureProtocolPath({ uuid: stringifiedUUID });

  const matchedLink = useMatchLinks(documentPath, signatureProtocolPath);

  return (
    <HeaderTabs>
      {matchedLink?.pathname && (
        <Tabs value={matchedLink.pathname} textColor="primary" indicatorColor="primary">
          <Tab component={Link} to={documentPath} value={documentPath} label="Документ" />
          <Tab component={Link} to={signatureProtocolPath} value={signatureProtocolPath} label="Протокол подписания" />
        </Tabs>
      )}
    </HeaderTabs>
  );
};
