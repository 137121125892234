import React from 'react';
import { styled, SxProps, Theme } from '@mui/system';
import { List } from '@mui/material';

type GridContainerProps = React.PropsWithChildren & {
  columns: number;
  sx?: SxProps<Theme>;
};

const StyledGridContainer = styled(List)<GridContainerProps>(({ theme, columns }) => ({
  padding: 0,
  display: 'grid',
  gridTemplateColumns: `repeat(${columns}, 1fr)`,
  gridGap: theme.spacing(2),
  '@media (max-width: 850px)': {
    gridTemplateColumns: `repeat(${columns - 1}, 1fr)`,
  },
  '@media (max-width: 650px)': {
    gridTemplateColumns: `repeat(${columns - 2}, 1fr)`,
  },
}));

export const GridContainer = (props: GridContainerProps) => <StyledGridContainer {...props} />;
