import { useQuery } from '@tanstack/react-query';
import { PlanogramQueryKeys } from '@/api/domains/planogram.query-keys';
import { FetchPlanogramControlTaskParams, PlanogramApi } from '@/api/domains/planogram.api';

export const usePlanogramTask = (params: FetchPlanogramControlTaskParams) => {
  return useQuery({
    queryKey: PlanogramQueryKeys.getPlanogramControlTask(params),
    queryFn: () => {
      return PlanogramApi.fetchPlanogramControlTask(params);
    },
    staleTime: Infinity,
    enabled: !!params.taskId,
  });
};
