import { useRef } from 'react';
import { useCopyToClipboard, useHoverDirty } from 'react-use';
import { TextField, TextFieldProps } from '@mui/material';
import { ClearAdornment } from './ClearAdornment';
import { ClipboardAdornment } from './ClipboardAdornment';
import { CircularProgressAdornment } from './CircularProgressAdornment';

// ----------------------------------------------------------------------

export type CustomTextFieldProps = Omit<TextFieldProps, 'onCopy' | 'ref'> & {
  isLoading?: boolean;
  isClearableField?: boolean;
  isCopyableField?: boolean;
  onClearButtonClick?: VoidFunction;
  onClipboardButtonClick?: VoidFunction;
  transformCopiedValue?: (value: unknown) => string;
};

// ----------------------------------------------------------------------

export const CustomTextField = (props: CustomTextFieldProps) => {
  const isClearableFieldDefault = props.disabled !== true;

  const {
    isLoading,
    isClearableField = isClearableFieldDefault,
    isCopyableField = false,
    transformCopiedValue = (value: unknown) => String(value),
    onClearButtonClick,
    onClipboardButtonClick,
    value,
    InputProps,
    ...restOfProps
  } = props;

  const textFieldRef = useRef<HTMLDivElement>(null);
  const isTextFieldHovered = useHoverDirty(textFieldRef);

  const [, copyToClipboard] = useCopyToClipboard();

  return (
    <TextField
      value={value}
      ref={textFieldRef}
      InputProps={{
        ...InputProps,
        endAdornment: (
          <>
            {InputProps?.endAdornment ? InputProps.endAdornment : null}
            {isClearableField && (
              <ClearAdornment
                isClearButtonVisible={!!value && isTextFieldHovered}
                onClear={onClearButtonClick}
                position="end"
              />
            )}
            {isCopyableField && (
              <ClipboardAdornment
                isClipboardButtonVisible={!!value}
                onCopy={() => {
                  copyToClipboard(transformCopiedValue(transformCopiedValue(value)));
                  onClipboardButtonClick?.();
                }}
                position="end"
              />
            )}
            {isLoading && <CircularProgressAdornment position="end" />}
          </>
        ),
      }}
      {...restOfProps}
    />
  );
};
