import { lazy } from 'react';

// ----------------------------------------------------------------------

const PriceListsPage = lazy(
  () =>
    import(
      /* webpackPreload: true */
      /* webpackChunkName: "PriceListsPage" */
      '@/pages/price-lists/PriceListsPage'
    ),
);

export default PriceListsPage;
