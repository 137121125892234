import { Stack, StackProps, styled } from '@mui/material';

const StyledRoot = styled(Stack)({
  height: '54px',
  width: '100%',
  alignItems: 'center',
}) as typeof Stack;

export const PanelBase = (props: StackProps) => {
  const { children, ...restOfProps } = props;

  return <StyledRoot {...restOfProps}>{children}</StyledRoot>;
};
