import { LabelColor } from '@/components/labels';
import {
  PromoContractStatusId,
  PromoContractStatusNameByStatusId,
  PromoContractColorByStatusId,
} from '@/constants/promo-contract-status.constants';
import { PromoContractRecordStatus } from '../data-grids/types';

export const getColorByPromoContractStatusId = (statusId: PromoContractStatusId): LabelColor => {
  return PromoContractColorByStatusId[statusId];
};

export const getPromoContractRecordStatus = (recordStatusId: PromoContractStatusId): PromoContractRecordStatus => ({
  name: PromoContractStatusNameByStatusId[recordStatusId],
  color: PromoContractColorByStatusId[recordStatusId],
});
