import {
  ConfirmationDialogActionProceedButton,
  ConfirmationDialogActions,
  ConfirmationDialogBodyMutedText,
  ConfirmationDialogContainer,
  ConfirmationDialogContainerProps,
  ConfirmationDialogHeader,
} from '@/components/dialogs/elements.confirmation-dialog';

type LinitedConfirmationDialogContainerProps = Omit<ConfirmationDialogContainerProps, 'subject'>;

type WarningInfoDialogProps = LinitedConfirmationDialogContainerProps & {
  title: string;
  mainText: React.ReactNode;
  desciptionText?: string;
};

export const WarningInfoDialog = (props: WarningInfoDialogProps) => {
  const { onClose, title, mainText, desciptionText, children, ...restOfDialogProps } = props;

  return (
    <ConfirmationDialogContainer subject="warning" maxWidth="sm" onClose={onClose} {...restOfDialogProps}>
      <ConfirmationDialogHeader>{title}</ConfirmationDialogHeader>
      <ConfirmationDialogBodyMutedText sx={{ whiteSpace: 'nowrap' }}>{mainText}</ConfirmationDialogBodyMutedText>

      {children}

      {desciptionText && (
        <ConfirmationDialogBodyMutedText sx={{ whiteSpace: 'nowrap' }}>
          {desciptionText}
        </ConfirmationDialogBodyMutedText>
      )}

      <ConfirmationDialogActions justifyContent="flex-end">
        <ConfirmationDialogActionProceedButton fullWidth={false} autoFocus onClick={onClose}>
          Закрыть
        </ConfirmationDialogActionProceedButton>
      </ConfirmationDialogActions>
    </ConfirmationDialogContainer>
  );
};
