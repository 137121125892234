import { useUnmount } from 'react-use';
import { some } from 'lodash';
import { devtools } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';
import { Maker } from '@/api/domains/makers.types';
import { createStoreWithReset } from '@/libs/zustand';

// ----------------------------------------------------------------------

export type MakersStore = {
  // State
  isFilterPanelVisible: boolean;

  // ----------------------------------------------------------------------
  // Modals
  isCreateModalVisible: boolean;
  isDuplicateModalVisible: boolean;
  isEditModalVisible: boolean;

  // ----------------------------------------------------------------------
  // Dialogs
  isDeleteDialogVisible: boolean;
  isDuplicateDialogVisible: boolean;

  searchQuery: string;
  selectedRow: Maker | null;

  // Actions
  actions: {
    showFilterPanel: VoidFunction;
    hideFilterPanel: VoidFunction;

    // ----------------------------------------------------------------------
    // Modals
    openCreateModal: VoidFunction;
    closeCreateModal: VoidFunction;
    openDuplicateModal: VoidFunction;
    closeDuplicateModal: VoidFunction;
    openEditModal: VoidFunction;
    closeEditModal: VoidFunction;

    // ----------------------------------------------------------------------
    // Dialogs
    openDeleteDialog: VoidFunction;
    closeDeleteDialog: VoidFunction;
    openDuplicateDialog: VoidFunction;
    closeDuplicateDialog: VoidFunction;

    // ----------------------------------------------------------------------

    setSearchQuery: (query: string) => void;
    clearSearchQuery: VoidFunction;

    setSelectedRow: (selectedRow: Maker) => void;
    resetSelectedRow: VoidFunction;
  };
};

// ----------------------------------------------------------------------

export const [useMakersStore, resetMakersStore] = createStoreWithReset<MakersStore>()(
  devtools(
    set => ({
      // State
      isFilterPanelVisible: false,

      // ----------------------------------------------------------------------
      // Modals
      isCreateModalVisible: false,
      isDuplicateModalVisible: false,
      isEditModalVisible: false,

      // ----------------------------------------------------------------------
      // Dialogs
      isDeleteDialogVisible: false,
      isDuplicateDialogVisible: false,

      // ----------------------------------------------------------------------
      searchQuery: '',
      selectedRow: null,

      // Actions
      actions: {
        // ----------------------------------------------------------------------
        // Modals
        showFilterPanel: () => set({ isFilterPanelVisible: true }, false, 'showFilterPanel'),
        hideFilterPanel: () => set({ isFilterPanelVisible: false }, false, 'hideFilterPanel'),
        openCreateModal: () => set({ isCreateModalVisible: true }, false, 'openCreateModal'),
        closeCreateModal: () => set({ isCreateModalVisible: false }, false, 'closeCreateModal'),
        openDuplicateModal: () => set({ isDuplicateModalVisible: true }, false, 'openDuplicateModal'),
        closeDuplicateModal: () => set({ isDuplicateModalVisible: false }, false, 'closeDuplicateModal'),
        openEditModal: () => set({ isEditModalVisible: true }, false, 'openEditModal'),
        closeEditModal: () => set({ isEditModalVisible: false }, false, 'closeEditModal'),

        // ----------------------------------------------------------------------
        // Dialogs
        openDeleteDialog: () => set({ isDeleteDialogVisible: true }, false, 'openDeleteDialog'),
        closeDeleteDialog: () => set({ isDeleteDialogVisible: false }, false, 'closeDeleteDialog'),
        openDuplicateDialog: () => set({ isDuplicateDialogVisible: true }, false, 'openDuplicateDialog'),
        closeDuplicateDialog: () => set({ isDuplicateDialogVisible: false }, false, 'closeDuplicateDialog'),

        // ----------------------------------------------------------------------

        setSearchQuery: searchQuery => set({ searchQuery, selectedRow: null }, false, 'setSearchQuery'),
        clearSearchQuery: () => set({ searchQuery: '', selectedRow: null }, false, 'clearSearchQuery'),

        setSelectedRow: selectedRow => set({ selectedRow }, false, 'setSelectedRow'),
        resetSelectedRow: () => set({ selectedRow: null }, false, 'resetSelectedRow'),
      },
    }),
    { name: 'useMakersStore' },
  ),
);

// ----------------------------------------------------------------------
// State selectors

export const selectMakersStoreIsFilterPanelVisible = (state: MakersStore) => state.isFilterPanelVisible;
export const selectMakersStoreSelectedRow = (state: MakersStore) => state.selectedRow;
export const selectMakersStoreContextWindowsState = (state: MakersStore) => ({
  isCreateModalVisible: state.isCreateModalVisible,
  isDuplicateDialogVisible: state.isDuplicateDialogVisible,
  isDuplicateModalVisible: state.isDuplicateModalVisible,
  isEditModalVisible: state.isEditModalVisible,
  isDeleteDialogVisible: state.isDeleteDialogVisible,
});
export const selectMakersStoreIsContextWindowsVisible = (state: MakersStore) =>
  some(selectMakersStoreContextWindowsState(state));
export const selectMakersStoreSearchQuery = (state: MakersStore) => state.searchQuery;

// ----------------------------------------------------------------------
// Actions selectors

export const selectMakersStoreActions = (state: MakersStore) => state.actions;

// ----------------------------------------------------------------------
// Hooks

export const useResetMakersStoreOnUnmount = () => useUnmount(resetMakersStore);
export const useMakersStoreActions = () => useMakersStore(selectMakersStoreActions, shallow);
export const useMakersStoreContextWindowsState = () => useMakersStore(selectMakersStoreContextWindowsState, shallow);
export const useMakersStoreIsContextWindowsVisible = () => useMakersStore(selectMakersStoreIsContextWindowsVisible);
