import { ConfirmationDeleteDialog, ConfirmationDeleteDialogProps } from '@/components/dialogs';
import { useGalleryStoreIsContextWindowsOpen, useGalleryStoreSelectedImage } from '../gallery.store';
import { ImageCard } from '../ImageCard';

type DeleteImageDialogProps = Omit<ConfirmationDeleteDialogProps, 'open'>;

export const DeleteImageDialog = (props: DeleteImageDialogProps) => {
  const { isDeleteDialogOpen } = useGalleryStoreIsContextWindowsOpen();
  const selectedImage = useGalleryStoreSelectedImage();

  return (
    <ConfirmationDeleteDialog open={isDeleteDialogOpen} {...props}>
      {selectedImage && <ImageCard image={selectedImage} isOverlayImage wrapperStyle={{ width: 164, height: 164 }} />}
    </ConfirmationDeleteDialog>
  );
};
