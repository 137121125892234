import { useSnackbar } from 'notistack';
import { produce } from 'immer';
import { isNumber, last } from 'lodash';
import { InfiniteData, useMutation, useQueryClient } from '@tanstack/react-query';

import {
  PromoContractUpdateParams,
  PromoContractsApi,
  PromoContractsFetchParams,
} from '@/api/domains/promo-contracts.api';
import { PromoContractsQueryKeys } from '@/api/domains/promo-contracts.query-keys';
import { PaginatedData } from '@/api/@types/pagination.types';
import { NormalizedData } from '@/helpers/transformation.helpers';
import { PromoContractDataGridRowData } from './types';
import {
  usePromoContractsRecordFilterStatus,
  usePromoContractsStoreGridFilterPeriod,
  usePromoContractsStoreSearchQuery,
} from '../promo-contracts.store';
import { getPromoContractRecordStatus } from '../helpers/status-select.helpers';

type UseUpdatePromoContract = {
  contractId: number;
  params: PromoContractUpdateParams;
  rowData: PromoContractDataGridRowData;
  recordStatuses?: string;
};

type MutateContracts = InfiniteData<
  PaginatedData<'items', NormalizedData<PromoContractDataGridRowData, 'data' | 'indices', number>>
>;

export const useUpdatePromoContract = () => {
  const queryClient = useQueryClient();
  const [startDate, endDate] = usePromoContractsStoreGridFilterPeriod();
  const recordFilterStatus = usePromoContractsRecordFilterStatus();
  const query = usePromoContractsStoreSearchQuery();
  const recordStatuses = recordFilterStatus ? String(recordFilterStatus) : undefined;
  const { enqueueSnackbar } = useSnackbar();

  return useMutation({
    mutationFn: async ({ contractId, params }: UseUpdatePromoContract) =>
      await PromoContractsApi.updatePromoContract(contractId, params),
    onSuccess: (_, variables) => {
      const {
        contractId,
        params: { record_status_id },
      } = variables;

      const keysToUpdate: Array<Partial<PromoContractsFetchParams>> = [{ date_from: startDate, date_to: endDate }];

      if (query) {
        keysToUpdate.push({ ...last(keysToUpdate), query });
      }

      if (recordStatuses) {
        keysToUpdate.push({ ...last(keysToUpdate), record_statuses: recordStatuses });
      }

      keysToUpdate.forEach(queryKey =>
        queryClient.setQueryData<MutateContracts>(
          PromoContractsQueryKeys.getContractsKey(queryKey),
          currentQueryData => {
            const newQueryData = produce(currentQueryData, draft => {
              for (const page of draft?.pages ?? []) {
                const itemIndex = page.items.indices[contractId];

                if (isNumber(itemIndex)) {
                  const contract = page.items.data[itemIndex];
                  contract.promoContract.record_status_id = record_status_id;
                  contract.recordStatus = getPromoContractRecordStatus(record_status_id);
                  contract.recordStatusId = record_status_id;
                }
              }
            });

            return newQueryData;
          },
        ),
      );

      enqueueSnackbar('Статус изменен', { variant: 'success' });
    },
    onError: (_, { rowData }) => {
      enqueueSnackbar(
        `Не удалось изменить статус для спецификации ${rowData.specificationNumber}, ${rowData.organizationName}`,
        {
          variant: 'error',
          persist: true,
        },
      );
    },
  });
};
