import { PaginationParams } from '@/api/@types/pagination.types';
import { paramsToQueryKey } from '@/helpers/transformation.helpers';

// ----------------------------------------------------------------------

const PREFIX = 'countries' as const;

// ----------------------------------------------------------------------

export const CountriesQueryKeys = {
  root: [PREFIX],

  countries: (params?: PaginationParams) => paramsToQueryKey([PREFIX, 'list'], params),
};
