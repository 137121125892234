import { Stack, SvgIcon, Typography } from '@mui/material';

export type ModalInfoItem = {
  label: React.ReactNode;
  icon?: React.ReactNode;
  value?: React.ReactNode;
};

export type ModalInfoProps = {
  title?: React.ReactNode;
  data: ModalInfoItem[];
};

export const ModalInfo = (props: ModalInfoProps) => {
  const { title, data } = props;

  return (
    <Stack spacing={1}>
      {title && (
        <Typography component="p" variant="body2" fontWeight={600} color="neutral.700">
          {title}
        </Typography>
      )}

      <Stack spacing={1}>
        {data.map(({ icon, label, value }, index) => (
          <Stack key={index} direction="row" alignItems="center" spacing={0.5}>
            <Stack direction="row" alignItems="center" spacing={1} flexShrink={0} width="50%">
              <SvgIcon color="secondary" fontSize="small">
                {icon}
              </SvgIcon>
              <Typography component="span" variant="body2" color="neutral.700">
                {label}:
              </Typography>
            </Stack>
            <Typography component="span" noWrap variant="body2" width="50%">
              {value}
            </Typography>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};
