import { EimzoDocumentsDetailPathParams } from '@/router/paths/e-imzo-documents.paths';
import { useParams } from 'react-router';
import { useQuery } from '@tanstack/react-query';
import { EimzoDocumentsQueryKeys } from '@/api/domains/e-imzo-documents.query-keys';
import { EimzoDocumentsApi } from '@/api/domains/e-imzo-documents.api';

export const useEimzoDocument = () => {
  const { uuid } = useParams<EimzoDocumentsDetailPathParams>();

  return useQuery({
    queryKey: EimzoDocumentsQueryKeys.getDocumentKey({ document_uuid: uuid }),
    queryFn: () => {
      if (!uuid) return;

      return EimzoDocumentsApi.fetchDocument({ document_uuid: uuid });
    },
    staleTime: Infinity,
    enabled: !!uuid,
  });
};
