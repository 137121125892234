import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { MakersApi, MakersParams } from '@/api/domains/makers.api';
import { MakersQueryKeys } from '@/api/domains/makers.query-keys';
import { Maker } from '@/api/domains/makers.types';

// ----------------------------------------------------------------------

const defaultItems: Maker[] = [];

export const useMakers = (
  params?: MakersParams,
  queryOptions?: Pick<UseQueryOptions, 'enabled' | 'keepPreviousData'>,
) => {
  const { isInitialLoading, data } = useQuery({
    queryKey: MakersQueryKeys.makers(params),
    queryFn: () => MakersApi.fetchMakers(params),
    staleTime: Infinity,
    ...queryOptions,
  });

  const makers = data?.items ?? defaultItems;

  return { isInitialLoading, makers };
};
