import { Box, type BoxProps } from '@mui/material';

type FormProps = BoxProps<'form'> & {
  isDisabled?: boolean;
  slotProps?: {
    fieldset?: Omit<BoxProps<'fieldset'>, 'disabled' | 'children'>;
  };
};

export const Form = (props: FormProps) => {
  const { isDisabled, slotProps, children, ...restProps } = props;

  return (
    <Box component="form" {...restProps}>
      <Box component="fieldset" disabled={isDisabled} display="contents" {...slotProps?.fieldset}>
        {children}
      </Box>
    </Box>
  );
};
