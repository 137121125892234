import { PaginatedList, PaginationParams } from '@/api/@types/pagination.types';
import {
  Contractor,
  ContractorRegisterData,
  ContractorOpeningHours,
  ContractorSearch,
  DistributorExtraData,
} from '@/api/domains/contractors.types';

import { ContractorTypeAlias } from '@/constants/contractor-types.constants';
import { httpClient } from '@/libs/axios/http-client';

// ----------------------------------------------------------------------

export type FetchContractorsParams = PaginationParams & {
  contractor_type_aliases?: ReadonlyArray<ContractorTypeAlias>;
  parent_id?: number;
  query?: string;
  specification_template_id?: number;
  without_relations?: boolean;
};

// ----------------------------------------------------------------------

export type SearchContractorsParams = PaginationParams & {
  query?: string;
  contractor_type_ids?: Array<number>;
};

// ----------------------------------------------------------------------

export type ContractorCreateFormData = {
  contractor_type_id: number;
  name: string;
  logo_url_token?: string;
  legal_status_id: number;
  country_id: string;
  country_name: string;
  description?: string;
  note?: string;
  parent_id?: number;
  tin?: number;
  email?: string;
  activity_status_id?: number;
  price_list_source?: string;
  responsible_manager_id?: number;
};

// ----------------------------------------------------------------------

export type ContractorUpdateFormData = ContractorCreateFormData & {
  record_status_id?: number;
};

export type UpdatePharmacyDataParams = {
  contractorId: number;
  geolocation_latitude: number;
  geolocation_longitude: number;
  location_description: string;
  opening_hours: Partial<ContractorOpeningHours>;
};

// ----------------------------------------------------------------------

export type ContractorRegisterDataParams = {
  tin: number;
};

export type DistributorsSettingsParams = PaginationParams & {
  contractor_ids?: Array<string>;
};

// ----------------------------------------------------------------------

export type DistributorExtraDataCreateFormData = {
  contractor_id: number;
  delivery_regions?: number[];
  min_order_amount?: number;
  min_order_amount_with_delivery?: number;
  activity_period?: number;
};

// ----------------------------------------------------------------------

export type DistributorExtraDataUpdateFormData = Omit<DistributorExtraDataCreateFormData, 'contractor_id'>;

// ----------------------------------------------------------------------
export type UpdateContractorLogoParams = {
  contractor_id: number;
  file: File;
};
// ----------------------------------------------------------------------
export type DeleteContractorLogoParams = {
  contractor_id: number;
};

// ----------------------------------------------------------------------

export type GenerateAgentIdParams = {
  contractor_id: number;
};

export type FetchContractorConfigParams = {
  contractor_id: number;
};

export type UpdateContractorConfigParams = {
  contractor_id: number;
  configuration?: string;
};

export type FetchContractorConfigResponse = {
  contractor_id: string;
  agent_id: string;
  configuration: string;
};

type UpdateContractorConfigResponse = {
  configuration: string;
};

// ----------------------------------------------------------------------

export const ContractorsApi = {
  fetchContractor: async (contractorId: number) => {
    const { data } = await httpClient.get<Contractor>(`contractors/${contractorId}`);

    return data;
  },

  fetchContractors: async (params?: FetchContractorsParams) => {
    const { data } = await httpClient.get<PaginatedList<'items', Contractor>>('contractors', {
      params,
    });

    return data;
  },

  // ----------------------------------------------------------------------

  searchContractors: async (params: SearchContractorsParams) => {
    const { data } = await httpClient.get<PaginatedList<'items', ContractorSearch>>('contractors/search', {
      params,
    });

    return data;
  },

  // ----------------------------------------------------------------------

  deleteContractor: (contractorId: number) => httpClient.delete<void>(`contractors/${contractorId}`),

  // ----------------------------------------------------------------------

  createContractor: async (formData: ContractorCreateFormData) => {
    const { data } = await httpClient.post<Contractor>('contractors', formData);

    return data;
  },

  // ----------------------------------------------------------------------

  updateContractor: async (contractorId: number, formData: ContractorUpdateFormData) => {
    const { data } = await httpClient.put<Contractor>(`contractors/${contractorId}`, formData);

    return data;
  },

  // ----------------------------------------------------------------------
  fetchContractorRegisterData: async (params: ContractorRegisterDataParams) => {
    const { data } = await httpClient.get<ContractorRegisterData>(`contractor-data/${params.tin}`);

    return data;
  },
  // ----------------------------------------------------------------------

  fetchDistributorExtraData: async (contractorId: number) => {
    const { data } = await httpClient.get<DistributorExtraData>(`distributors/${contractorId}/extra-data`);

    return data;
  },

  // ----------------------------------------------------------------------

  createDistributorExtraData: (formData: DistributorExtraDataCreateFormData) =>
    httpClient.post<DistributorExtraData>('distributors/extra-data', formData),

  // ----------------------------------------------------------------------

  updateDistributorExtraData: async (contractorId: number, formData: DistributorExtraDataUpdateFormData) => {
    const { data } = await httpClient.put<DistributorExtraData>(`distributors/extra-data/${contractorId}`, formData);

    return data;
  },

  // ----------------------------------------------------------------------

  deleteDistributorExtraData: (contractorId: number) =>
    httpClient.delete<void>(`distributors/extra-data/${contractorId}`),

  // ----------------------------------------------------------------------

  updateContractorLogo: async (params: UpdateContractorLogoParams) => {
    const { contractor_id, file } = params;
    const formData = new FormData();
    formData.append('file', file, file.name);

    const { data } = await httpClient.put<Contractor>(`contractor/${contractor_id}/logo`, formData, {
      headers: {
        'Content-Type': `multipart/form-data;`,
      },
    });

    return data;
  },

  // ----------------------------------------------------------------------

  deleteContractorLogo: async (params: DeleteContractorLogoParams) => {
    const { data } = await httpClient.delete<Contractor>(`contractor/${params.contractor_id}/logo`);

    return data;
  },

  // ----------------------------------------------------------------------

  generateAgentId: (params: GenerateAgentIdParams) =>
    httpClient.patch<string>(`configger/${params.contractor_id}/config-key`),

  fetchContractorConfig: async (params: FetchContractorConfigParams) => {
    const { data } = await httpClient.get<FetchContractorConfigResponse>(`configger/${params.contractor_id}/config`);

    return data;
  },

  updateContractorConfig: async ({ contractor_id, ...params }: UpdateContractorConfigParams) =>
    httpClient.post<UpdateContractorConfigResponse>(`configger/${contractor_id}/config`, params),

  updatePharmacyData: async ({ contractorId, ...params }: UpdatePharmacyDataParams) => {
    const { data } = await httpClient.put<Contractor>(`contractors/${contractorId}/pharmacy-data`, params);

    return data;
  },
};
