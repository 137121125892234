import {
  ConfirmationDialogBodyCard,
  ConfirmationDialogBodyMutedText,
  ConfirmationDialogBodyText,
  ConfirmationDuplicateDialog,
} from '@/components/dialogs';
import {
  selectMakersStoreSelectedRow,
  useMakersStore,
  useMakersStoreActions,
  useMakersStoreContextWindowsState,
} from '@/components/makers/useMakersStore';

// ----------------------------------------------------------------------

export const MakersDuplicateDialog = () => {
  const maker = useMakersStore(selectMakersStoreSelectedRow);

  const { isDuplicateDialogVisible, isDuplicateModalVisible } = useMakersStoreContextWindowsState();

  const { closeDuplicateDialog, closeDuplicateModal } = useMakersStoreActions();

  const cancelDuplicateCreation = () => {
    closeDuplicateDialog();

    if (isDuplicateModalVisible) {
      closeDuplicateModal();
    }
  };

  return (
    <ConfirmationDuplicateDialog
      open={isDuplicateDialogVisible}
      onClose={closeDuplicateDialog}
      onCancel={cancelDuplicateCreation}
      onProceed={closeDuplicateDialog}
    >
      {maker && (
        <ConfirmationDialogBodyCard>
          <ConfirmationDialogBodyText>{maker.name}</ConfirmationDialogBodyText>
          <ConfirmationDialogBodyMutedText>{maker.country_name}</ConfirmationDialogBodyMutedText>
        </ConfirmationDialogBodyCard>
      )}
    </ConfirmationDuplicateDialog>
  );
};
