import { type ChangeEventHandler, type ClipboardEventHandler, useState } from 'react';
import { useUpdateEffect } from 'react-use';
import type { Classification, ClassificationWithChildren } from '@/api/domains/classifications.types';
import { useDebouncedCallback } from '@/hooks/useDebouncedCallback';
import { getClassificationName } from '../utils/getClassificationName';

type UseClassificationFieldOptions = {
  value: Classification | ClassificationWithChildren | null;
  onChange: (classification: Classification | ClassificationWithChildren | null) => void;
};

export const useClassificationField = (options: UseClassificationFieldOptions) => {
  const { value, onChange } = options;
  const [inputValue, setInputValue] = useState(() => getClassificationName(value));
  const [searchQuery, setSearchQuery] = useState('');
  const isSearchMode = searchQuery !== '';

  const [debouncedSetSearchQuery, cancel] = useDebouncedCallback(setSearchQuery, {
    wait: 500,
  });

  const resetSearchQuery = () => {
    cancel();
    setSearchQuery('');
  };

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = event => {
    setInputValue(event.target.value);
    debouncedSetSearchQuery(event.target.value.trim());
  };

  const handleInputClear = () => {
    setInputValue('');
    onChange(null);
    cancel();
    setSearchQuery('');
  };

  const handleInputPaste: ClipboardEventHandler<HTMLInputElement> = event => {
    event.preventDefault();

    const clipboardData = event.clipboardData.getData('text');

    setInputValue(clipboardData);
    debouncedSetSearchQuery(clipboardData.trim());
  };

  useUpdateEffect(() => {
    if (inputValue === '') {
      cancel();
      setSearchQuery('');
      onChange(null);
    }
  }, [inputValue]);

  useUpdateEffect(() => {
    setInputValue(getClassificationName(value));
    resetSearchQuery();
  }, [value]);

  return {
    isSearchMode,
    inputValue,
    searchQuery,
    resetSearchQuery,
    handleInputChange,
    handleInputClear,
    handleInputPaste,
  };
};
