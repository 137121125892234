import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { DrugsApi, DrugsSearchParams } from '@/api/domains/drugs.api';
import { DrugsQueryKeys } from '@/api/domains/drugs.query-keys';

// ----------------------------------------------------------------------

export const useDrugs = (
  params: DrugsSearchParams,
  queryOptions?: Pick<UseQueryOptions, 'enabled' | 'keepPreviousData'>,
) => {
  const { isInitialLoading, data } = useQuery({
    queryKey: DrugsQueryKeys.searchDrugs({ query: params.query, marketing_org_ids: params.marketing_org_ids }),
    queryFn: () => DrugsApi.searchDrugs(params),
    staleTime: Infinity,
    enabled: !!params?.query,
    ...queryOptions,
  });

  const drugs = data?.items ?? [];

  return { isInitialLoading, drugs };
};
