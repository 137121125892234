import {
  DatePicker as MuiDatePicker,
  type DatePickerProps as MuiDatePickerProps,
  LocalizationProvider,
  type PickerValidDate,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ru from 'date-fns/locale/ru';

export type DatePickerProps<TDate extends PickerValidDate> = MuiDatePickerProps<TDate>;

export const DatePicker = <TDate extends PickerValidDate>(props: DatePickerProps<TDate>) => (
  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ru}>
    <MuiDatePicker {...props} />
  </LocalizationProvider>
);
