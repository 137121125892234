import { Outlet } from 'react-router-dom';

import { Header } from '@/layouts/Header';
import { PageContainer, PageContent } from '@/layouts/Page';
import { RegistryImportLogTabs } from './RegistryImportLogTabs';
import { RegistryImportLogActionBar } from '@/pages/registers';

export const RegistryImportLogLayout = () => {
  return (
    <PageContainer title="Реестры · лог импорта">
      <Header title="Реестры · лог импорта" titleText="Реестры · лог импорта" />

      <RegistryImportLogTabs />

      <PageContent>
        <Outlet />
      </PageContent>

      <RegistryImportLogActionBar />
    </PageContainer>
  );
};
