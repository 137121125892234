import { type ButtonTypeMap, IconButton, type IconButtonProps, Tooltip, type TooltipProps } from '@mui/material';
import { type OverridableComponent } from '@mui/material/OverridableComponent';
import { styled } from '@mui/material/styles';

// ----------------------------------------------------------------------

type Color = Exclude<IconButtonProps['color'], undefined | 'default' | 'inherit'>;

export type ActionIconButtonProps = Omit<IconButtonProps, 'color' | 'title'> & {
  /**
   * @deprecated Do not use this prop.
   */
  color?: Color;
  title?: React.ReactNode;
  TooltipProps?: Omit<TooltipProps, 'title'>;
};

// ----------------------------------------------------------------------

const StyledActionButton = styled(IconButton)(
  {
    width: 34,
    minWidth: 34,
    height: 34,
    minHeight: 34,
    borderRadius: 6,
  },
  ({ theme }) => ({
    color: theme.palette.action.active,
  }),
);

// ----------------------------------------------------------------------

export const ActionIconButton: OverridableComponent<ButtonTypeMap<ActionIconButtonProps>> = (
  props: ActionIconButtonProps,
) => {
  const { title, TooltipProps, ...restOfProps } = props;

  const button = <StyledActionButton {...restOfProps} color="inherit" />;

  if (title) {
    return (
      <Tooltip disableInteractive title={title} {...TooltipProps} TransitionProps={{ exit: false }}>
        <span>{button}</span>
      </Tooltip>
    );
  }

  return button;
};
