import { Button, buttonClasses, type ButtonProps, type ButtonTypeMap, svgIconClasses } from '@mui/material';
import type { OverridableComponent } from '@mui/material/OverridableComponent';
import { styled } from '@mui/material/styles';
import type { Assign } from 'utility-types';

export type ActionButtonProps = Assign<
  Omit<ButtonProps, 'variant'>,
  {
    /**
     * @deprecated Do not use this prop.
     */
    color?: ButtonProps['color'];
  }
> & {
  isSelected?: boolean;
};

type StyledRootProps = {
  isSelected?: boolean;
};
const StyledRoot = styled(Button, {
  shouldForwardProp: prop => prop !== 'isSelected',
})<StyledRootProps>(({ theme, isSelected, disabled }) => ({
  '&:not(.Mui-disabled)': {
    [`.${svgIconClasses.root}`]: {
      '&, &:hover, &:focus': {
        color: theme.palette.action.active,
      },
    },
  },

  '&:not(:hover)': {
    backgroundColor: 'transparent',
  },

  variants: [
    {
      props: { variant: 'outlined' },
      style: {
        [`&.${buttonClasses.outlined}`]: {
          justifyContent: 'space-between',
          color: theme.palette.neutral['900'],
          borderColor: isSelected && !disabled ? theme.palette.primary['+2'] : theme.palette.neutral['500'],
          backgroundColor: isSelected && !disabled ? theme.palette.primary['+2'] : theme.palette.neutral['100'],

          [`.${svgIconClasses.root}`]: {
            width: 18,
            height: 18,
            color: theme.palette.neutral['700'],
          },
        },

        [`&.${buttonClasses.outlined}:hover`]: {
          borderColor: isSelected && !disabled ? theme.palette.primary['+1'] : theme.palette.neutral['500'],
          backgroundColor: isSelected ? theme.palette.primary['+1'] : theme.palette.neutral['200'],
        },

        [`&.${buttonClasses.disabled}`]: {
          color: theme.palette.neutral['500'],
          backgroundColor: isSelected ? theme.palette.neutral[300] : theme.palette.neutral['100'],

          [`.${svgIconClasses.root}`]: {
            color: theme.palette.neutral['500'],
          },
        },
      },
    },
  ],
})) as typeof Button;

export const ActionButton: OverridableComponent<ButtonTypeMap<ActionButtonProps>> = (props: ActionButtonProps) => (
  <StyledRoot variant="outlined" {...props} color="inherit" />
);
