import { devtools } from 'zustand/middleware';
import { createStoreWithReset } from '@/libs/zustand';
import { GalleryImageInfo } from './types';

type GalleryStore = {
  selectedImage: GalleryImageInfo | null;
  editedFile: File | null;

  isDeleteDialogOpen: boolean;
  isEditDialogOpen: boolean;

  actions: {
    setSelectedImage: (image: GalleryImageInfo) => void;
    resetSelectedImage: VoidFunction;

    openDeleteDialog: VoidFunction;
    closeDeleteDialog: VoidFunction;

    openEditDialog: (file: File) => void;
    closeEditDialog: VoidFunction;
  };
};

export const [useGalleryStore, resetGalleryStore] = createStoreWithReset<GalleryStore>()(
  devtools(
    set => ({
      selectedImage: null,
      editedFile: null,

      isDeleteDialogOpen: false,
      isEditDialogOpen: false,

      actions: {
        setSelectedImage: image => set({ selectedImage: image }, false, 'setSelectedImage'),
        resetSelectedImage: () => set({ selectedImage: null }, false, 'resetSelectedImage'),

        openDeleteDialog: () => set({ isDeleteDialogOpen: true }, false, 'openDeleteDialog'),
        closeDeleteDialog: () => set({ isDeleteDialogOpen: false }, false, 'closeDeleteDialog'),

        openEditDialog: file => set({ isEditDialogOpen: true, editedFile: file }, false, 'openEditDialog'),
        closeEditDialog: () => set({ isEditDialogOpen: false, editedFile: null }, false, 'closeEditDialog'),
      },
    }),
    {
      name: 'gallery-store',
      enabled: process.env.NODE_ENV !== 'production',
    },
  ),
);

export const useGalleryStoreActions = () => useGalleryStore(state => state.actions);

export const useGalleryStoreIsContextWindowsOpen = () =>
  useGalleryStore(state => ({
    isDeleteDialogOpen: state.isDeleteDialogOpen,
    isEditDialogOpen: state.isEditDialogOpen,
  }));

export const useGalleryStoreSelectedImage = () => useGalleryStore(state => state.selectedImage);
export const useGalleryStoreEditedFile = () => useGalleryStore(state => state.editedFile);
