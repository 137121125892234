import { forwardRef } from 'react';
import { Stack, type StackProps } from '@mui/material';
import { styled } from '@mui/material/styles';

type StyledProps = {
  isRoot?: boolean;
  isExpandable?: boolean;
};

export type TreeViewProps = StackProps<'ul'> & StyledProps;

const StyledRoot = styled(Stack, {
  shouldForwardProp: propName => propName !== 'isRoot' && propName !== 'isExpandable',
})<StyledProps>(
  {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  ({ isRoot, isExpandable, theme }) =>
    isRoot && {
      paddingLeft: isExpandable ? 0 : theme.spacing(2),
      ':not(:has(> .TreeViewItem > .TreeViewItem-content > .TreeViewItem-icon))': {
        '> .TreeViewItem': {
          paddingLeft: 0,

          '> .TreeViewItem-content': {
            paddingLeft: 0,
            marginLeft: 0,
          },
        },
      },
    },
) as typeof Stack;

export const TreeView = forwardRef<HTMLUListElement, TreeViewProps>((props, ref) => (
  <StyledRoot ref={ref} {...props} component="ul" spacing={1} className="TreeView" />
));

if (process.env.NODE_ENV !== 'production') {
  TreeView.displayName = 'TreeView';
}
