import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: 28,
  padding: theme.spacing(0, 1),
  borderRadius: theme.spacing(0.5),
  backgroundColor: theme.palette.neutral['100'],
}));

export type InputLabelProps = React.PropsWithChildren;

export const InputLabel = (props: InputLabelProps) => (
  <StyledRoot className="InputLabel">
    <Typography component="div" variant="subtitle2" color="neutral.500">
      {props.children}
    </Typography>
  </StyledRoot>
);
