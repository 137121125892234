import { SvgIcon } from '@mui/material';

import { ReactComponent as DeleteSVG } from '@/assets/icons-2.0/delete.svg';
import {
  ActionButton,
  type ActionButtonProps,
  ActionIconButton,
  type ActionIconButtonProps,
  MainActionButton,
  type MainActionButtonProps,
} from '@/components/buttons';

export type DeleteMainActionButtonProps = MainActionButtonProps;

export const DeleteMainActionButton = (props: DeleteMainActionButtonProps) => {
  const { children = 'Удалить' } = props;

  return (
    <MainActionButton startIcon={<SvgIcon component={DeleteSVG} inheritViewBox fontSize="small" />} {...props}>
      {children}
    </MainActionButton>
  );
};

export type DeleteActionButtonProps = ActionButtonProps;

export const DeleteActionButton = (props: DeleteActionButtonProps) => {
  const { children = 'Удалить' } = props;

  return (
    <ActionButton startIcon={<SvgIcon component={DeleteSVG} inheritViewBox fontSize="small" />} {...props}>
      {children}
    </ActionButton>
  );
};

export type DeleteActionIconButtonProps = ActionIconButtonProps;

export const DeleteActionIconButton = (props: DeleteActionIconButtonProps) => (
  <ActionIconButton title="Удалить" {...props}>
    <SvgIcon component={DeleteSVG} inheritViewBox fontSize="medium" />
  </ActionIconButton>
);
