import { getPalette } from '@/theme/palette.theme';

// ----------------------------------------------------------------------

declare module '@mui/system/createTheme/shape' {
  export interface Shape {
    borderRadius: number;
    borderRadius2: number;
    divider: string;
    dashedDivider: string;
  }
}

// ----------------------------------------------------------------------

const themePalette = getPalette('light');

export const shape = {
  borderRadius: 8,
  borderRadius2: 16,
  divider: `1px solid ${themePalette.divider}`,
  dashedDivider: `1px dashed ${themePalette.divider}`,
} as const;
