import { useState } from 'react';
import { useDebounce } from 'react-use';
import { Autocomplete, AutocompleteProps, Box } from '@mui/material';
import { Inn } from '@/api/domains/inn.type';
import { highlightKeyword } from '@/helpers/format.helpers';
import { useInns } from '@/hooks/useInns';

type LimitedAutocompleteProps = Omit<
  AutocompleteProps<Inn, false, false, false>,
  'freeSolo' | 'autoComplete' | 'includeInputInList' | 'filterSelectedOptions' | 'loading' | 'noOptionsText' | 'options'
>;

export type InnFieldProps = LimitedAutocompleteProps & {
  isSingleComponentInns?: boolean;
};

const INNS_QUERY_LIMIT = 1000;

export const InnField = (props: InnFieldProps) => {
  const { isSingleComponentInns, ...restOfAutocompleteProps } = props;
  const [inputValue, setInputValue] = useState('');
  const [searchQuery, setSearchQuery] = useState('');

  useDebounce(() => setSearchQuery(inputValue), 500, [inputValue]);

  const { isFetching, inns } = useInns(
    { query: searchQuery, limit: INNS_QUERY_LIMIT, with_one_element: isSingleComponentInns },
    { enabled: searchQuery.length >= 2 },
  );

  const handleInputChange = (_event: React.SyntheticEvent, inputValue: string) => {
    setInputValue(inputValue);
  };

  return (
    <Autocomplete
      {...restOfAutocompleteProps}
      autoComplete
      includeInputInList
      filterSelectedOptions
      loading={isFetching}
      options={inns}
      getOptionLabel={option => (typeof option === 'string' ? option : option.name)}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onInputChange={handleInputChange}
      renderOption={(optionProps, option) => (
        <Box
          component="li"
          {...optionProps}
          sx={{
            display: 'block !important',
            margin: 0,
            padding: 0,
          }}
        >
          {highlightKeyword(option.name, searchQuery)}
        </Box>
      )}
      componentsProps={{
        paper: {
          sx: {
            width: inns.length > 0 ? 'fit-content' : '100%',
          },
        },
      }}
    />
  );
};
