import { httpClient } from '@/libs/axios/http-client';
import { ImageInfo } from './image.types';

/**
 * - `drugs` - Товары
 * - `avatars` - Аватар профиля
 * - `logos` - Лого
 * - `makers` - Лого производителя
 **/
export type ImagePrefix = 'drugs' | 'avatars' | 'logos' | 'makers';

export type UploadImageParams = {
  file: File;
  prefix: ImagePrefix;
};

export const ImageApi = {
  uploadImage: async (params: UploadImageParams) => {
    const { file, prefix } = params;

    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('prefix', prefix);

    const { data } = await httpClient.post<ImageInfo>('image', formData, {
      headers: {
        'Content-Type': `multipart/form-data;`,
      },
    });

    return data;
  },
};
