import { useState } from 'react';
import { ChevronRight as ChevronRightIcon, ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { CircularProgress, Collapse } from '@mui/material';
import type { Classification } from '@/api/domains/classifications.types';
import { useClassifications } from './hooks/useClassifications';
import { TreeView, TreeViewItem, TreeViewItemContent, TreeViewItemIcon, TreeViewItemText } from './TreeView';
import type { ClassificationAliasProp } from './types';
import { getClassificationName } from './utils/getClassificationName';

type ClassificationFiledTreeItemProps = ClassificationAliasProp & {
  value: Classification;
  selectedValue: Classification | null;
  onSelect: (value: Classification | null) => void;
  isParentSelectable?: boolean;
};

export const ClassificationFiledTreeItem = (props: ClassificationFiledTreeItemProps) => {
  const { value, selectedValue, alias, onSelect, isParentSelectable } = props;
  const { has_children: hasChildren, id: parentId } = value;

  const [isExpandedState, setIsExpanded] = useState(false);
  const isExpanded = isExpandedState && hasChildren;

  const { isFetching, classifications } = useClassifications({
    alias,
    parentId,
    enabled: isExpanded,
  });

  const toggleExpand = () => setIsExpanded(isExpanded => !isExpanded);

  const handleSelect = () => onSelect(value);

  const handleItemTextClick = () => {
    if (isParentSelectable || !hasChildren) {
      handleSelect();
    } else {
      toggleExpand();
    }
  };

  const isSelected = value.id === selectedValue?.id;

  return (
    <TreeViewItem>
      <TreeViewItemContent isExpanded={isExpanded} isSelected={isSelected}>
        {hasChildren && (
          <TreeViewItemIcon disabled={isFetching} onClick={toggleExpand}>
            {isFetching && <CircularProgress color="secondary" size={24} />}
            {!isFetching && !isExpanded && <ChevronRightIcon width={24} height={24} />}
            {!isFetching && isExpanded && <ExpandMoreIcon width={24} height={24} />}
          </TreeViewItemIcon>
        )}
        <TreeViewItemText onClick={handleItemTextClick}>{getClassificationName(value)}</TreeViewItemText>
      </TreeViewItemContent>

      <Collapse unmountOnExit in={isExpanded && !isFetching}>
        <TreeView>
          {classifications.map(classification => (
            <ClassificationFiledTreeItem
              key={classification.id}
              alias={alias}
              value={classification}
              selectedValue={selectedValue}
              onSelect={onSelect}
              isParentSelectable={isParentSelectable}
            />
          ))}
        </TreeView>
      </Collapse>
    </TreeViewItem>
  );
};
