import { ActionIconButtonProps } from '@/components/buttons';
import { DataGridActionEdit } from '@/components/DataGrid';
import { useMakersStoreActions, useMakersStoreIsContextWindowsVisible } from '@/components/makers/useMakersStore';
import { useShortcut } from '@/hooks/useShortcut';

// ----------------------------------------------------------------------

export const MakersMainEditButton = (props: ActionIconButtonProps) => {
  const { disabled } = props;

  const isContextWindowsVisible = useMakersStoreIsContextWindowsVisible();
  const canOpenEditModal = !disabled && !isContextWindowsVisible;

  const { openEditModal } = useMakersStoreActions();

  useShortcut(
    'ctrl + e',
    () => {
      if (canOpenEditModal) {
        openEditModal();
      }
    },
    [canOpenEditModal],
  );

  return <DataGridActionEdit {...props} onClick={openEditModal} />;
};

// ----------------------------------------------------------------------

export const MakersInlineEditButton = (props: ActionIconButtonProps) => {
  const { onClick } = props;

  const { openEditModal } = useMakersStoreActions();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onClick?.(event);
    openEditModal();
  };

  return <DataGridActionEdit {...props} onClick={handleClick} />;
};
