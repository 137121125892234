import { useMutation, useQueryClient } from '@tanstack/react-query';
import { HttpStatusCode } from 'axios';

import { MakersApi } from '@/api/domains/makers.api';
import { MakersQueryKeys } from '@/api/domains/makers.query-keys';
import {
  ConfirmationDeleteDialog,
  ConfirmationDialogBodyCard,
  ConfirmationDialogBodyMutedText,
  ConfirmationDialogBodyText,
  useGlobalErrorStore,
} from '@/components/dialogs';
import {
  selectMakersStoreSelectedRow,
  useMakersStore,
  useMakersStoreActions,
  useMakersStoreContextWindowsState,
} from '@/components/makers/useMakersStore';
import { useDeleteControlDrugsDialogStore } from '@/components/dialogs/DeleteControlDrugsDialog';
import { useDeleteControlFetchDrugs } from '@/hooks/useDeleteControlFetchDrugs';
import { isAxiosErrorWithStatus } from '@/libs/axios/helpers';

// ----------------------------------------------------------------------

export const MakersDeleteDialog = () => {
  const { closeDeleteDialog, resetSelectedRow, closeEditModal } = useMakersStoreActions();
  const { isDeleteDialogVisible, isEditModalVisible } = useMakersStoreContextWindowsState();
  const maker = useMakersStore(selectMakersStoreSelectedRow);
  const { setDeleteControlDrugs } = useDeleteControlDrugsDialogStore();
  const { isLoading: isDeletedConflictDrugsLoading, mutateAsync: getDeletedConflictDrugs } =
    useDeleteControlFetchDrugs();

  // ----------------------------------------------------------------------

  const { setGlobalError } = useGlobalErrorStore();

  const queryClient = useQueryClient();
  const { isLoading, mutate: deleteMaker } = useMutation({
    mutationFn: async () => {
      if (maker) {
        return MakersApi.deleteMaker({ ids: [maker.id] });
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(MakersQueryKeys.root);

      closeDeleteDialog();
      resetSelectedRow();

      if (isEditModalVisible) {
        closeEditModal();
      }
    },
    onError: async error => {
      if (isAxiosErrorWithStatus(HttpStatusCode.NotAcceptable, error)) {
        if (maker) {
          const data = await getDeletedConflictDrugs({ maker_ids: String([maker.id]), limit: 50 });
          const drugs = data.items ?? null;
          setDeleteControlDrugs({ drugs, count: data.count });
          closeDeleteDialog();
        }
      } else {
        setGlobalError(error);
      }
    },
  });

  const handleProceed = () => deleteMaker();

  return (
    <ConfirmationDeleteDialog
      open={isDeleteDialogVisible}
      isLoading={isLoading || isDeletedConflictDrugsLoading}
      onProceed={handleProceed}
      onClose={closeDeleteDialog}
    >
      {maker && (
        <ConfirmationDialogBodyCard>
          <ConfirmationDialogBodyText>{maker.name}</ConfirmationDialogBodyText>
          <ConfirmationDialogBodyMutedText>{maker.country_name}</ConfirmationDialogBodyMutedText>
        </ConfirmationDialogBodyCard>
      )}
    </ConfirmationDeleteDialog>
  );
};
