import {
  CalendarMonthOutlined as CalendarMonthOutlinedIcon,
  Person2Outlined as Person2OutlinedIcon,
} from '@mui/icons-material';

import { formatDateTime } from '@/helpers/format.helpers';
import { ModalInfo } from '@/components/Modal';
import { DEFAULT_DATE_FORMAT, DEFAULT_FULL_DATE_TIME_FORMAT } from '@/constants/date-formats.constants';

type ModalInfoCreateProps = {
  authorName?: string;
};

export const ModalInfoCreate = (props: ModalInfoCreateProps) => {
  const { authorName } = props;

  return (
    <ModalInfo
      title="Создание"
      data={[
        {
          icon: <CalendarMonthOutlinedIcon fontSize="small" />,
          label: 'Дата',
          value: formatDateTime(new Date(), DEFAULT_DATE_FORMAT),
        },
        {
          icon: <Person2OutlinedIcon fontSize="small" />,
          label: 'Автор',
          value: authorName,
        },
      ]}
    />
  );
};

type ModalInfoEditCreatedProps = {
  createdAt?: string;
  authorName?: string;
};

export const ModalInfoEditCreated = (props: ModalInfoEditCreatedProps) => {
  const { createdAt, authorName } = props;

  return (
    <ModalInfo
      title="Создание"
      data={[
        {
          icon: <CalendarMonthOutlinedIcon fontSize="small" />,
          label: 'Дата',
          value: createdAt ? formatDateTime(createdAt, DEFAULT_FULL_DATE_TIME_FORMAT) : '',
        },
        {
          icon: <Person2OutlinedIcon fontSize="small" />,
          label: 'Автор',
          value: authorName,
        },
      ]}
    />
  );
};

type ModalInfoEditUpdatedProps = {
  updatedAt?: string;
  initiatorName?: string;
};

export const ModalInfoEditUpdated = (props: ModalInfoEditUpdatedProps) => {
  const { updatedAt, initiatorName } = props;

  return (
    <ModalInfo
      title="Последнее изменение"
      data={[
        {
          icon: <CalendarMonthOutlinedIcon fontSize="small" />,
          label: 'Дата',
          value: updatedAt ? formatDateTime(updatedAt, DEFAULT_FULL_DATE_TIME_FORMAT) : '',
        },
        {
          icon: <Person2OutlinedIcon fontSize="small" />,
          label: 'Инициатор',
          value: initiatorName,
        },
      ]}
    />
  );
};
