import { Controller, useFormContext } from 'react-hook-form';
import { TextField, TextFieldProps } from '@mui/material';
import { omit } from 'lodash';

import { DrugField, DrugFieldProps } from '@/components/form-controls/DrugField';

// ----------------------------------------------------------------------

export type RHFDrugFieldProps = Omit<DrugFieldProps, 'renderInput'> & {
  name: string;
  label?: string;
  helperText?: React.ReactNode;
  TextFieldProps?: Omit<TextFieldProps, 'label' | 'error' | 'helperText'>;
};

// ----------------------------------------------------------------------

export const RHFDrugField = (props: RHFDrugFieldProps) => {
  const {
    name,
    label,
    helperText,
    TextFieldProps,
    forSpecificationTemplate = false,
    marketingOrgIds,
    ...restOfProps
  } = props;
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: fieldProps, fieldState: { error } }) => {
        const { onChange, ...restOfFieldProps } = omit(fieldProps, ['ref']);

        const reassignedOnChange: DrugFieldProps['onChange'] = (_, value) => {
          onChange(value);
        };

        return (
          <DrugField
            {...restOfFieldProps}
            forSpecificationTemplate={forSpecificationTemplate}
            marketingOrgIds={marketingOrgIds}
            onChange={reassignedOnChange}
            renderInput={inputParams => (
              <TextField
                error={!!error}
                label={label}
                placeholder="Поиск"
                helperText={error ? error?.message : helperText}
                {...TextFieldProps}
                {...inputParams}
              />
            )}
            {...restOfProps}
          />
        );
      }}
    />
  );
};
