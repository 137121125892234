import { Stack, StackProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactComponent as CloseSVG } from '@/assets/icons/minimal/ic_close.svg';
import { ModalButton } from '@/components/Modal/ModalButton';

// ----------------------------------------------------------------------

export type ModalHeaderProps = Omit<StackProps, 'direction'> & {
  onClose?: VoidFunction;
};

// ----------------------------------------------------------------------

const StyledRoot = styled(Stack)(({ theme }) => ({
  flexShrink: 0,
  alignItems: 'center',
  padding: theme.spacing(0, 1.5),
  height: theme.dimensions.modal.header.height,
  backgroundColor: theme.palette.background.neutral,
  borderBottom: `1px solid ${theme.palette.divider}`,
})) as typeof Stack;

const StyledTimesRoot = styled('div')({
  marginLeft: 'auto',
});

// ----------------------------------------------------------------------

export const ModalHeader = (props: ModalHeaderProps) => {
  const { children, onClose, ...restOfProps } = props;

  return (
    <StyledRoot direction="row" spacing={1} {...restOfProps}>
      {children}
      <StyledTimesRoot>
        <ModalButton onClick={onClose} data-testid="modal-header-close-btn">
          <CloseSVG width="20" height="20" />
        </ModalButton>
      </StyledTimesRoot>
    </StyledRoot>
  );
};
