import { generatePath, joinPath, ExtractPathParams } from '@/libs/react-router';

const ROOT_PATH = '/makers';
const DEDUPLICATION_PATH = joinPath(ROOT_PATH, 'deduplication/:makerId');

export type MakersDeduplicationPathParams = ExtractPathParams<typeof DEDUPLICATION_PATH>;

export const MakersPaths = {
  ROOT_PATH,
  DEDUPLICATION_PATH,

  generateDeduplicationPath: <TParams extends MakersDeduplicationPathParams>(params: TParams) =>
    generatePath(DEDUPLICATION_PATH, params),
} as const;
