import { SvgIcon } from '@mui/material';
import { ActionIconButton, type ActionIconButtonProps } from '@/components/buttons';
import { ReactComponent as BindIcon } from '@/assets/icons-2.0/link.svg';

export type BindActionIconButtonProps = Omit<ActionIconButtonProps, 'children'>;

export const BindActionIconButton = (props: BindActionIconButtonProps) => (
  <ActionIconButton title="Привязать" {...props}>
    <SvgIcon component={BindIcon} inheritViewBox />
  </ActionIconButton>
);
