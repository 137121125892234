import { createSortFunction } from '@/components/DataGrid';
import { MakersDataGridRowData } from '@/components/makers/types';

// ----------------------------------------------------------------------

export const prepareSearchQueryFromMakerName = (drugName: string) => {
  const [firstWord, secondWord] = drugName.split(' ');

  if (firstWord.length >= 3 || !secondWord) {
    return firstWord;
  }

  return `${firstWord} ${secondWord}`;
};

// ----------------------------------------------------------------------

export const sortMakers = createSortFunction<MakersDataGridRowData>({
  id: sortBy => sortBy.number(id => id),
  maker: sortBy => sortBy.string(maker => maker),
  country: sortBy => sortBy.string(country => country),
  notes: sortBy => sortBy.string(notes => notes),
  updateDate: sortBy => sortBy.date(updateDate => new Date(updateDate)),
  initiator: sortBy => sortBy.string(initiator => initiator?.name),
  status: sortBy => sortBy.number(statusId => statusId),
});
