import { Add as AddIcon } from '@mui/icons-material';
import {
  ActionButton,
  type ActionButtonProps,
  ActionIconButton,
  type ActionIconButtonProps,
  MainActionButton,
  type MainActionButtonProps,
} from '@/components/buttons';

export type AddMainActionButtonProps = MainActionButtonProps;

export const AddMainActionButton = (props: AddMainActionButtonProps) => {
  const { children = 'Создать' } = props;

  return (
    <MainActionButton startIcon={<AddIcon fontSize="small" />} {...props}>
      {children}
    </MainActionButton>
  );
};

export type AddActionButtonProps = ActionButtonProps;

export const AddActionButton = (props: AddActionButtonProps) => {
  const { children = 'Создать' } = props;

  return (
    <ActionButton startIcon={<AddIcon fontSize="small" />} {...props}>
      {children}
    </ActionButton>
  );
};

export type AddActionIconButtonProps = ActionIconButtonProps;

export const AddActionIconButton = (props: AddActionIconButtonProps) => (
  <ActionIconButton title="Создать" {...props}>
    <AddIcon fontSize="medium" />
  </ActionIconButton>
);
