import { ControlPointDuplicate as ControlPointDuplicateIcon } from '@mui/icons-material';
import {
  ActionButton,
  type ActionButtonProps,
  ActionIconButton,
  type ActionIconButtonProps,
  MainActionButton,
  type MainActionButtonProps,
} from '@/components/buttons';

export type DuplicateMainActionButtonProps = MainActionButtonProps;

export const DuplicateMainActionButton = (props: DuplicateMainActionButtonProps) => {
  const { children = 'Дублировать' } = props;

  return (
    <MainActionButton startIcon={<ControlPointDuplicateIcon fontSize="small" />} {...props}>
      {children}
    </MainActionButton>
  );
};

export type DuplicateActionButtonProps = ActionButtonProps;

export const DuplicateActionButton = (props: DuplicateActionButtonProps) => {
  const { children = 'Дублировать' } = props;

  return (
    <ActionButton startIcon={<ControlPointDuplicateIcon fontSize="small" />} {...props}>
      {children}
    </ActionButton>
  );
};

export type DuplicateActionIconButtonProps = ActionIconButtonProps;

export const DuplicateActionIconButton = (props: DuplicateActionIconButtonProps) => (
  <ActionIconButton title="Дублировать" {...props}>
    <ControlPointDuplicateIcon fontSize="medium" />
  </ActionIconButton>
);
