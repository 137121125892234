import { ReactNode } from 'react';
import { Stack, StackProps, Typography, TypographyProps } from '@mui/material';
import { omit } from 'lodash';

export type HeaderTitleProps = Omit<StackProps, 'component' | 'direction'> &
  TypographyProps & {
    /**
     * @deprecated Do not use this prop
     */
    icon?: ReactNode;
  };

export const HeaderTitle = (props: HeaderTitleProps) => (
  <Typography
    component={Stack}
    direction="row"
    alignItems="center"
    spacing={1}
    mr="auto"
    variant="h5"
    {...omit(props, ['icon'])}
  />
);
