import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

// ----------------------------------------------------------------------

export type GlobalErrorStore = {
  globalError: unknown;

  setGlobalError: (globalError: unknown) => void;
};

// ----------------------------------------------------------------------

export const useGlobalErrorStore = create<GlobalErrorStore>()(
  devtools(set => ({
    globalError: null,

    setGlobalError: (globalError: unknown) => set({ globalError }, false, 'setGlobalError'),
  })),
);

export const useSetGlobalError = () => useGlobalErrorStore(state => state.setGlobalError);
