import { IconButton, type IconButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export type TreeViewItemIconProps = Omit<IconButtonProps, 'className'>;

const StyledRoot = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(0.5),
  left: 0,
  width: theme.spacing(5),
  height: theme.spacing(5),
  padding: 0,
})) as typeof IconButton;

export const TreeViewItemIcon = (props: TreeViewItemIconProps) => (
  <StyledRoot {...props} color="inherit" className="TreeViewItem-icon" />
);
