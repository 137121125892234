import { To } from 'react-router-dom';
import { useMatchLink } from '@/hooks/useMatchLink';

// ----------------------------------------------------------------------

export const useActiveRoute = (link: To) => {
  const match = useMatchLink(link);

  return Boolean(match);
};
