import { Drug } from '@/api/domains/drugs.types';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export type DeleteDrugControlDialogStore = {
  deleteControlDrugs: {
    drugs: Drug[] | null;
    count: number;
  } | null;

  setDeleteControlDrugs: (deleteControlDrugs: DeleteDrugControlDialogStore['deleteControlDrugs']) => void;
};

// ----------------------------------------------------------------------

export const useDeleteControlDrugsDialogStore = create<DeleteDrugControlDialogStore>()(
  devtools(set => ({
    deleteControlDrugs: null,

    setDeleteControlDrugs: (deleteControlDrugs: DeleteDrugControlDialogStore['deleteControlDrugs']) =>
      set({ deleteControlDrugs }, false, 'setDeleteControlDrugs'),
  })),
);
