import { DataGridSelectionParams } from '@/components/DataGrid';
import { MakersDataGridRowData } from '@/components/makers/types';
import { useMakersStoreActions } from '@/components/makers/useMakersStore';

// ----------------------------------------------------------------------

export const useMakersSelection = () => {
  const { setSelectedRow } = useMakersStoreActions();

  const handleSelectionChange = (params: DataGridSelectionParams<MakersDataGridRowData>) => {
    const { data } = params;
    const { originalMaker } = data ?? {};

    if (!originalMaker) {
      return;
    }

    setSelectedRow(originalMaker);
  };

  return { handleSelectionChange };
};
