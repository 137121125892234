import { lazy } from 'react';

const ContractTemplatesPage = lazy(
  () =>
    import(
      /* webpackPreload: true */
      /* webpackChunkName: "ContractTemplatesPage" */
      './ContractTemplatesPage'
    ),
);

export default ContractTemplatesPage;
