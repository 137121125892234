import { forwardRef } from 'react';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { StyledLabel } from '@/components/labels/styles';
import { LabelProps } from '@/components/labels/types';

// ----------------------------------------------------------------------

export const Label = forwardRef<HTMLSpanElement, LabelProps>(
  ({ children, color = 'default', variant = 'soft', startIcon, endIcon, sx, ...other }, ref) => {
    const theme = useTheme();

    return (
      <StyledLabel
        ref={ref}
        component="span"
        ownerState={{ color, variant }}
        sx={{
          ...(startIcon && { pl: 0.75 }),
          ...(endIcon && { pr: 0.75 }),
          ...sx,
        }}
        theme={theme}
        {...other}
      >
        {startIcon && <Box sx={{ mr: 0.75 }}>{startIcon}</Box>}

        {children}

        {endIcon && <Box sx={{ ml: 0.75 }}>{endIcon}</Box>}
      </StyledLabel>
    );
  },
);
