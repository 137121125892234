import { TypeFilterType } from '@inovua/reactdatagrid-community/types';
import ReactDataGrid from '@inovua/reactdatagrid-enterprise';
import { isArray, isNull } from 'lodash';

// ----------------------------------------------------------------------

type SelectByIdInlineOperatorProps = {
  value: { id: number | string } | null;
  filterValue: Array<number | string>;
};

type SelectByNameInlineOperatorProps = {
  value?: { name: string; full_name: string };
  filterValue: Array<number | string>;
};

type SelectByValueInlineOperatorProps = {
  value: string[];
  filterValue: string[] | null;
};

type SelectByPlansInlineOperatorProps = {
  value: Array<{ plan: number; bonus: number }>;
  filterValue: number | string;
};

// ----------------------------------------------------------------------

export const filterTypes = {
  'select-by-id': {
    type: 'select-by-id',
    emptyValue: null,
    operators: [
      {
        name: 'inlist',
        fn: ({ value, filterValue }: SelectByIdInlineOperatorProps) => {
          if (isNull(value)) {
            return false;
          }

          if (!filterValue || filterValue.length === 0) {
            return true;
          }

          return filterValue.includes(value.id);
        },
      },
    ],
  } as TypeFilterType,

  'select-by-name': {
    type: 'select-by-name',
    emptyValue: null,
    operators: [
      {
        name: 'inlist',
        fn: ({ value, filterValue }: SelectByNameInlineOperatorProps) => {
          if (!filterValue || filterValue.length === 0) {
            return true;
          }

          return filterValue.includes(value?.name ?? '');
        },
      },
    ],
  } as TypeFilterType,

  'select-by-full_name': {
    type: 'select-by-full_name',
    emptyValue: null,
    operators: [
      {
        name: 'inlist',
        fn: ({ value, filterValue }: SelectByNameInlineOperatorProps) => {
          if (!filterValue || filterValue.length === 0) {
            return true;
          }

          return filterValue.includes(value?.full_name ?? '');
        },
      },
    ],
  } as TypeFilterType,

  'select-by-value': {
    type: 'select-by-value',
    emptyValue: null,
    operators: [
      {
        name: 'contains',
        fn: ({ value, filterValue }: SelectByValueInlineOperatorProps) => {
          if (!Array.isArray(filterValue) || filterValue.length === 0) {
            return true;
          }

          return value.some(val => filterValue.includes(val));
        },
      },
    ],
  } as TypeFilterType,

  'select-by-bonus': {
    type: 'select-by-bonus',
    emptyValue: null,
    operators: [
      {
        name: 'inlist',
        fn: ({ value, filterValue }: SelectByPlansInlineOperatorProps) => {
          if (!isArray(filterValue) || filterValue.length === 0) {
            return true;
          }

          return value.some(val => filterValue.includes(val.bonus));
        },
      },
    ],
  } as TypeFilterType,

  'select-by-plans': {
    type: 'select-by-plans',
    emptyValue: null,
    operators: [
      {
        name: 'inlist',
        fn: ({ value, filterValue }: SelectByPlansInlineOperatorProps) => {
          if (!isArray(filterValue) || filterValue.length === 0) {
            return true;
          }

          return value.some(val => filterValue.includes(val.plan));
        },
      },
    ],
  } as TypeFilterType,
};

export const customFilterTypes = Object.assign({}, ReactDataGrid.defaultProps.filterTypes, filterTypes);
