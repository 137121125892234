import { paramsToQueryKey } from '@/helpers/transformation.helpers';
import type {
  FetchPlanogramTasksParams,
  FetchPlanogramTaskParams,
  FetchPlanogramControlTasksParams,
} from './planogram.api';

const PREFIX = 'planogram';

export const PlanogramQueryKeys = {
  root: [PREFIX],

  getPlanogramControlTasks: (params?: FetchPlanogramControlTasksParams) =>
    paramsToQueryKey([PREFIX, 'control-tasks'], params),
  getPlanogramTasks: (params?: Partial<FetchPlanogramTasksParams>) => paramsToQueryKey([PREFIX, 'tasks'], params),
  getPlanogramTask: (params?: Partial<FetchPlanogramTaskParams>) => paramsToQueryKey([PREFIX, 'task'], params),
  getPlanogramControlTask: (params?: Partial<FetchPlanogramTaskParams>) =>
    paramsToQueryKey([PREFIX, 'control-task'], params),
};
