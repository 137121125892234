import { Stack, type StackProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export type TreeViewItemProps = StackProps<'li'>;

const StyledRoot = styled(Stack)(({ theme }) => ({
  position: 'relative',
  paddingLeft: theme.spacing(2),
})) as typeof Stack;

export const TreeViewItem = (props: TreeViewItemProps) => (
  <StyledRoot {...props} component="li" spacing={1} className="TreeViewItem" />
);
