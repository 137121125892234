import { Clear } from '@mui/icons-material';
import { IconButton, InputAdornment, InputAdornmentProps } from '@mui/material';

type ClearEndAdornmentProps = InputAdornmentProps & {
  isClearButtonVisible: boolean;
  onClear?: VoidFunction;
};

export const ClearAdornment = ({ isClearButtonVisible, onClear, ...restAdornmentProps }: ClearEndAdornmentProps) => {
  const handleMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  if (!isClearButtonVisible) {
    return null;
  }

  return (
    <InputAdornment {...restAdornmentProps}>
      <IconButton onMouseDown={handleMouseDown} onClick={onClear} size="small">
        <Clear fontSize="small" />
      </IconButton>
    </InputAdornment>
  );
};
