import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useMountedState } from 'react-use';
import { LoadingButton } from '@mui/lab';
import { Button, DialogContent, FormControl, Skeleton, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import { find } from 'lodash';
import { AfterSubmit } from '@/@types/common.types';
import { Country } from '@/api/domains/countries.types';
import { MakerFormData, MakersApi } from '@/api/domains/makers.api';
import { MakersQueryKeys } from '@/api/domains/makers.query-keys';
import { Maker } from '@/api/domains/makers.types';
import { useGlobalErrorStore } from '@/components/dialogs';
import { CustomFormLabel, FormProvider, RHFAutocomplete, RHFTextField } from '@/components/form-controls';
import { MakersModalInfo } from '@/components/makers/modals/MakersModalInfo';
import { MakersModalToolbar } from '@/components/makers/modals/MakersModalToolbar';
import { useMakersStoreActions } from '@/components/makers/useMakersStore';
import { ModalBody, ModalSidebar, ModalSidebarActions, ModalSidebarAdditional } from '@/components/Modal';
import { useCountries } from '@/hooks/useCountries';
import { Yup } from '@/libs/yup';
import { RHFUploadLogo } from '@/components/form-controls';

// ----------------------------------------------------------------------

type MakersFormProps = {
  onClose: VoidFunction;
} & (
  | {
      variant: 'create';
      maker?: never;
    }
  | {
      variant: 'edit' | 'duplicate';
      maker?: Maker | null;
    }
);

type FormValues = AfterSubmit &
  Omit<MakerFormData, 'country_id' | 'country_name'> & {
    country: Country | null;
    logoUrlToken: string;
    logoUrl: string;
    logoFile: File | null;
  };

// ----------------------------------------------------------------------

const StyledFormRoot = styled(Stack)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(0, 2),
  marginLeft: 'auto',
  marginRight: 'auto',
})) as typeof Stack;

// ----------------------------------------------------------------------

const FormSchema = Yup.object().shape({
  name: Yup.string().trim().required('Обязательное поле'),
  country: Yup.object().shape({ id: Yup.number().required() }).nullable().required('Обязательно поле'),
  description: Yup.string(),
});

// ----------------------------------------------------------------------

export const MakersForm = (props: MakersFormProps) => {
  const { variant, maker, onClose } = props;

  const isEditMode = variant === 'edit';
  const isDuplicateMode = variant === 'duplicate';
  const shouldPrefillMakersForm = (isEditMode || isDuplicateMode) && !!maker;

  const methods = useForm<FormValues>({
    mode: 'onSubmit',
    // @ts-ignore
    resolver: yupResolver(FormSchema),
    defaultValues: {
      name: shouldPrefillMakersForm ? maker.name : '',
      country: null,
      description: shouldPrefillMakersForm ? maker.description : '',
      logoUrl: shouldPrefillMakersForm ? maker.logo_url : '',
      logoUrlToken: shouldPrefillMakersForm ? maker.logo_url_token : '',
      logoFile: null,
    },
  });

  const {
    setError,
    reset,
    getValues,
    formState: { isSubmitting, isDirty },
  } = methods;

  // ----------------------------------------------------------------------

  const { isLoading: isLoadingCountries, countries } = useCountries({ limit: 500 });

  useEffect(() => {
    if (shouldPrefillMakersForm) {
      const selectedCountry = find(countries, {
        id: maker.country_id,
      });

      if (selectedCountry) {
        reset({ ...getValues(), country: selectedCountry }, { keepDirty: false });
      }
    }
  }, [shouldPrefillMakersForm, countries, maker, getValues, reset]);

  // ----------------------------------------------------------------------

  const isMounted = useMountedState();
  const queryClient = useQueryClient();
  const { setGlobalError } = useGlobalErrorStore();

  const { openDuplicateDialog } = useMakersStoreActions();

  const handleSubmit = async (formData: FormValues) => {
    try {
      if (isDuplicateMode && !isDirty) {
        openDuplicateDialog();

        return;
      }

      const { name, country, description, logoUrlToken } = formData;

      const preparedFormData: MakerFormData = {
        name,
        country_id: country!.id,
        country_name: country!.name,
        description,
        logo_url_token: logoUrlToken,
      };

      if (isEditMode) {
        if (maker) {
          await MakersApi.updateMaker(maker.id, preparedFormData);
        }
      } else {
        await MakersApi.createMaker(preparedFormData);
      }

      await queryClient.invalidateQueries(MakersQueryKeys.root);

      onClose();
    } catch (error) {
      setGlobalError(error);

      if (isMounted()) {
        // @ts-ignore
        setError('afterSubmit', { ...error, message: error.message });
      }
    }
  };

  // ----------------------------------------------------------------------

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit}>
      <ModalBody
        sidebar={
          <ModalSidebar>
            {isEditMode && <MakersModalInfo maker={maker} />}

            <ModalSidebarAdditional>
              <Typography component="p" variant="caption" color="text.secondary">
                <Typography component="span" variant="inherit" color="error.main">
                  *
                </Typography>{' '}
                - обязательные поля
              </Typography>
            </ModalSidebarAdditional>

            <ModalSidebarActions>
              <Button fullWidth variant="outlined" color="inherit" onClick={onClose}>
                Отмена
              </Button>
              <LoadingButton fullWidth loading={isSubmitting} type="submit" variant="contained" color="secondary">
                Применить
              </LoadingButton>
            </ModalSidebarActions>
          </ModalSidebar>
        }
      >
        <MakersModalToolbar disabled={!isEditMode} />

        <DialogContent>
          <Stack spacing={2} py={2} mt={4} alignItems="center">
            <StyledFormRoot spacing={2}>
              <Stack direction="row" spacing={2}>
                <FormControl fullWidth>
                  <CustomFormLabel isRequired htmlFor="name">
                    Производитель
                  </CustomFormLabel>
                  <RHFTextField fullWidth name="name" id="name" variant="filled" />
                </FormControl>

                <FormControl fullWidth>
                  <CustomFormLabel isRequired htmlFor="country">
                    Страна
                  </CustomFormLabel>
                  {isLoadingCountries && (
                    <Skeleton variant="text" width="100%" height={48} sx={{ transform: 'none' }} />
                  )}
                  {!isLoadingCountries && (
                    <RHFAutocomplete
                      fullWidth
                      name="country"
                      id="country"
                      options={countries}
                      getOptionLabel={option => (typeof option === 'string' ? option : option.name)}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      TextFieldProps={{ variant: 'filled' }}
                    />
                  )}
                </FormControl>
              </Stack>

              <RHFUploadLogo<FormValues>
                prefix="makers"
                fieldNames={{
                  url: 'logoUrl',
                  urlToken: 'logoUrlToken',
                  file: 'logoFile',
                }}
              />

              <FormControl fullWidth>
                <CustomFormLabel htmlFor="description">Примечание</CustomFormLabel>
                <RHFTextField fullWidth name="description" id="description" variant="filled" />
              </FormControl>
            </StyledFormRoot>
          </Stack>
        </DialogContent>
      </ModalBody>
    </FormProvider>
  );
};
