import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import type { Contractor } from '@/api/domains/contractors.types';
import { ContractorsApi, type FetchContractorsParams } from '@/api/domains/contractors.api';
import { ContractorsQueryKeys } from '@/api/domains/contractors.query-keys';
import { useDebouncedCallback } from '@/hooks/useDebouncedCallback';
import { PaginatedList } from '@/api/@types/pagination.types';

type UseContractorsSelectParams = {
  fetchParams?: FetchContractorsParams;
  shouldInitialPreload?: boolean;
  idToExclude?: Contractor['id'];
  select?: (data: PaginatedList<'items', Contractor>) => PaginatedList<'items', Contractor>;
};

const MIN_SEARCH_LENGTH = 2;
const defaultOptions: Contractor[] = [];

export const useContractorsSelect = (params: UseContractorsSelectParams = {}) => {
  const { shouldInitialPreload = false, fetchParams, idToExclude, select } = params;

  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedSetSearchQuery, cancel] = useDebouncedCallback(setSearchQuery, { wait: 300 });

  const requestParams = { ...fetchParams, query: searchQuery.toLocaleLowerCase(), limit: 50 };

  const { isInitialLoading, data } = useQuery({
    queryKey: ContractorsQueryKeys.getUseContractorsSelectKey(requestParams),
    queryFn: () => ContractorsApi.fetchContractors(requestParams),
    enabled: shouldInitialPreload || searchQuery.length >= MIN_SEARCH_LENGTH,
    staleTime: Infinity,
    select,
  });

  const options = data?.items.filter(({ id }) => id !== idToExclude) || defaultOptions;

  const searchFn = (inputValue: string) => {
    cancel();
    debouncedSetSearchQuery(() => inputValue);
  };

  return {
    searchFn,
    options,
    isInitialLoading,
  };
};
