import 'simplebar-react/dist/simplebar.min.css';

import { HelmetProvider } from 'react-helmet-async';
import { GlobalErrorDialog } from '@/components/dialogs';
import { AuthProvider } from '@/contexts/AuthContext';
import { ReactQueryProvider } from '@/contexts/ReactQueryContext';
import { Router } from '@/router';
import { ThemeProvider } from '@/theme';
import { Initialize } from './Initialize';
import { SnackbarProvider } from '@/contexts/SnackbarContext';
import { DeleteControlDrugsDialog } from '@/components/dialogs/DeleteControlDrugsDialog';
import { GlobalSnackbar } from '@/components/GlobalSnackbar';

export const App = () => (
  <HelmetProvider>
    <ThemeProvider>
      <SnackbarProvider>
        <ReactQueryProvider>
          <AuthProvider>
            <Initialize>
              <Router />
            </Initialize>

            <GlobalErrorDialog />
            <GlobalSnackbar />
            <DeleteControlDrugsDialog />
          </AuthProvider>
        </ReactQueryProvider>
      </SnackbarProvider>
    </ThemeProvider>
  </HelmetProvider>
);
