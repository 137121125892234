import { IconButton, IconButtonProps } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

// ----------------------------------------------------------------------

export type ModalButtonProps = IconButtonProps;

// ----------------------------------------------------------------------

const StyledRoot = styled(IconButton)(({ theme }) => ({
  width: theme.spacing(4),
  height: theme.spacing(4),
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
  color: theme.palette.text.secondary,
  borderRadius: theme.shape.borderRadius,

  ':hover': {
    backgroundColor: alpha(theme.palette.grey[500], 0.24),
  },
}));

// ----------------------------------------------------------------------

export const ModalButton = (props: ModalButtonProps) => <StyledRoot {...props} />;
