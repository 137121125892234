import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { getDorimEnvironment } from '@/helpers/common.helpers';
import { ReactComponent as LogoSVG } from '@/assets/icons/logo/logo.svg';
import { LOGO_BG_COLOR_DEV, LOGO_BG_COLOR_STAGE } from '@/layouts/Sidebar/constants';

// ----------------------------------------------------------------------

const { isDev, isStage } = getDorimEnvironment();
const backgroundColor = isDev ? LOGO_BG_COLOR_DEV : isStage ? LOGO_BG_COLOR_STAGE : undefined;

const StyledRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: 80,
  borderBottom: theme.shape.divider,
  backgroundColor,
}));

const StyledLogo = styled(LogoSVG)({
  width: 40,
  height: 40,
});

// ----------------------------------------------------------------------

export const SidebarLogo = () => (
  <StyledRoot>
    <RouterLink to="/">
      <StyledLogo />
    </RouterLink>
  </StyledRoot>
);
