import { useLatest, useUpdateEffect } from 'react-use';
import useKeyboardJs from 'react-use/lib/useKeyboardJs';
import { isFunction } from 'lodash';

export type UseShortcutOptions = {
  isEnable?: boolean | (() => boolean);
  combination: string | Array<string>;
  onPressed: VoidFunction;
};

export const useShortcut = (options: UseShortcutOptions) => {
  const [isPressed, event] = useKeyboardJs(options.combination);

  if (event && !event.defaultPrevented) {
    event.preventDefault();
  }

  const latestOptions = useLatest(options);
  useUpdateEffect(() => {
    const isEnable = isFunction(latestOptions.current.isEnable)
      ? latestOptions.current.isEnable()
      : latestOptions.current.isEnable;

    if (isPressed && isEnable !== false) {
      latestOptions.current.onPressed();
    }
  }, [isPressed]);

  return {
    isPressed,
  };
};
