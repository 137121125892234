import { useEffect, useMemo, useState } from 'react';
import { Box, Card, Collapse, Link, Stack, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import { StatusCodes } from 'http-status-codes';
import { capitalize, map } from 'lodash';
import { ClipboardButton } from '@/components/buttons/ClipboardButton';
import {
  ConfirmationDialogActionProceedButton,
  ConfirmationDialogActions,
  ConfirmationDialogContainer,
  ConfirmationDialogHeader,
} from '@/components/dialogs/elements.confirmation-dialog';
import { useGlobalErrorStore } from '@/components/dialogs/GlobalErrorDialog/useGlobalErrorStore';

// ----------------------------------------------------------------------

type ErrorDetailsProps = {
  url: string;
  status: string | number;
  data: Record<'message', string> | Record<'detail', string>;
};

// ----------------------------------------------------------------------

const ErrorDetails = (props: ErrorDetailsProps) => {
  const { url, status, data } = props;

  // ----------------------------------------------------------------------

  const [areDetailsVisible, setAreDetailsVisible] = useState(false);
  const toggleDetails = () => setAreDetailsVisible(isVisible => !isVisible);

  // ----------------------------------------------------------------------

  const details = useMemo(() => {
    const serializedDetailsEntries = map(
      {
        date: new Date().toLocaleString('ru-RU'),
        url,
        status,
        response: JSON.stringify(data, null, 2),
      },
      (value, key) => `${capitalize(key)}: ${value}`,
    );

    return serializedDetailsEntries.join('\n');
  }, [url, status, data]);

  // ----------------------------------------------------------------------

  return (
    <Stack spacing={1} width="100%">
      <Link component="button" variant="body1" color="secondary" underline="none" onClick={toggleDetails}>
        {!areDetailsVisible ? 'Подробнее' : 'Скрыть'}
      </Link>

      <Collapse in={areDetailsVisible} sx={{ position: 'relative' }}>
        <Card
          component={Stack}
          spacing={0.5}
          sx={{
            width: '100%',
            textAlign: 'left',
            padding: theme => theme.spacing(2, 6, 2, 2),
            backgroundColor: theme => theme.palette.background.neutral,
            position: 'static',
            overflowX: 'auto',
          }}
        >
          <Typography sx={{ whiteSpace: 'pre' }}>{details}</Typography>

          <Box
            sx={{
              position: 'absolute',
              top: theme => theme.spacing(1),
              right: theme => theme.spacing(1),
            }}
          >
            <ClipboardButton valueToCopy={details} />
          </Box>
        </Card>
      </Collapse>
    </Stack>
  );
};

// ----------------------------------------------------------------------

export const GlobalErrorDialog = () => {
  const { globalError, setGlobalError } = useGlobalErrorStore();

  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);
  const handleReset = () => setGlobalError(null);

  useEffect(() => {
    if (globalError) {
      handleOpen();
    }
  }, [globalError]);

  // ----------------------------------------------------------------------

  let responseUrl: ErrorDetailsProps['url'];
  let responseStatus: ErrorDetailsProps['status'];
  let responseData: ErrorDetailsProps['data'];

  if (globalError instanceof AxiosError) {
    const { response } = globalError;

    responseUrl = response?.request?.responseURL ?? 'N/A';
    responseStatus = response?.status ?? StatusCodes.NOT_FOUND;
    responseData = response?.data ?? { message: 'Неизвестная ошибка' };
  } else if (globalError instanceof Error) {
    const { message } = globalError;

    responseUrl = 'N/A';
    responseStatus = 'N/A';
    responseData = { message };
  } else {
    responseUrl = 'N/A';
    responseStatus = 'N/A';
    responseData = { message: 'Неизвестная ошибка' };
  }

  // ----------------------------------------------------------------------

  return (
    <ConfirmationDialogContainer
      open={isOpen}
      subject="error"
      maxWidth="sm"
      onClose={handleClose}
      TransitionProps={{ onExited: handleReset }}
    >
      <ConfirmationDialogHeader whiteSpace="pre-line" sx={{ maxWidth: 350 }}>
        {'message' in responseData ? responseData.message : 'Неизвестная ошибка'}
      </ConfirmationDialogHeader>

      <ErrorDetails url={responseUrl} status={responseStatus} data={responseData} />

      <Typography color="text.secondary">
        Служба поддержки:&nbsp;
        <Link href="tel:+998781137755" color="inherit">
          +998 (78) 113 77 55
        </Link>
      </Typography>

      <ConfirmationDialogActions justifyContent="center">
        <ConfirmationDialogActionProceedButton autoFocus fullWidth={false} onClick={handleClose}>
          Закрыть
        </ConfirmationDialogActionProceedButton>
      </ConfirmationDialogActions>
    </ConfirmationDialogContainer>
  );
};
