import ReactDOM from 'react-dom/client';
import { App } from '@/App';

import { setDefaultOptions } from 'date-fns';
import { ru } from 'date-fns/locale';

setDefaultOptions({ locale: ru });

const rootElement = document.getElementById('root') as HTMLElement;
const root = ReactDOM.createRoot(rootElement);

root.render(<App />);

if (module.hot) {
  module.hot.accept();
}
