import { ProfileQueryKeys } from '@/api/domains/profile-query.keys';
import { ProfileApi } from '@/api/domains/profile.api';
import { Permissions, PermissionsAction, PermissionsStatus } from '@/api/domains/profile.types';
import { useQuery } from '@tanstack/react-query';
import { reduce } from 'lodash';

type UseProfilePermissionsParams<TData> = {
  select?: (permissions: TransformedPermissions) => TData;
};

type Actions = Record<
  PermissionsAction['name'],
  {
    conditions: PermissionsAction['conditions'];
  }
>;

type Statuses = Record<number, PermissionsStatus>;

type TransformedPermissions = Record<
  string,
  {
    actions: Actions;
    statuses: Statuses;
  }
>;

const transformActions = (actions: PermissionsAction[]) =>
  reduce<PermissionsAction, Actions>(
    actions,
    (result, action) => {
      result[action.name] = {
        conditions: action.conditions,
      };
      return result;
    },
    {} as Actions,
  );

const transformStatuses = (statuses: PermissionsStatus[]) =>
  reduce<PermissionsStatus, Statuses>(
    statuses,
    (result, status) => {
      result[status.id] = status;
      return result;
    },
    {},
  );

const transformPermissions = (permissions: Permissions[]) =>
  reduce<Permissions, TransformedPermissions>(
    permissions,
    (result, permission) => {
      result[permission.name] = {
        actions: transformActions(permission.actions),
        statuses: transformStatuses(permission.statuses),
      };
      return result;
    },
    {},
  );

export const useProfilePermissions = <TData = TransformedPermissions | undefined>(
  params?: UseProfilePermissionsParams<TData>,
) =>
  useQuery({
    queryKey: ProfileQueryKeys.permissions,
    queryFn: async () => {
      const permissions = await ProfileApi.fetchProfilePermissions();

      return transformPermissions(permissions.entities);
    },
    staleTime: Infinity,
    select: params?.select,
  });
