import { Stack } from '@mui/material';
import { DataGridToolbar } from '@/components/DataGrid';
import {
  MakersCreateButton,
  MakersDeduplicateButton,
  MakersFilterButton,
  MakersMainDeleteButton,
  MakersMainDuplicateButton,
  MakersMainEditButton,
} from '@/components/makers/actions';
import { selectMakersStoreSelectedRow, useMakersStore } from '@/components/makers/useMakersStore';

// ----------------------------------------------------------------------

const SelectedRowActions = (props: { isAnyRowSelected: boolean }) => {
  const { isAnyRowSelected } = props;

  return (
    <Stack direction="row" spacing={0.5}>
      <MakersMainDuplicateButton disabled={!isAnyRowSelected} />
      <MakersMainEditButton disabled={!isAnyRowSelected} />
      <MakersMainDeleteButton disabled={!isAnyRowSelected} />
    </Stack>
  );
};

// ----------------------------------------------------------------------

export const MakersMainToolbar = () => {
  const selectedRow = useMakersStore(selectMakersStoreSelectedRow);
  const isAnyRowSelected = !!selectedRow;

  return (
    <DataGridToolbar actions={<MakersFilterButton />}>
      <Stack direction="row" spacing={2}>
        <MakersCreateButton />
        <SelectedRowActions isAnyRowSelected={isAnyRowSelected} />

        <MakersDeduplicateButton disabled={!isAnyRowSelected} selectedRow={selectedRow} />
      </Stack>
    </DataGridToolbar>
  );
};
