import { lazy } from 'react';

// ----------------------------------------------------------------------

const SignInPage = lazy(
  () =>
    import(
      /* webpackPreload: true */
      /* webpackChunkName: "SignInPage" */
      '@/pages/auth/sign-in/SignInPage'
    ),
);

export default SignInPage;
