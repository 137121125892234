import { Stack, type StackProps, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { Assign } from 'utility-types';

const StyledRoot = styled(Stack)(({ theme }) => ({
  '&:not(:last-of-type)': {
    marginBottom: theme.spacing(5),
  },
}));

export type ModalBodyContentSectionProps = Assign<
  StackProps,
  {
    title: string;
  }
>;

export const ModalBodyContentSection = (props: ModalBodyContentSectionProps) => {
  const { title, children, ...restProps } = props;

  return (
    <StyledRoot spacing={3} {...restProps}>
      <Typography component="div" variant="subtitle1" mb={2}>
        {title}
      </Typography>
      {children}
    </StyledRoot>
  );
};
