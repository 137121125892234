type ImageDimensions = {
  width: number;
  height: number;
};

export const getImageDimensions = (image: File | string) =>
  new Promise<ImageDimensions>((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve({ width: img.width, height: img.height });
    };
    img.onerror = () => {
      reject();
    };

    img.src = image instanceof File ? URL.createObjectURL(image) : image;
  });

export const getCanvasSize = async (imageFile: File | string, maxWidth: number, maxHeight: number) => {
  const { width: imageWidth, height: imageHeight } = await getImageDimensions(imageFile);

  let width = 0;
  let height = 0;

  const isVertical = imageHeight >= imageWidth;

  if (isVertical) {
    height = maxHeight;
    width = (imageWidth / imageHeight) * maxHeight;
  } else {
    width = maxWidth;
    height = (imageHeight / imageWidth) * maxWidth;
  }

  return {
    width,
    height,
  };
};
