import { TFilterItem } from '@/components/DataGrid';
import { compareString } from '@/helpers/grid-filter.helpers';
import { PromoContractDataGridRowData } from './types';

export type FilterData = {
  specificationNumberFilterData: TFilterItem<string>[];
  contractDocumentNumberFilterData: TFilterItem<string>[];
  organizationNameFilterData: TFilterItem<string>[];
  contractTemplateFilterData: TFilterItem<string>[];
  tinFilterData: TFilterItem[];
  statusFilterData: TFilterItem[];
  paymentSumFilterData: TFilterItem[];
  marketingOrgsFilterData: TFilterItem<string>[];
};

type GetFilterDataParams = {
  items: PromoContractDataGridRowData[];
};

export const initialPromoContractsFilterData: FilterData = {
  specificationNumberFilterData: [],
  organizationNameFilterData: [],
  contractTemplateFilterData: [],
  tinFilterData: [],
  statusFilterData: [],
  paymentSumFilterData: [],
  marketingOrgsFilterData: [],
  contractDocumentNumberFilterData: [],
};

export const getFilterData = ({ items }: GetFilterDataParams): FilterData => {
  const specificationNumberFilterData = new Map();
  const contractDocumentNumberFilterData = new Map();
  const organizationNameFilterData = new Map();
  const contractTemplateFilterData = new Map();
  const tinFilterData = new Map();
  const statusFilterData = new Map();
  const paymentSumFilterData = new Map();
  const marketingOrgsFilterData = new Map();

  items.forEach(item => {
    const {
      templateName,
      recordStatus,
      tin,
      contractDocumentNumber,
      organizationName,
      specificationNumber,
      recordStatusId,
      paymentSum,
      marketingOrgs,
    } = item;

    if (!specificationNumberFilterData.has(specificationNumber)) {
      specificationNumberFilterData.set(specificationNumber, { id: specificationNumber, label: specificationNumber });
    }

    if (!contractDocumentNumberFilterData.has(contractDocumentNumber)) {
      contractDocumentNumberFilterData.set(contractDocumentNumber, {
        id: contractDocumentNumber,
        label: contractDocumentNumber,
      });
    }

    if (organizationName && !organizationNameFilterData.has(organizationName)) {
      organizationNameFilterData.set(organizationName, { id: organizationName, label: organizationName });
    }

    if (!contractTemplateFilterData.has(templateName)) {
      contractTemplateFilterData.set(templateName, { id: templateName, label: templateName });
    }

    if (tin && !tinFilterData.has(tin)) {
      tinFilterData.set(tin, { id: tin, label: String(tin) });
    }

    if (!statusFilterData.has(recordStatusId)) {
      statusFilterData.set(recordStatusId, {
        id: recordStatusId,
        label: recordStatus.name,
      });
    }

    if (!paymentSumFilterData.has(paymentSum)) {
      paymentSumFilterData.set(paymentSum, { id: paymentSum, label: String(paymentSum) });
    }

    // MARKETING ORG DATA
    marketingOrgs.forEach(marketingOrg => {
      if (!marketingOrgsFilterData.has(marketingOrg)) {
        marketingOrgsFilterData.set(marketingOrg, {
          id: marketingOrg,
          label: marketingOrg,
        });
      }
    });
  });

  const filterData = {
    specificationNumberFilterData: Array.from(specificationNumberFilterData.values()).sort(compareString),
    contractDocumentNumberFilterData: Array.from(contractDocumentNumberFilterData.values()).sort(compareString),
    organizationNameFilterData: Array.from(organizationNameFilterData.values()).sort(compareString),
    contractTemplateFilterData: Array.from(contractTemplateFilterData.values()).sort(compareString),
    tinFilterData: Array.from(tinFilterData.values()).sort(compareString),
    statusFilterData: Array.from(statusFilterData.values()).sort(compareString),
    paymentSumFilterData: Array.from(paymentSumFilterData.values()).sort(compareString),
    marketingOrgsFilterData: Array.from(marketingOrgsFilterData.values()).sort(compareString),
  };

  return filterData;
};
