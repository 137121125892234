import { ReactComponent as EcommerceSVG } from '@/assets/icons/navbar/ic_ecommerce.svg';
import { MakersForm } from '@/components/makers/modals/MakersForm';
import {
  selectMakersStoreSelectedRow,
  useMakersStore,
  useMakersStoreActions,
  useMakersStoreContextWindowsState,
} from '@/components/makers/useMakersStore';
import { Modal, ModalBreadcrumbs, ModalHeader, ModalTabbar } from '@/components/Modal';

// ----------------------------------------------------------------------

export const MakersDuplicateModal = () => {
  const maker = useMakersStore(selectMakersStoreSelectedRow);

  const { isDuplicateModalVisible } = useMakersStoreContextWindowsState();
  const { closeDuplicateModal } = useMakersStoreActions();

  return (
    <Modal open={isDuplicateModalVisible} onClose={closeDuplicateModal}>
      <ModalHeader onClose={closeDuplicateModal}>
        <ModalBreadcrumbs
          rootIcon={<EcommerceSVG />}
          breadcrumbs={[{ name: 'Производители' }, { name: 'Создание записи' }]}
        />
      </ModalHeader>

      <ModalTabbar value="general" tabs={[{ label: 'Основная информация', value: 'general' }]} />

      <MakersForm variant="duplicate" maker={maker} onClose={closeDuplicateModal} />
    </Modal>
  );
};
