import { DataGridActionbar } from '@/components/DataGrid';
import {
  PromoContractsInlineViewButton,
  PromoContractsInlineViewButtonProps,
} from '../actions/promo-contracts-view-actions';
import { PropsWithChildren } from 'react';
import { Stack } from '@mui/material';
import { CustomTypography } from '@/components/CustomTypography';

export type PromoContractsInlineToolbarProps = PropsWithChildren & PromoContractsInlineViewButtonProps;

export const PromoContractsInlineToolbar = (props: PromoContractsInlineToolbarProps) => {
  const { children } = props;

  return (
    <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
      <CustomTypography noWrap lineClamp={1} component="p" variant="inherit">
        {children}
      </CustomTypography>
      <DataGridActionbar>
        <PromoContractsInlineViewButton {...props} />
      </DataGridActionbar>
    </Stack>
  );
};
