import { ButtonTypeMap, SvgIcon } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

import { ActionIconButton, type ActionIconButtonProps } from '@/components/buttons';
import { ReactComponent as FeedbackIcon } from '@/assets/icons-2.0/feedback.svg';

export type ImportLogActionIconButtonProps = ActionIconButtonProps;

export const ImportLogActionIconButton: OverridableComponent<ButtonTypeMap<ImportLogActionIconButtonProps>> = (
  props: ImportLogActionIconButtonProps,
) => (
  <ActionIconButton title="Лог импорта" {...props}>
    <SvgIcon component={FeedbackIcon} inheritViewBox />
  </ActionIconButton>
);
