import { useQuery, type UseQueryOptions } from '@tanstack/react-query';
import type { PaginatedList } from '@/api/@types/pagination.types';
import { ClassificationsApi, type ClassificationsSearchParams } from '@/api/domains/classifications.api';
import { ClassificationsQueryKeys } from '@/api/domains/classifications.query-keys';
import type { ClassificationWithChildren } from '@/api/domains/classifications.types';
import { RECORD_STATUS_REMOVED } from '@/constants/record-status.constants';

type UseClassificationFiledSearchOptions = Pick<ClassificationsSearchParams, 'alias'> &
  Pick<UseQueryOptions<PaginatedList<'items', ClassificationWithChildren>>, 'onSuccess'> & {
    searchQuery: string;
  };

export const useClassificationFiledSearch = (options: UseClassificationFiledSearchOptions) => {
  const { alias, searchQuery, onSuccess } = options;

  const params = { alias, query: searchQuery };
  const { isFetching, data } = useQuery({
    queryKey: ClassificationsQueryKeys.searchClassifications(params),
    queryFn: () => ClassificationsApi.searchClassifications(params),
    enabled: searchQuery.length > 0,
    staleTime: Infinity,
    select: data => ({
      ...data,
      items: data.items.filter(item => item.record_status_id !== RECORD_STATUS_REMOVED),
    }),
    onSuccess,
  });

  const searchedClassifications = data?.items || [];

  return {
    isFetching,
    searchedClassifications,
  };
};
