import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AxiosError } from 'axios';
import { StatusCodes } from 'http-status-codes';
import { ReactNode } from 'react';

// ----------------------------------------------------------------------

const STATUSES_AFTER_WHICH_REQUEST_WILL_NOT_BE_RETRIED = [StatusCodes.UNAUTHORIZED, StatusCodes.BAD_REQUEST];

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (failureCount, error) => {
        const status = (error as AxiosError)?.response?.status;

        if (status && STATUSES_AFTER_WHICH_REQUEST_WILL_NOT_BE_RETRIED.includes(status)) {
          return false;
        }

        return failureCount < 3;
      },
    },
  },
});

// ----------------------------------------------------------------------

export const ReactQueryProvider = (props: { children: ReactNode }) => {
  const { children } = props;

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};
