import { Controller, useFormContext } from 'react-hook-form';
import { TextField, type TextFieldProps } from '@mui/material';
import { Contractor } from '@/api/domains/contractors.types';
import { ContractorSelect, ContractorSelectProps } from './ContractorSelect';

export type RHFContractorSelectFieldProps<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
> = Omit<ContractorSelectProps<Multiple, DisableClearable, FreeSolo>, 'renderInput'> & {
  name: string;
  label?: string;
  helperText?: React.ReactNode;
  TextFieldProps?: Omit<TextFieldProps, 'label' | 'error' | 'helperText'>;
  idToExclude?: Contractor['id'];
};

export const RHFContractorSelectField = <
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined,
>(
  props: RHFContractorSelectFieldProps<Multiple, DisableClearable, FreeSolo>,
) => {
  const { name, label, helperText, TextFieldProps, ...restOfProps } = props;

  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, onChange, ...restFieldsProps }, fieldState: { error } }) => {
        const reassignedOnChange: ContractorSelectProps<Multiple, DisableClearable, FreeSolo>['onChange'] = (
          _,
          value,
        ) => {
          onChange(value);
        };

        return (
          <ContractorSelect
            {...restFieldsProps}
            onChange={reassignedOnChange}
            renderInput={inputParams => (
              <TextField
                inputRef={ref}
                label={label}
                error={!!error}
                placeholder="Поиск"
                helperText={error ? error?.message : helperText}
                {...TextFieldProps}
                {...inputParams}
              />
            )}
            {...restOfProps}
          />
        );
      }}
    />
  );
};
