import { PaginatedList, PaginationParams } from '@/api/@types/pagination.types';
import { Country } from '@/api/domains/countries.types';
import { httpClient } from '@/libs/axios/http-client';

// ----------------------------------------------------------------------

export const CountriesApi = {
  fetchCountries: async (params?: PaginationParams) => {
    const { data } = await httpClient.get<PaginatedList<'items', Country>>('countries', {
      params,
    });

    return data;
  },
};
