import { useState } from 'react';
import { useUpdateEffect } from 'react-use';

import type { createSortFunction } from './data-grid.sorting';
import type { SortInfo } from './types';

// ----------------------------------------------------------------------

export const useDataGridSorting = <SourceData extends object>(
  unsortedSource: SourceData[],
  sort: ReturnType<typeof createSortFunction<SourceData>>,
  defaultSortInfo: SortInfo<SourceData> | null = null,
) => {
  const [sortInfo, setSortInfo] = useState<SortInfo<SourceData> | null>(defaultSortInfo);
  const [dataSource, setDataSource] = useState<SourceData[]>(() => sort(unsortedSource, sortInfo));

  const handleSortInfoChange = (sortInfo: SortInfo<SourceData>) => {
    setSortInfo(sortInfo);
    setDataSource(sort(unsortedSource, sortInfo));
  };

  useUpdateEffect(() => {
    setDataSource(sort(unsortedSource, sortInfo));
  }, [unsortedSource]);

  return {
    dataSource,
    sortInfo,
    handleSortInfoChange,
  };
};
