export const paramsToQueryKey = (baseKey: unknown[], params?: Record<string, unknown>) => {
  if (params) {
    for (const key in params) {
      if (params[key] !== null && params[key] !== undefined) {
        baseKey.push({ [key]: String(params[key]) });
      }
    }
  }

  return baseKey;
};

export const urlToBlob = async (url: string, fetchParams?: RequestInit) => {
  const data = await fetch(url, fetchParams);
  const blob = await data.blob();

  return blob;
};

export const urlToBase64 = async (url: string) => {
  const blob = await urlToBlob(url);

  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      if (typeof reader.result === 'string') {
        resolve(reader.result.split('base64,')[1]);
      } else {
        reject(new Error('Failed to convert file to base64'));
      }
    };
    reader.onerror = () => reject(reader.error);
  });
};

type UrlToFile = {
  url: string;
  fetchParams?: RequestInit;
  fileName?: string;
};

export const urlToFile = async (params: UrlToFile) => {
  const { url, fetchParams, fileName = 'file' } = params;

  const blob = await urlToBlob(url, fetchParams);
  const file = new File([blob], fileName, { type: blob.type });

  return file;
};

export type Extractor<TId extends number | string, TData extends Array<Object>, TItem = unknown> = {
  (item: TData[number], index: number, data: TData): { id: TId; item: TItem };
};

export type NormalizedDataKeys = 'ids' | 'indices' | 'pool' | 'data';

type Ids<TId extends number | string> = Array<TId>;
type Indices = Record<number | string, number>;
type Pool<TId extends number | string, TItem> = Record<TId, TItem>;
type Data<TItem> = Array<TItem>;

export type NormalizedData<TItem, DataKeys extends NormalizedDataKeys, TId extends number | string> = {
  [Key in DataKeys]: Key extends 'ids'
    ? Ids<TId>
    : Key extends 'indices'
    ? Indices
    : Key extends 'pool'
    ? Pool<TId, TItem>
    : Key extends 'data'
    ? Data<TItem>
    : never;
};

type HasData = {
  ids: Ids<any>;
  indices: Indices;
  pool: Pool<any, any>;
  data: Data<any>;
};

const has = <Key extends keyof HasData>(
  normalizedData: any,
  includes: Array<string>,
  key: Key,
): normalizedData is Record<Key, HasData[Key]> => includes.includes(key);

export const normalizeData = <
  TId extends number | string,
  TData extends Array<Object>,
  TItem extends TData[number],
  TIncludes extends Array<NormalizedDataKeys> = Array<'pool' | 'ids'>,
>(
  raw: TData,
  extractor: Extractor<TId, TData, TItem> = (item: any) => ({ id: item.id, item }),
  includes: TIncludes = ['pool', 'ids'] as TIncludes,
) => {
  const normalizedData = {} as NormalizedData<TItem, TIncludes[number], TId>;

  const hasIds = has(normalizedData, includes, 'ids');
  const hasIndices = has(normalizedData, includes, 'indices');
  const hasPool = has(normalizedData, includes, 'pool');
  const hasData = has(normalizedData, includes, 'data');

  if (hasIds) normalizedData.ids = [];
  if (hasIndices) normalizedData.indices = {};
  if (hasPool) normalizedData.pool = {};
  if (hasData) normalizedData.data = [];

  raw.forEach((rawItem, idx) => {
    const { id, item } = extractor(rawItem, idx, raw);

    if (hasIds) normalizedData.ids.push(id);
    if (hasIndices) normalizedData.indices[id] = idx;
    if (hasPool) normalizedData.pool[id] = item;
    if (hasData) normalizedData.data.push(item);
  });

  return normalizedData;
};
