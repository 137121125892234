import { ReactNode } from 'react';
import { Navigate, NavigateProps } from 'react-router-dom';

// ----------------------------------------------------------------------

export type RedirectWithoutAccessProps = NavigateProps & {
  hasAccess: boolean;
  children: ReactNode;
};

// ----------------------------------------------------------------------

export const RedirectWithoutAccess = (props: RedirectWithoutAccessProps) => {
  const { hasAccess = false, children, ...rest } = props;

  if (!hasAccess) {
    return <Navigate {...rest} />;
  }

  return <>{children}</>;
};
