import { lazy } from 'react';

export const RegistryBindingPage = lazy(
  () =>
    import(
      /* webpackPreload: true */
      /* webpackChunkName: "RegistryBindingPage" */
      './RegistryBindingPage'
    ),
);
