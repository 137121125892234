import { useUnmount } from 'react-use';
import { devtools } from 'zustand/middleware';
import { TypeSingleFilterValue } from '@inovua/reactdatagrid-community/types';
import { add } from 'date-fns';
import { createStoreWithReset } from '@/libs/zustand';
import { PromoContractDataGridRowData } from './data-grids/types';
import { PromoContractStatusId } from '@/constants/promo-contract-status.constants';
import { formatDateTime } from '@/helpers/format.helpers';
import { defaultFilterValue } from './data-grids';
import { some } from 'lodash';

const startDate = formatDateTime(new Date(), 'yyyy-MM-dd');
const endDate = formatDateTime(
  add(new Date(), {
    years: 1,
  }),
  'yyyy-MM-dd',
);

const DEFAULT_GRID_FILTER_PERIOD = [startDate, endDate];

export type PromoContractsStore = {
  isFilterVisible: boolean;
  filterValue: TypeSingleFilterValue[];

  isSpecificationsStatusEditModalOpen: boolean;
  isPaymentSumEditModalOpen: boolean;

  searchQuery: string;
  selectedPromoContract: PromoContractDataGridRowData | null;
  gridFilterPeriod: Array<string>;
  recordFilterStatus: PromoContractStatusId | null;

  selectedSpecificationStatusIds: number[];

  actions: {
    setFilterVisible: (isFilterVisible: boolean) => void;
    setFilterValue: (filterValue: TypeSingleFilterValue[]) => void;

    closeSpecificationStatusEditModal: () => void;
    openSpecificationStatusEditModal: () => void;

    setSearchQuery: (query: string) => void;
    clearSearchQuery: VoidFunction;

    openPaymentSumEditModal: () => void;
    closePaymentSumEditModal: () => void;

    setSelectedPromoContract: (selectedPromoContract: PromoContractDataGridRowData) => void;
    resetSelectedPromoContract: VoidFunction;

    setGridFilterPeriod: (gridFilterPeriod: Array<string>) => void;
    setSpecificationRecordFilterStatus: (recordFilterStatus: PromoContractStatusId | null) => void;

    setSelectedSpecificationStatusIds: (ids: number[]) => void;
  };
};

export const [usePromoContractsStore, resetPromoContractsStore] = createStoreWithReset<PromoContractsStore>()(
  devtools(
    set => ({
      // State
      isFilterVisible: false,
      filterValue: defaultFilterValue,

      isSpecificationsStatusEditModalOpen: false,
      isPaymentSumEditModalOpen: false,

      searchQuery: '',
      selectedPromoContract: null,
      gridFilterPeriod: DEFAULT_GRID_FILTER_PERIOD,
      recordFilterStatus: null,

      isThereCashbackCalculationStatus: false,

      selectedSpecificationStatusIds: [],

      // Actions
      actions: {
        setFilterVisible: isFilterVisible => set({ isFilterVisible }, false, 'setFilterVisible'),
        setFilterValue: filterValue => set({ filterValue }, false, 'setFilterValue'),

        closeSpecificationStatusEditModal: () =>
          set({ isSpecificationsStatusEditModalOpen: false }, false, 'closeSpecificationStatusEditModal'),

        openSpecificationStatusEditModal: () =>
          set({ isSpecificationsStatusEditModalOpen: true }, false, 'openSpecificationStatusEditModal'),

        openPaymentSumEditModal: () => set({ isPaymentSumEditModalOpen: true }, false, 'openPaymentSumEditModalOpen'),

        closePaymentSumEditModal: () =>
          set({ isPaymentSumEditModalOpen: false }, false, 'closePaymentSumEditModalOpen'),

        setSearchQuery: (searchQuery: string) => set({ searchQuery }, false, 'setSearchQuery'),
        clearSearchQuery: () => set({ searchQuery: '' }, false, 'clearSearchQuery'),

        setSelectedPromoContract: selectedPromoContract =>
          set({ selectedPromoContract }, false, 'setSelectedselectedPromoContract'),
        resetSelectedPromoContract: () => set({ selectedPromoContract: null }, false, 'resetSelectedPromoContract'),

        setGridFilterPeriod: (gridFilterPeriod: Array<string>) =>
          set({ gridFilterPeriod }, false, 'setGridFilterPeriod'),

        setSelectedSpecificationStatusIds: (ids: number[]) =>
          set({ selectedSpecificationStatusIds: ids }, false, 'setSelectedSpecificationStatusIds'),

        setSpecificationRecordFilterStatus: recordFilterStatus =>
          set({ recordFilterStatus }, false, 'setRecordFilterStatus'),
      },
    }),
    {
      name: 'promo-contracts-store',
      enabled: process.env.NODE_ENV !== 'production',
    },
  ),
);

export const usePromoContractsStoreIsContextWindowsOpen = () =>
  usePromoContractsStore(state => ({
    isSpecificationsStatusEditModalOpen: state.isSpecificationsStatusEditModalOpen,
    isPaymentSumEditModalOpen: state.isPaymentSumEditModalOpen,
  }));

export const usePromoContractsIsContextWindowsVisible = () => some(usePromoContractsStoreIsContextWindowsOpen());

export const usePromoContractsStoreActions = () => usePromoContractsStore(state => state.actions);

export const usePromoContractsStoreSearchQuery = () => usePromoContractsStore(state => state.searchQuery);

export const usePromoContractsStoreSelectedContract = () =>
  usePromoContractsStore(state => state.selectedPromoContract);
export const usePromoContractsStoreGridFilterPeriod = () => usePromoContractsStore(state => state.gridFilterPeriod);

export const usePromoContractsStoreIsFilterVisible = () => usePromoContractsStore(state => state.isFilterVisible);
export const usePromoContractsStoreFilterValue = () => usePromoContractsStore(state => state.filterValue);

export const usePromoContractsStoreIsSpecificationsStatusEditModalOpen = () =>
  usePromoContractsStore(state => state.isSpecificationsStatusEditModalOpen);

export const usePromoContractsRecordFilterStatus = () => usePromoContractsStore(state => state.recordFilterStatus);

export const usePromoContractsIsContextWindowOpen = () => false;

export const useResetPromoContractsStoreOnUnmount = () => useUnmount(resetPromoContractsStore);

export const usePromoContractsStoreSpecificationStatusIds = () =>
  usePromoContractsStore(state => state.selectedSpecificationStatusIds);
