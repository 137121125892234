import { svgIconClasses } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';

import { ActionButton, type ActionButtonProps } from '@/components/buttons';

type StyledToggleButtonProps = {
  isActive?: boolean;
};

const StyledToggleButton = styled(ActionButton, {
  shouldForwardProp: propName => propName !== 'isActive',
})<StyledToggleButtonProps>(
  ({ isActive, theme }) =>
    isActive && {
      '&:not(:hover):not(.Mui-disabled)': {
        color: theme.palette.secondary.main,
        backgroundColor: alpha(theme.palette.secondary.main, 0.12),

        [`.${svgIconClasses.root}`]: {
          color: theme.palette.secondary.main,
        },
      },
    },
) as React.FC<StyledToggleButtonProps & ActionButtonProps>;

export type ToggleButtonProps = StyledToggleButtonProps & Omit<ActionButtonProps, 'color'>;

export const ToggleButton = (props: ToggleButtonProps) => {
  const { isActive, ...restOfProps } = props;

  return <StyledToggleButton isActive={isActive} {...restOfProps} />;
};
