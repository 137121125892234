import { Avatar, AvatarProps, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

type Props = {
  width: number;
  isAura?: boolean;
  isLoad?: boolean;
};

type StyledAvatar = AvatarProps & Props;

export const StyledAvatar = styled(Avatar, { shouldForwardProp: prop => prop !== 'isLoad' })<StyledAvatar>(
  ({ theme: { palette }, width, isLoad }) => ({
    backgroundColor: palette.tetriary['-1'],
    color: palette.common.white,
    height: width,
    width,
    fontSize: width / 2,

    '&:hover&:after': {
      alignItems: 'center',
      backgroundColor: 'rgba(11, 14, 18, .5)',
      borderRadius: '50%',
      content: isLoad ? '"Загрузить"' : undefined,
      cursor: 'pointer',
      display: 'flex',
      fontSize: '12px',
      fontWeight: 700,
      height: width,
      justifyContent: 'center',
      letterSpacing: '0.5px',
      lineHeight: '16px',
      position: 'absolute',
      width: width,
    },

    '&:hover&:before': {
      bottom: '10px',
      content: isLoad ? '"до 5Мб"' : undefined,
      fontSize: '10px',
      fontWeight: 400,
      letterSpacing: '0.5px',
      lineHeight: '12px',
      position: 'absolute',
      zIndex: 1,
    },
  }),
);

export const AvatarAuraStyled = styled(Stack, { shouldForwardProp: prop => prop !== 'isAura' })<Props>(
  ({ theme: { palette }, width, isAura }) => ({
    alignItems: 'center',
    height: width,
    justifyContent: 'center',
    width,

    '&:before': {
      borderRadius: '50%',
      boxShadow: `0 0 0 2px ${palette.common.white}`,
      content: isAura ? '""' : undefined,
      height: width,
      position: 'absolute',
      width,
    },

    '&:after': {
      borderRadius: '50%',
      boxShadow: `0 0 0 2px ${palette.tetriary['-1']}`,
      content: isAura ? '""' : undefined,
      height: width + 4,
      position: 'absolute',
      width: width + 4,
    },
  }),
);
