import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { InnApi, FetchInnsParams } from '@/api/domains/inn.api';
import { InnQueryKeys } from '@/api/domains/inn.query-keys';
import { Inn } from '@/api/domains/inn.type';

// ----------------------------------------------------------------------

const defaultItems: Inn[] = [];

// ----------------------------------------------------------------------

export const useInns = (
  params?: FetchInnsParams,
  queryOptions?: Pick<UseQueryOptions, 'enabled' | 'keepPreviousData'>,
) => {
  const { isLoading, isFetching, data } = useQuery({
    queryKey: InnQueryKeys.inn(params),
    queryFn: () => InnApi.fetchInns(params),
    staleTime: Infinity,
    ...queryOptions,
  });

  const inns = data?.items ?? defaultItems;

  return { isLoading, isFetching, inns };
};
