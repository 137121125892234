import { useLatest, useUpdateEffect } from 'react-use';
import useKeyboardJs from 'react-use/lib/useKeyboardJs';

/**
 * @deprecated - Use `import { useShortcut } from '@/hooks/useShortcut2'` instead
 */
export const useShortcut = (combination: string | Array<string>, fn: VoidFunction, deps: React.DependencyList = []) => {
  const [isPressed] = useKeyboardJs(combination);

  const latestFn = useLatest(fn);

  useUpdateEffect(() => {
    if (isPressed) {
      latestFn.current();
    }
  }, [isPressed, ...deps]);
};
