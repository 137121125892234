import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { PaginationParams } from '@/api/@types/pagination.types';
import { CountriesApi } from '@/api/domains/countries.api';
import { CountriesQueryKeys } from '@/api/domains/countries.query-keys';
import { Country } from '@/api/domains/countries.types';

// ----------------------------------------------------------------------

const defaultItems: Country[] = [];

export const useCountries = (
  params?: PaginationParams,
  queryOptions?: Pick<UseQueryOptions, 'enabled' | 'keepPreviousData'>,
) => {
  const { isLoading, isFetching, data } = useQuery({
    queryKey: CountriesQueryKeys.countries(params),
    queryFn: () => CountriesApi.fetchCountries(params),
    staleTime: Infinity,
    ...queryOptions,
  });

  const countries = data?.items ?? defaultItems;

  return { isLoading, isFetching, countries };
};
