import { MakersParams } from '@/api/domains/makers.api';
import { paramsToQueryKey } from '@/helpers/transformation.helpers';

// ----------------------------------------------------------------------

const PREFIX = 'makers' as const;

// ----------------------------------------------------------------------

export const MakersQueryKeys = {
  root: [PREFIX],

  searchMakers: (params?: MakersParams) => paramsToQueryKey([PREFIX, 'search'], params),

  makers: (params?: MakersParams) => paramsToQueryKey([PREFIX, 'list'], params),

  maker: (makerId?: string | number) => [PREFIX, makerId?.toString(), 'view'],
} as const;
