import { useRef } from 'react';

import { useShortcut, type UseShortcutOptions } from '@/hooks/useShortcut2';

export const useSearchFieldShortcuts = (predicate?: UseShortcutOptions['isEnable']) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useShortcut({
    combination: ['ctrl+f', 'f3', 'command+f'],
    isEnable: predicate,
    onPressed: () => {
      const inputElement = inputRef.current;

      if (inputElement) {
        inputElement.focus();

        const { length } = inputElement.value;

        if (length > 0) {
          inputElement.setSelectionRange(0, length);
        }
      }
    },
  });

  return inputRef;
};
