import { generatePath } from 'react-router';
import { joinPath, ExtractPathParams } from '@/libs/react-router';

const ROOT_PATH = '/registers';
const REFERENCE_PRICE_REGISTRY_VIEW_PATH = joinPath(ROOT_PATH, 'reference-price');
const FIELD_MATCHING_PATH = joinPath(ROOT_PATH, 'fields');
const BINDING = joinPath(ROOT_PATH, 'binding');
const IMPORT_LOG_PATH = joinPath(ROOT_PATH, ':registryId/import-log');
const IMPORT_LOG_BASE_INFO_PATH = joinPath(IMPORT_LOG_PATH, 'info');
const IMPORT_LOG_ERRORS_PATH = joinPath(IMPORT_LOG_PATH, 'errors');

export type RegistryImportLogBasePathParams = ExtractPathParams<typeof IMPORT_LOG_BASE_INFO_PATH>;
export type RegistryImportLogErrorPathParams = ExtractPathParams<typeof IMPORT_LOG_ERRORS_PATH>;

export const RegistersPaths = {
  ROOT_PATH,
  REFERENCE_PRICE_REGISTRY_VIEW_PATH,
  FIELD_MATCHING_PATH,
  BINDING,
  IMPORT_LOG_PATH,

  IMPORT_LOG_BASE_INFO_PATH,
  generateImportLogBasePath: <TParams extends RegistryImportLogBasePathParams>(params: TParams) =>
    generatePath(IMPORT_LOG_BASE_INFO_PATH, params),

  IMPORT_LOG_ERRORS_PATH,
  generateImportLogErrorPath: <TParams extends RegistryImportLogErrorPathParams>(params: TParams) =>
    generatePath(IMPORT_LOG_ERRORS_PATH, params),
} as const;
