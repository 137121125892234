import { ReactNode } from 'react';
import { CssBaseline } from '@mui/material';
import { createTheme, StyledEngineProvider, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { getCustomShadows } from '@/theme/custom-shadows.theme';
import { dimensions } from '@/theme/dimensions.theme';
import { globalStyles } from '@/theme/GlobalStyles';
import { overrideComponents } from '@/theme/overrides';
import { getPalette } from '@/theme/palette.theme';
import { getShadows } from '@/theme/shadows.theme';
import { shape } from '@/theme/shape.theme';
import { typography } from '@/theme/typography.theme';
import { breakpoints } from '@/theme/breakpoints.theme';

type Props = {
  children: ReactNode;
};

// @ts-ignore
const theme = createTheme({
  palette: getPalette('light'),
  typography,
  shape,
  shadows: getShadows('light'),
  customShadows: getCustomShadows('light'),
  dimensions,
  breakpoints,
});

theme.components = overrideComponents(theme);

window.theme = theme;

// ----------------------------------------------------------------------
export const ThemeProvider = (props: Props) => {
  const { children } = props;

  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        {globalStyles}
        {children}
      </MuiThemeProvider>
    </StyledEngineProvider>
  );
};
