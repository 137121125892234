import { isNil } from 'lodash';

import { TFilterItem } from '@/components/DataGrid';

export const compareIds = <T extends number | string | boolean>(
  { id: a }: TFilterItem<T>,
  { id: b }: TFilterItem<T>,
): number => {
  if (typeof a === 'number' && typeof b === 'number') {
    return a - b;
  }
  return String(a).localeCompare(String(b));
};

export const compareLabels = <T extends string | number | boolean>(
  { label: a }: TFilterItem<T>,
  { label: b }: TFilterItem<T>,
): number => a.localeCompare(b);

export const compareString = (
  { label: a }: TFilterItem<string | number | boolean>,
  { label: b }: TFilterItem<string | number | boolean>,
) => a.localeCompare(b);

export const setFilterData = <T extends number | string>(
  filterData: Map<T, TFilterItem<T>>,
  key: T | undefined,
  label?: string,
): void => {
  if (!isNil(key) && !filterData.has(key)) {
    filterData.set(key, { id: key, label: label ?? String(key) });
  }
};

export const prepareFilterData = <T extends number | string>(
  filterData: Map<T, TFilterItem<T>>,
  sortBy: (a: TFilterItem<T>, b: TFilterItem<T>) => number = compareIds,
): TFilterItem<T>[] => {
  return Array.from(filterData.values()).sort(sortBy);
};
