import { lazy } from 'react';

// ----------------------------------------------------------------------

const BindingsPage = lazy(
  () =>
    import(
      /* webpackPreload: true */
      /* webpackChunkName: "BindingsPage" */
      '@/pages/bindings/BindingsPage'
    ),
);

export default BindingsPage;
