import { Box, BoxProps, TypographyProps } from '@mui/material';
import { OverridableComponent, OverrideProps } from '@mui/material/OverridableComponent';
import { styled, Theme } from '@mui/material/styles';
import { SystemProps } from '@mui/system';
import { get } from 'lodash';

type StyledLabelProps = {
  color?: Extract<TypographyProps['color'], string>;
  backgroundColor?: Extract<TypographyProps['color'], string>;
};

export interface DorimPromoLabelMap<P = {}, D extends React.ElementType = 'div'> {
  props: P & SystemProps<Theme> & React.PropsWithChildren<StyledLabelProps>;
  defaultComponent: D;
}

export type DorimPromoLabelProps<
  D extends React.ElementType = DorimPromoLabelMap['defaultComponent'],
  P = {},
> = OverrideProps<DorimPromoLabelMap<P, D>, D>;

const StyledLabel = styled(Box, {
  shouldForwardProp: prop => prop !== 'color' && prop !== 'backgroundColor',
})<StyledLabelProps>(
  {
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    height: 18,
    padding: '0 5px',
    borderRadius: 4,
    fontSize: 12,
    fontWeight: 400,
  },
  ({ theme, color }) =>
    color && {
      color: get(theme.palette, color),
    },
  ({ theme, backgroundColor }) =>
    backgroundColor && {
      backgroundColor: get(theme.palette, backgroundColor),
    },
) as React.FC<BoxProps & StyledLabelProps>;

export const DorimPromoLabel: OverridableComponent<DorimPromoLabelMap> = (props: DorimPromoLabelProps) => (
  <StyledLabel {...props} />
);
