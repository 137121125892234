import { useMemo, useState } from 'react';
import { ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon, Clear as ClearIcon } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  IconButton,
  type IconButtonProps,
  InputAdornment,
  TextField,
  type TextFieldProps,
  Typography,
} from '@mui/material';
import type { Classification, ClassificationWithChildren } from '@/api/domains/classifications.types';
import { Scrollbar } from '@/components/Scrollbar';
import { ClassificationFieldSearchTreeItem } from './ClassificationFieldSearchTreeItem';
import { ClassificationFiledTreeItem } from './ClassificationFiledTreeItem';
import { ClassificationPopper } from './ClassificationPopper';
import { useClassificationField } from './hooks/useClassificationField';
import { useClassificationFieldPopper } from './hooks/useClassificationFieldPopper';
import { useClassificationFiledSearch } from './hooks/useClassificationFiledSearch';
import { useClassifications } from './hooks/useClassifications';
import { TreeView } from './TreeView';
import type { ClassificationAliasProp } from './types';

export type ClassificationFieldProps = ClassificationAliasProp &
  Omit<TextFieldProps, 'multiline' | 'value' | 'select' | 'onSelect' | 'onChange'> & {
    disableClearable?: boolean;
    parentId?: number;
    value: Classification | ClassificationWithChildren | null;
    onChange: (classification: Classification | ClassificationWithChildren | null) => void;
    isParentSelectable?: boolean;
    shouldShowSelectedValueDescription?: boolean;
  };

export const ClassificationField = (props: ClassificationFieldProps) => {
  const {
    disableClearable = false,
    alias,
    value,
    parentId,
    onChange,
    isParentSelectable = true,
    shouldShowSelectedValueDescription = true,
    ...restOfProps
  } = props;

  const {
    isSearchMode,
    inputValue,
    searchQuery,
    resetSearchQuery,
    handleInputPaste,
    handleInputChange,
    handleInputClear,
  } = useClassificationField({ value, onChange });

  const [isTextFieldFocused, setIsTextFieldFocused] = useState(false);
  const [isTextFieldHovered, setIsTextFieldHovered] = useState(false);

  const {
    isOpen,
    isFocused,
    popperRef,
    anchorEl,
    setAnchorEl,
    getRootProps,
    getInputProps,
    getPopupIndicatorProps,
    getListboxProps,
    closePopper,
    updatePopperPosition,
  } = useClassificationFieldPopper({
    onPopperClose: resetSearchQuery,
  });

  const { isFetching: isFetchingSearch, searchedClassifications } = useClassificationFiledSearch({
    alias,
    searchQuery,
    onSuccess: updatePopperPosition,
  });

  const { isFetching: isFetchingClassifications, classifications } = useClassifications({
    alias,
    parentId,
    enabled: !isSearchMode && isOpen,
    onSuccess: updatePopperPosition,
  });

  const handleSelect = (classification: Classification | ClassificationWithChildren | null) => {
    if (!isParentSelectable && classification?.has_children) {
      return;
    }

    onChange(classification);
    closePopper();
  };

  const inputProps = getInputProps();

  delete inputProps.value;

  const hasData = isSearchMode ? searchedClassifications.length > 0 : classifications.length > 0;
  const isFetching = isFetchingClassifications || isFetchingSearch;

  const formatSelectedValue = (input: string) => {
    if (shouldShowSelectedValueDescription) {
      return input;
    }

    const [name] = input.split(' - ');
    return name;
  };

  const hasSomeClassificationChildren = useMemo(
    () => classifications.some(classification => classification.has_children),
    [classifications],
  );

  return (
    <>
      <Box {...getRootProps({ sx: { display: 'inline' } })}>
        <TextField
          focused={isFocused}
          ref={setAnchorEl}
          variant="filled"
          autoComplete="off"
          {...restOfProps}
          placeholder="Поиск"
          inputProps={inputProps}
          value={formatSelectedValue(inputValue)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {!disableClearable && !isFetching && (
                  <IconButton
                    onClick={handleInputClear}
                    size="small"
                    sx={{ visibility: value && (isTextFieldHovered || isTextFieldFocused) ? 'visible' : 'hidden' }}
                  >
                    <ClearIcon fontSize="small" />
                  </IconButton>
                )}
                <IconButton disabled={isFetching} edge="end" {...(getPopupIndicatorProps() as IconButtonProps)}>
                  {!isOpen && !isFetching && <ExpandMoreIcon fontSize="small" color="inherit" />}
                  {isOpen && !isFetching && <ExpandLessIcon fontSize="small" color="inherit" />}
                  {isFetching && <CircularProgress color="inherit" size={24} />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={handleInputChange}
          onPaste={handleInputPaste}
          onFocus={() => setIsTextFieldFocused(true)}
          onBlur={() => setIsTextFieldFocused(false)}
          onMouseEnter={() => setIsTextFieldHovered(true)}
          onMouseLeave={() => setIsTextFieldHovered(false)}
        />
      </Box>

      <ClassificationPopper open={isOpen} anchorEl={anchorEl} popperRef={popperRef}>
        <Scrollbar className="Popover-scrollable">
          <TreeView isRoot isExpandable={hasSomeClassificationChildren} {...getListboxProps()}>
            {!isSearchMode &&
              classifications.map(classification => (
                <ClassificationFiledTreeItem
                  key={classification.id}
                  alias={alias}
                  value={classification}
                  selectedValue={value}
                  onSelect={handleSelect}
                  isParentSelectable={isParentSelectable}
                />
              ))}

            {isSearchMode &&
              searchedClassifications.map(classification => (
                <ClassificationFieldSearchTreeItem
                  key={classification.id}
                  searchQuery={searchQuery}
                  value={classification}
                  selectedValue={value}
                  onSelect={handleSelect}
                />
              ))}

            {(!hasData || isFetching) && (
              <Typography component="p" p={1} variant="body1" color="text.secondary">
                {isFetching ? 'Загрузка...' : 'Нет данных'}
              </Typography>
            )}
          </TreeView>
        </Scrollbar>
      </ClassificationPopper>
    </>
  );
};
