import { lazy } from 'react';

export const RegistryFieldMatchingPage = lazy(
  () =>
    import(
      /* webpackPreload: true */
      /* webpackChunkName: "RegistryFieldMatchingPage" */
      './RegistryFieldMatchingPage'
    ),
);
