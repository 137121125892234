import { lazy } from 'react';

// ----------------------------------------------------------------------

const OffersPage = lazy(
  () =>
    import(
      /* webpackPreload: true */
      /* webpackChunkName: "OffersPage" */
      '@/pages/offers/OffersPage'
    ),
);

// ----------------------------------------------------------------------

export default OffersPage;
