import { lazy } from 'react';

export const ProfilePageLazy = lazy(
  () =>
    import(
      /* webpackPreload: true */
      /* webpackChunkName: "ProfilePage" */
      '@/pages/profile/ProfilePage'
    ),
);
