import { Box, Stack, styled } from '@mui/material';

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: theme.dimensions.statusActionsBar.height,
  padding: theme.spacing(1, 4),
  backgroundColor: theme.palette.background.paper,
}));

const StatusContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  flexGrow: 1,
});

const ActionsContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  flexGrow: 1,
});

type StatusActionsBarProps = {
  status?: React.ReactNode;
  actions?: React.ReactNode;
};
export const StatusActionsBar = (props: StatusActionsBarProps) => {
  const { status, actions } = props;
  return (
    <Container>
      <Stack direction="row" justifyContent="space-between" flexGrow={1}>
        {status && <StatusContainer>{status}</StatusContainer>}
        {actions && <ActionsContainer>{actions}</ActionsContainer>}
      </Stack>
    </Container>
  );
};
