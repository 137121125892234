import { SxProps } from '@mui/material';
import { alpha, styled, Theme } from '@mui/material/styles';
import SimpleBar, { Props } from 'simplebar-react';

// ----------------------------------------------------------------------

export type ScrollbarProps = React.PropsWithChildren<
  Props & {
    sx?: SxProps<Theme>;
  }
>;

// ----------------------------------------------------------------------

export const StyledScrollbarContainer = styled('div')({
  height: '100%',
  overflow: 'hidden',
});

export const StyledScrollbar = styled(SimpleBar)(({ theme }) => ({
  height: '100%',

  '.simplebar-scrollbar': {
    '&:before': {
      backgroundColor: alpha(theme.palette.grey[600], 0.48),
    },
    '&.simplebar-visible:before': {
      opacity: 1,
    },
  },
  '.simplebar-mask': {
    zIndex: 'inherit',
  },
}));

// ----------------------------------------------------------------------

export const Scrollbar = (props: ScrollbarProps) => {
  const { children, sx, ...restOfProps } = props;

  return (
    <StyledScrollbarContainer>
      <StyledScrollbar clickOnTrack={false} sx={sx} {...restOfProps}>
        {children}
      </StyledScrollbar>
    </StyledScrollbarContainer>
  );
};
