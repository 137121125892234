import { FetchInnsParams } from '@/api/domains/inn.api';
import { paramsToQueryKey } from '@/helpers/transformation.helpers';

// ----------------------------------------------------------------------

const PREFIX = 'inn' as const;

// ----------------------------------------------------------------------

export const InnQueryKeys = {
  root: [PREFIX],

  inn: (params?: FetchInnsParams) => paramsToQueryKey([PREFIX, 'list'], params),
};
