import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { UniqueIdentifier } from '@dnd-kit/core';
import { ImageCard, ImageCardProps } from './ImageCard';

type ImageCardSortableProps = {
  id: UniqueIdentifier;
  index: number;
  useDragOverlay?: boolean;
  onRemove: ImageCardProps['onRemove'];
  getWrapperStyle: (index: number) => React.CSSProperties;
  image: ImageCardProps['image'];
  onEdit: ImageCardProps['onEdit'];
};

export const ImageCardSortable = (props: ImageCardSortableProps) => {
  const { id, index, getWrapperStyle, ...restProps } = props;
  const { attributes, isDragging, listeners, setNodeRef, transform, transition } = useSortable({
    id,
  });

  return (
    <ImageCard
      ref={setNodeRef}
      dragging={isDragging}
      transform={transform}
      transition={transition}
      wrapperStyle={getWrapperStyle?.(index)}
      listeners={listeners}
      data-index={index}
      data-id={id}
      {...restProps}
      {...attributes}
    />
  );
};
