import { Close as CloseIcon } from '@mui/icons-material';
import { Collapse, IconButton, InputAdornment, Stack, Typography } from '@mui/material';

import { InputLabel } from '@/components/labels';

type EndAdornmentProps = {
  size?: 'small' | 'medium';
  isVisibleClearButton?: boolean;
  isVisibleLabel?: boolean;
  onClear?: () => void;
};

export const EndAdornment = (props: EndAdornmentProps) => {
  const { size = 'medium', isVisibleClearButton, isVisibleLabel, onClear } = props;

  return (
    <InputAdornment position="end">
      <Stack direction="row" alignItems="center">
        {isVisibleClearButton && (
          <IconButton size={size} edge={isVisibleLabel ? false : 'end'} sx={{ color: 'neutral.700' }} onClick={onClear}>
            <CloseIcon fontSize={size} color="inherit" />
          </IconButton>
        )}
        <Collapse unmountOnExit in={isVisibleLabel} orientation="horizontal">
          <InputLabel>
            F3{' '}
            <Typography component="span" variant="inherit" color="neutral.300">
              /
            </Typography>{' '}
            CTRL+F
          </InputLabel>
        </Collapse>
      </Stack>
    </InputAdornment>
  );
};
