import { ClipboardEvent, forwardRef, useState } from 'react';
import { OnValueChange, PatternFormat, PatternFormatProps } from 'react-number-format';
import { CustomTextField, CustomTextFieldProps } from './CustomTextField';

// ----------------------------------------------------------------------

export type MaskedPhoneTextFieldProps = Omit<CustomTextFieldProps, 'ref' | 'onChange'> &
  Omit<PatternFormatProps, 'mask' | 'customInput' | 'format'>;

const COUNTRY_PHONE_CODE = 998;

export const MaskedPhoneTextField = forwardRef<HTMLDivElement, MaskedPhoneTextFieldProps>((props, ref) => {
  const {
    onFocus: passedOnFocus,
    onBlur: passedOnBlur,
    onValueChange: passedOnValueChange,
    value,
    ...restOfProps
  } = props;

  const [isFocused, setIsFocused] = useState(false);

  const patchedOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(true);
    passedOnFocus?.(e);
  };

  const patchedOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(false);
    passedOnBlur?.(e);
  };

  // skip leading "998" (country code)
  const transformedValue = value ? value.toString().slice(3) : '';

  const patchedOnValueChange: OnValueChange = (values, sourceInfo) => {
    const { formattedValue, floatValue } = values;
    const numericValue = formattedValue.replace(/\D/g, '');
    // skip setting value if it only represents "998" (country code)
    const transformedValue = numericValue.length > 3 ? numericValue : '';

    passedOnValueChange?.({ formattedValue, value: transformedValue, floatValue }, sourceInfo);
  };

  const handlePaste = (event: ClipboardEvent<HTMLInputElement>) => {
    event.preventDefault();

    const paste = (event.clipboardData || window.clipboardData).getData('text').replace(/\D/g, '');
    const phoneWithoutCode = paste && paste.slice(-9);

    if (paste) {
      // @ts-ignore TODO: надо доработать метод
      passedOnValueChange?.({ value: `${COUNTRY_PHONE_CODE}${phoneWithoutCode}` });
    }
  };

  return (
    <PatternFormat
      allowEmptyFormatting={isFocused}
      format={`+${COUNTRY_PHONE_CODE} (##) ###-##-##`}
      mask="_"
      customInput={CustomTextField}
      getInputRef={ref}
      onFocus={patchedOnFocus}
      onBlur={patchedOnBlur}
      value={transformedValue}
      onValueChange={patchedOnValueChange}
      onPaste={handlePaste}
      {...restOfProps}
    />
  );
});
