import { PropsWithChildren } from 'react';
import { useAuthState } from '@/contexts/AuthContext';
import { useProfile } from '@/modules/profile';

const ProtectedInitialize = () => {
  useProfile();

  return null;
};

export const Initialize = ({ children }: PropsWithChildren<{}>) => {
  const { isAuthenticated } = useAuthState();

  return (
    <>
      {children}
      {isAuthenticated && <ProtectedInitialize />}
    </>
  );
};
