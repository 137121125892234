import { styled } from '@mui/material/styles';
import { Button, Stack } from '@mui/material';

export const StyledSupportMenuPopover = styled(Stack)(({ theme: { palette } }) => ({
  backgroundColor: palette.common.white,
  padding: 8,
}));

export const StyledSupportMenuPopoverHeader = styled(Stack)(() => ({
  borderBottom: '1px solid #E6EBF0',
  marginBottom: 8,
  padding: 8,
}));

export const StyledSupportMenuPopoverButton = styled(Button)(({ theme }) => ({
  paddingLeft: theme.spacing(1.5),
  paddingRight: theme.spacing(1.5),

  '&:hover': {
    backgroundColor: '#E6EBF0',
    color: theme.palette.text.secondary,
  },
  '&:not(:hover)': {
    backgroundColor: 'transparent',

    '&:not(.Mui-disabled)': {
      color: theme.palette.text.secondary,
    },
  },
})) as typeof Button;

export const popoverSxStyle = {
  title: {
    color: '#637381',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    letterSpacing: '0.4px',
    lineHeight: '16px',
    whiteSpace: 'nowrap',
  },
} as const;
