import { lazy } from 'react';

export const RegistryImportLogGeneralInfoPage = lazy(
  () =>
    import(
      /* webpackPreload: true */
      /* webpackChunkRegistersMain: "GeneralInfoPage" */
      './GeneralInfoPage'
    ),
);
