import { useMutation } from '@tanstack/react-query';
import { Link as RouterLink } from 'react-router-dom';

import { Stack } from '@mui/material';

import { useProfile } from '@/modules/profile/queries/useProfile';

import { useAuthMethods } from '@/contexts/AuthContext';

import { ActionButton } from '@/components/buttons';
import { useGlobalErrorStore } from '@/components/dialogs';

import { ReactComponent as ContractSVG } from '@/assets/icons-2.0/contract.svg';
import { ReactComponent as LogoutSVG } from '@/assets/icons-2.0/logout.svg';
import { ReactComponent as PersonSVG } from '@/assets/icons-2.0/person.svg';

import { ProfileLinks } from '@/api/domains/profile.types';
import { Avatar } from '../../Avatar';
import {
  StyledProfileMenuPopoverFooter,
  StyledProfileMenuPopoverHeader,
  StyledProfileMenuPopoverHeaderUserInfo,
  StyledProfileMenuPopover,
} from './styles';
import { popoverSxStyle } from './styles';

type Props = ProfileLinks;

export const ProfileMenuPopover = ({ routeLink, privacyPolicyLink }: Props) => {
  const { user, fullName, profile, contractor } = useProfile();

  const authMethods = useAuthMethods();
  const { setGlobalError } = useGlobalErrorStore();

  const { isLoading: isLoadingMutation, mutate: signOut } = useMutation({
    mutationFn: authMethods.signOut,
    onError: setGlobalError,
  });
  const handleSignOut = () => signOut();

  return (
    <StyledProfileMenuPopover minWidth={300} maxWidth={400}>
      <StyledProfileMenuPopoverHeader direction="row">
        <Avatar fullName={fullName} imgLink={user?.avatar_url} size={56} />

        <StyledProfileMenuPopoverHeaderUserInfo>
          <Stack sx={popoverSxStyle.userName}>
            <Stack>{user?.first_name}</Stack>
            <Stack>{user?.last_name}</Stack>
          </Stack>
          {profile && (
            <Stack sx={popoverSxStyle.contractorInfo}>
              {profile?.job_title}
              {contractor?.name && <Stack sx={popoverSxStyle.contractorInfo.name}>{contractor?.name}</Stack>}
            </Stack>
          )}
        </StyledProfileMenuPopoverHeaderUserInfo>
      </StyledProfileMenuPopoverHeader>

      <Stack spacing={1}>
        {routeLink && (
          <ActionButton
            component={RouterLink}
            disabled={isLoadingMutation}
            startIcon={<PersonSVG width={24} />}
            variant="soft"
            sx={{ justifyContent: 'flex-start' }}
            to={routeLink}
          >
            Профиль
          </ActionButton>
        )}
        {privacyPolicyLink && (
          <ActionButton
            component={RouterLink}
            disabled={isLoadingMutation}
            startIcon={<ContractSVG width={24} />}
            variant="soft"
            sx={{ justifyContent: 'flex-start' }}
            target="_blank"
            to={privacyPolicyLink}
          >
            Публичная оферта
          </ActionButton>
        )}
      </Stack>

      <StyledProfileMenuPopoverFooter>
        <ActionButton
          disabled={isLoadingMutation}
          onClick={handleSignOut}
          variant="soft"
          startIcon={<LogoutSVG width={24} />}
          sx={{ justifyContent: 'flex-start' }}
        >
          Выход
        </ActionButton>
      </StyledProfileMenuPopoverFooter>
    </StyledProfileMenuPopover>
  );
};
