import { Controller, ControllerProps, useFormContext } from 'react-hook-form';
import { ClassificationField, ClassificationFieldProps } from './ClassificationField';

type RHFClassificationFieldProps = Omit<ClassificationFieldProps, 'value' | 'onChange'> & {
  name: string;
  errorMessage?: string;
};

export const RHFClassificationField = (props: RHFClassificationFieldProps) => {
  const { name, errorMessage: passedErrorMessage, ...restOfProps } = props;
  const { control } = useFormContext();

  const RenderComponent: ControllerProps['render'] = ({
    field: { ref, ...restOfFieldProps },
    fieldState: { error },
  }) => {
    const hasError = !!error;
    const errorMessage = hasError && (error?.message ?? passedErrorMessage);

    return <ClassificationField {...restOfProps} {...restOfFieldProps} error={hasError} helperText={errorMessage} />;
  };

  return <Controller name={name} control={control} render={RenderComponent} />;
};
