import { lazy } from 'react';

// ----------------------------------------------------------------------

const InnPage = lazy(
  () =>
    import(
      /* webpackPreload: true */
      /* webpackChunkName: "InnPage" */
      '@/pages/inn/InnPage'
    ),
);

// ----------------------------------------------------------------------

export default InnPage;
