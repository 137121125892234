import { QueryKey, useInfiniteQuery, UseInfiniteQueryOptions } from '@tanstack/react-query';
import { PaginatedList } from '@/api/@types/pagination.types';
import { useDebouncedCallback } from '@/hooks/useDebouncedCallback';

type PaginatedListParams = Pick<PaginatedList<string, any>, 'offset' | 'has_more'>;

export type UseInfiniteLoaderOptions<
  TQueryFnData extends PaginatedListParams,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
> = Omit<
  UseInfiniteQueryOptions<TQueryFnData, TError, TData, TQueryFnData, TQueryKey>,
  'getNextPageParam' | 'queryFn'
> & {
  offsetPerPage: number;
  queryFn: (params: { offset: number }) => TQueryFnData | Promise<TQueryFnData>;
};

export const useInfiniteLoader = <
  TQueryFnData extends PaginatedListParams,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  options: UseInfiniteLoaderOptions<TQueryFnData, TError, TData, TQueryKey>,
) => {
  const { offsetPerPage, queryFn, ...restOfOptions } = options;

  const {
    hasNextPage = false,
    fetchNextPage,
    ...restOfInfiniteQuery
  } = useInfiniteQuery<TQueryFnData, TError, TData, TQueryKey>({
    ...restOfOptions,
    queryFn: ({ pageParam = 0 }) => queryFn({ offset: pageParam }),
    getNextPageParam: ({ has_more = false, offset = 0 }) => (has_more ? offset + offsetPerPage : undefined),
  });

  const [debouncedFetchNextPage] = useDebouncedCallback(fetchNextPage, {
    wait: 500,
    leading: true,
  });

  return { hasNextPage, fetchNextPage: debouncedFetchNextPage, ...restOfInfiniteQuery };
};
