import { Fragment } from 'react';
import { Helmet } from 'react-helmet-async';

export type PageContainerBaseProps = React.PropsWithChildren<{
  title: string;
  meta?: React.ReactNode;
}>;

export const PageContainerBase = (props: PageContainerBaseProps) => {
  const { children, title, meta } = props;

  return (
    <Fragment>
      <Helmet>
        <title>{title} | Dorim Base</title>
        {meta}
      </Helmet>
      {children}
    </Fragment>
  );
};
