import { ReactComponent as EcommerceSVG } from '@/assets/icons/navbar/ic_ecommerce.svg';
import { MakersForm } from '@/components/makers/modals/MakersForm';
import { useMakersStoreActions, useMakersStoreContextWindowsState } from '@/components/makers/useMakersStore';
import { Modal, ModalBreadcrumbs, ModalHeader, ModalTabbar } from '@/components/Modal';

// ----------------------------------------------------------------------

export const MakersCreateModal = () => {
  const { isCreateModalVisible } = useMakersStoreContextWindowsState();
  const { closeCreateModal } = useMakersStoreActions();

  return (
    <Modal open={isCreateModalVisible} onClose={closeCreateModal}>
      <ModalHeader onClose={closeCreateModal}>
        <ModalBreadcrumbs
          rootIcon={<EcommerceSVG />}
          breadcrumbs={[{ name: 'Производители' }, { name: 'Создание записи' }]}
        />
      </ModalHeader>

      <ModalTabbar value="general" tabs={[{ label: 'Основная информация', value: 'general' }]} />

      <MakersForm variant="create" onClose={closeCreateModal} />
    </Modal>
  );
};
