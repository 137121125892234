import { Button, ButtonProps } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

type OpenLinkButtonProps = ButtonProps & {
  url?: string;
  title?: string;
};

export const OpenLinkButton = (props: OpenLinkButtonProps) => {
  const { url, title = 'Перейти', ...restButtonProps } = props;

  const handleOpenLink = () => {
    if (url) {
      let modifiedUrl = url;
      if (!url.startsWith('http://') && !url.startsWith('https://')) {
        modifiedUrl = 'https://' + url;
      }

      window.open(modifiedUrl, '_blank');
    }
  };

  return (
    <Button
      variant="outlined"
      color="secondary"
      startIcon={<OpenInNewIcon />}
      onClick={handleOpenLink}
      disabled={!url}
      sx={{ flexShrink: 0 }}
      {...restButtonProps}
    >
      {title}
    </Button>
  );
};
