import { first } from 'lodash';
import { compareString as _compareString } from './grid-filter.helpers';

export const isNumeric = (value: string) => /^\d+$/.test(value);

export const reverseString = (value: string) => {
  if (!value) {
    return value;
  }

  return value.split('').reverse().join('');
};

export const removeLastWord = (value: string) => {
  let replacedValue = '';

  const reversedValue = reverseString(value);
  const arrayReverseValue = reversedValue.split(' ').filter(Boolean);

  if (arrayReverseValue.length > 1) {
    const firstWord = first(arrayReverseValue) ?? '';

    replacedValue = reverseString(reversedValue.replace(firstWord, ''));
  }

  return replacedValue;
};

/**
 * @deprecated Use `import { compareString } from '@/helpers/grid-filter.helpers';` instead
 */
export const compareString = _compareString;

export const isSafeNumeric = (value: string) => Number.isSafeInteger(Number(value.replace(/\D+/g, '')));

// Convert UTF8 string to base64 (from MDN)
export const stringToBase64 = (text: string) => {
  const encodedString = new TextEncoder().encode(text);
  const binString = Array.from(encodedString, byte => String.fromCodePoint(byte)).join('');
  return btoa(binString);
};

// Convert base64 to UTF8 string (from MDN)
export const base64ToString = (base64: string) => {
  const binString = atob(base64);
  const codePoints = Array.from(binString)
    .map(m => m.codePointAt(0))
    .filter((code): code is number => code !== undefined);
  const buffer = Uint8Array.from(codePoints);

  return new TextDecoder().decode(buffer);
};
