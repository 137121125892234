import jwtDecode from 'jwt-decode';

// ----------------------------------------------------------------------

export const getUnixTimestamp = (): number => Math.round(Date.now() / 1000);

// ----------------------------------------------------------------------

export const isAccessTokenExpired = (accessToken: string | null): boolean => {
  if (!accessToken) {
    return false;
  }

  try {
    const decoded = jwtDecode<{ exp: number }>(accessToken);
    const currentTime = getUnixTimestamp();

    return currentTime > decoded.exp;
  } catch (error) {
    console.error({ isAccessTokenExpired: error });

    return false;
  }
};

// ----------------------------------------------------------------------

export const isAccessToken = (accessToken: string | null): boolean => {
  if (!accessToken) {
    return false;
  }

  try {
    const decodedHeader = jwtDecode<{ typ: string; alg: string }>(accessToken, { header: true });

    if (!decodedHeader) {
      return false;
    }

    return typeof decodedHeader.typ === 'string' && typeof decodedHeader.alg === 'string';
  } catch (error) {
    console.error({ isAccessToken: error });

    return false;
  }
};
