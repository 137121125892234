// import { useShortcut } from '@/hooks/useShortcut';
import { FilterButton } from '@/components/buttons';
import {
  // useMakersStoreIsContextWindowsVisible,
  selectMakersStoreIsFilterPanelVisible,
  useMakersStore,
  useMakersStoreActions,
} from '@/components/makers/useMakersStore';

// ----------------------------------------------------------------------

export const MakersFilterButton = () => {
  const isFilterPanelVisible = useMakersStore(selectMakersStoreIsFilterPanelVisible);
  // const isContextWindowsVisible = useMakersStoreIsContextWindowsVisible();
  const { showFilterPanel, hideFilterPanel } = useMakersStoreActions();

  const toggleFilterPanel = isFilterPanelVisible ? hideFilterPanel : showFilterPanel;

  // useShortcut(
  //   'ctrl + f',
  //   () => {
  //     if (!isContextWindowsVisible) {
  //       toggleFilterPanel();
  //     }
  //   },
  //   [isContextWindowsVisible],
  // );

  return <FilterButton isActive={isFilterPanelVisible} onClick={toggleFilterPanel} />;
};
