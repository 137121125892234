import { ReactNode, useState } from 'react';
import { useToggle } from 'react-use';
import { Stack, FormControl, CircularProgress, SvgIcon } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FieldValues, type Path, useFormContext } from 'react-hook-form';
import { CustomFormLabel, RHFFileField, RHFFileFieldProps } from '@/components/form-controls';
import {
  ConfirmationDialogActionCancelButton,
  ConfirmationDialogActionProceedButton,
  ConfirmationDialogActions,
  ConfirmationDialogBodyMutedText,
  ConfirmationDialogContainer,
  ConfirmationDialogHeader,
  ImageCropDialog,
  useGlobalErrorStore,
} from '@/components/dialogs';
import { ReactComponent as DeleteSVG } from '@/assets/icons-2.0/delete.svg';
import { useUploadImage } from '@/hooks/useUploadImage';
import { UploadImageParams } from '@/api/domains/image.api';
import { StyledAvatar, StyledAvatarWrapper, StyledIconButton, StyledCircularProgressBox } from './upload-logo.elements';
import { ACCEPTED_IMAGE_FILE_TYPES } from '@/constants/file-type.constants';

const DEFAULT_MAX_AVATAR_SIZE_MB = 5;

type FieldKey = 'url' | 'urlToken' | 'file';

type RHFUploadLogoProps<T extends FieldValues> = {
  prefix: UploadImageParams['prefix'];
  fieldNames: Record<FieldKey, Path<T>>;
  maxMbSize?: number;
  defaultAvatar?: ReactNode;
};

export const RHFUploadLogo = <T extends FieldValues>(props: RHFUploadLogoProps<T>) => {
  const { prefix, maxMbSize = DEFAULT_MAX_AVATAR_SIZE_MB, defaultAvatar = 'А' } = props;
  const fieldNames = props.fieldNames as FieldValues;

  const [imageFile, setImageFile] = useState<File | null>(null);
  const [isEditDialogOpen, toggleIsEditDialogOpen] = useToggle(false);
  const [isDeleteDialogOpen, toggleIsDeleteDialogOpen] = useToggle(false);
  const { isLoading: isUploadingImage, mutateAsync: uploadImage } = useUploadImage();
  const { enqueueSnackbar } = useSnackbar();
  const { setGlobalError } = useGlobalErrorStore();
  const { watch, setValue } = useFormContext();

  const openEditDialog = () => toggleIsEditDialogOpen(true);
  const closeEditDialog = () => toggleIsEditDialogOpen(false);
  const openDeleteDialog = () => toggleIsDeleteDialogOpen(true);
  const closeDeleteDialog = () => toggleIsDeleteDialogOpen(false);

  const logoUrl = watch(fieldNames.url);

  const handleLogoFileFieldChange: RHFFileFieldProps['onChange'] = file => {
    if (!file) return;

    if (!file.type.includes('image')) {
      enqueueSnackbar('Изображение не загружено.\nНеверный формат файла.', {
        style: { whiteSpace: 'break-spaces' },
        variant: 'error',
      });
      return;
    }

    const DEFAULT_MAX_AVATAR_SIZE_BYTES = maxMbSize * 1024 * 1024;

    if (file.size > DEFAULT_MAX_AVATAR_SIZE_BYTES) {
      enqueueSnackbar(`Загрузите изображение меньше ${maxMbSize}мб.`, { variant: 'error' });
      return;
    }

    setImageFile(file);

    openEditDialog();
  };

  const handleEdit = async (blob: Blob) => {
    try {
      if (imageFile) {
        const file = new File([blob], imageFile.name, { type: `image/${blob.type}` });

        const { token, url } = await uploadImage({ file, prefix });
        setValue(fieldNames.url, url);
        setValue(fieldNames.urlToken, token);
        setValue(fieldNames.file, file);

        closeEditDialog();
      }
    } catch (error) {
      setGlobalError(error);
    }
  };

  const handleDelete = () => {
    setValue(fieldNames.url, '');
    setValue(fieldNames.urlToken, '');
    setValue(fieldNames.file, null);
    closeDeleteDialog();
  };

  return (
    <Stack direction="row" spacing={2} alignItems="end">
      <StyledAvatarWrapper>
        <StyledAvatar className="StyledAvatar" src={logoUrl}>
          {defaultAvatar}
        </StyledAvatar>

        {logoUrl && (
          <StyledIconButton onClick={openDeleteDialog}>
            <SvgIcon component={DeleteSVG} fontSize="inherit" />
          </StyledIconButton>
        )}
        {isUploadingImage && (
          <StyledCircularProgressBox>
            <CircularProgress color="warning" />
          </StyledCircularProgressBox>
        )}
      </StyledAvatarWrapper>

      <FormControl fullWidth>
        <CustomFormLabel htmlFor="logoFile">Логотип</CustomFormLabel>
        <RHFFileField
          fullWidth
          name="logoFile"
          id="logoFile"
          variant="filled"
          placeholder={`SVG, PNG, JPG не более ${maxMbSize}мб`}
          endAdornmentText="Выбрать файл"
          accept={ACCEPTED_IMAGE_FILE_TYPES}
          onChange={handleLogoFileFieldChange}
        />
      </FormControl>

      <ImageCropDialog
        title="Загрузка логотипа"
        open={isEditDialogOpen}
        isLoading={isUploadingImage}
        imageFile={imageFile}
        onClose={closeEditDialog}
        onEdit={handleEdit}
        variant="logo"
      />

      <ConfirmationDialogContainer subject="delete" onClose={closeDeleteDialog} open={isDeleteDialogOpen}>
        <ConfirmationDialogHeader>Подтверждение удаления</ConfirmationDialogHeader>

        <ConfirmationDialogBodyMutedText>Удалить загруженный логотип?</ConfirmationDialogBodyMutedText>

        <ConfirmationDialogActions>
          <ConfirmationDialogActionCancelButton onClick={closeDeleteDialog} />
          <ConfirmationDialogActionProceedButton autoFocus onClick={handleDelete}>
            Удалить
          </ConfirmationDialogActionProceedButton>
        </ConfirmationDialogActions>
      </ConfirmationDialogContainer>
    </Stack>
  );
};
