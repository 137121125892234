import { Stack } from '@mui/material';

import { ModalInfoEditCreated, ModalInfoEditUpdated } from './modal-info.elements';

export type EditModalInfoProps = {
  createdAt: string;
  authorName: string;
  updatedAt?: string;
  initiatorName?: string;
};

export const EditModalInfo = (props: EditModalInfoProps) => {
  const { createdAt, authorName, updatedAt, initiatorName } = props;

  return (
    <Stack spacing={1}>
      <ModalInfoEditCreated authorName={authorName} createdAt={createdAt} />
      {updatedAt && initiatorName && <ModalInfoEditUpdated initiatorName={initiatorName} updatedAt={updatedAt} />}
    </Stack>
  );
};
