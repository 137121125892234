import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import { Label } from '@/components/labels';
import {
  MakersDataGridBase,
  MakersInlineToolbar,
  selectMakersStoreSearchQuery,
  useMakersFilterData,
  useMakersStore,
} from '@/components/makers';
import {
  NamesByRecordStatusId,
  RECORD_STATUS_ACTIVE,
  RECORD_STATUS_NEW,
  RECORD_STATUS_REMOVED,
  RECORD_STATUS_UNKNOWN,
} from '@/constants/record-status.constants';
import { formatDateTime, highlightKeyword } from '@/helpers/format.helpers';

// ----------------------------------------------------------------------

const SearchQueryAwareText = (props: { text: string }) => {
  const { text } = props;

  const searchQuery = useMakersStore(selectMakersStoreSearchQuery);
  const preparedValue = searchQuery ? highlightKeyword(text, searchQuery) : text;

  return <>{preparedValue}</>;
};

// ----------------------------------------------------------------------

export const MakersPageDataGrid = () => {
  const { makerFilterData, countryFilterData, notesFilterData, initiatorFilterData, statusFilterData, idsFilterData } =
    useMakersFilterData();

  return (
    <MakersDataGridBase
      instanceId="makers-catalog-page"
      columns={[
        {
          name: 'id',
          type: 'number',
          defaultWidth: 100,
          textAlign: 'end',
          header: 'ID',
          filterEditor: SelectFilter,
          filterEditorProps: {
            multiple: true,
            wrapMultiple: false,
            placeholder: 'Все',
            dataSource: idsFilterData,
          },
          render: ({ value }) => <SearchQueryAwareText text={String(value)} />,
        },
        {
          name: 'maker',
          minWidth: 400,
          defaultFlex: 1,
          header: 'Производитель',
          filterEditor: SelectFilter,
          filterEditorProps: {
            multiple: true,
            wrapMultiple: false,
            placeholder: 'Все',
            dataSource: makerFilterData,
          },
          render: ({ value, rowIndex, data, setRowSelected }) => {
            const handleRowSelect = (event: React.MouseEvent<HTMLButtonElement>) => {
              event.stopPropagation();
              setRowSelected(rowIndex, data);
            };

            return (
              <MakersInlineToolbar onClick={handleRowSelect}>
                <SearchQueryAwareText text={value} />
              </MakersInlineToolbar>
            );
          },
        },
        {
          name: 'country',
          defaultWidth: 300,
          header: 'Страна',
          filterEditor: SelectFilter,
          filterEditorProps: {
            multiple: true,
            wrapMultiple: false,
            placeholder: 'Все',
            dataSource: countryFilterData,
          },
          render: ({ value }) => <SearchQueryAwareText text={value} />,
        },
        {
          name: 'notes',
          defaultWidth: 300,
          header: 'Примечание',
          filterEditor: SelectFilter,
          filterEditorProps: {
            multiple: true,
            wrapMultiple: false,
            placeholder: 'Все',
            dataSource: notesFilterData,
          },
        },
        {
          name: 'updateDate',
          defaultWidth: 250,
          type: 'date',
          dateFormat: 'DD-MM-YYYY',
          width: 280,
          textAlign: 'end',
          header: 'Дата изменения',
          filterEditor: DateFilter,
          filterEditorProps: (_props: React.ComponentProps<typeof DateFilter>, { index }: { index: number }) => ({
            dateFormat: 'DD.MM.YYYY',
            cancelButton: false,
            highlightWeekends: false,
            placeholder: index === 0 ? 'Дата, от' : 'Дата, до',
          }),
          render: ({ value }) => formatDateTime(value, 'dd.MM.yyyy HH:mm:ss'),
        },
        {
          name: 'initiator',
          defaultWidth: 150,
          header: 'Инициатор',
          filterEditor: SelectFilter,
          filterEditorProps: {
            multiple: true,
            wrapMultiple: false,
            placeholder: 'Все',
            dataSource: initiatorFilterData,
          },
          render: ({ value: initiator }) => {
            if (!initiator) {
              return null;
            }

            return initiator.name;
          },
        },
        {
          name: 'status',
          defaultWidth: 170,
          header: 'Статус',
          filterEditor: SelectFilter,
          filterEditorProps: {
            multiple: true,
            wrapMultiple: false,
            placeholder: 'Все',
            dataSource: statusFilterData,
          },
          render: ({ value }) => {
            const statusName = NamesByRecordStatusId[value];
            const color =
              value === RECORD_STATUS_REMOVED
                ? 'warning'
                : value === RECORD_STATUS_NEW
                ? 'info'
                : value === RECORD_STATUS_ACTIVE
                ? 'secondary'
                : 'default';

            return (
              <Label startIcon="•" color={color}>
                {statusName || RECORD_STATUS_UNKNOWN}
              </Label>
            );
          },
        },
      ]}
    />
  );
};
