import { GalleryImageInfo } from './types';

export const reorderImages = (images: GalleryImageInfo[], startIndex: number, endIndex: number) => {
  const newImages = [...images];
  const [reorderedItem] = newImages.splice(startIndex, 1);
  newImages.splice(endIndex, 0, reorderedItem);

  return newImages.map((image, index) => ({
    ...image,
    position: index,
  }));
};

export const getFirstBigImageWrapperStyle = (index: number) => {
  if (index === 0) {
    return {
      gridRowStart: 'span 2',
      gridColumnStart: 'span 2',
    };
  }

  return {};
};
