import {
  TimePicker as MuiTimePicker,
  type TimePickerProps as MuiTimePickerProps,
  LocalizationProvider,
  type PickerValidDate,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ru from 'date-fns/locale/ru';

export type TimePickerProps<TDate extends PickerValidDate> = MuiTimePickerProps<TDate>;

export const TimePicker = <TDate extends PickerValidDate>(props: TimePickerProps<TDate>) => (
  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ru}>
    <MuiTimePicker {...props} />
  </LocalizationProvider>
);
