import { Button, type ButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export type TreeViewItemTextProps = Omit<ButtonProps, 'disableElevation' | 'variant' | 'className'>;

const StyledRoot = styled(Button)(
  {
    minWidth: 'none',
    minHeight: 'none',
    flexGrow: 1,
    justifyContent: 'flex-start',
    textAlign: 'left',
    textTransform: 'none',
  },
  ({ theme }) => theme.typography.body2,
) as typeof Button;

export const TreeViewItemText = (props: TreeViewItemTextProps) => {
  const { children } = props;

  return (
    <StyledRoot {...props} disableElevation variant="text" color="inherit" className="TreeViewItem-text">
      <span>{children}</span>
    </StyledRoot>
  );
};
