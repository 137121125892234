import { compareAsc } from 'date-fns';
import { isNil } from 'lodash';

import type { SingleSortInfo, SortBy, SortConfig, SortInfo } from './types';

// ----------------------------------------------------------------------

const sortDataSource = <TSource extends object, TExtractedData extends {}>(
  sourceData: Array<TSource>,
  extractItem: (sourceItem: TSource) => TExtractedData | null | undefined,
  compare: (a: NonNullable<TExtractedData>, b: NonNullable<TExtractedData>) => number,
): Array<TSource> => {
  if (sourceData.length === 0) {
    return sourceData;
  }

  return [...sourceData].sort((a, b) => {
    const sortableItemA = extractItem(a);
    const sortableItemB = extractItem(b);

    if (isNil(sortableItemA) || isNil(sortableItemB)) {
      return 0;
    }

    return compare(sortableItemA, sortableItemB);
  });
};

// ----------------------------------------------------------------------

const sortByString = <TSource extends object>(
  sourceData: Array<TSource>,
  extractItem: (sourceItem: TSource) => string | null | undefined,
  sortInfo: SingleSortInfo<TSource>,
): Array<TSource> => sortDataSource(sourceData, extractItem, (a, b) => a.localeCompare(b) * sortInfo.dir);

// ----------------------------------------------------------------------

const sortByNumber = <TSource extends object>(
  sourceData: Array<TSource>,
  extractItem: (sourceItem: TSource) => number | null | undefined,
  sortInfo: SingleSortInfo<TSource>,
): Array<TSource> => sortDataSource(sourceData, extractItem, (a, b) => (a - b) * sortInfo.dir);

// ----------------------------------------------------------------------

const sortByDate = <TSource extends object>(
  sourceData: Array<TSource>,
  extractItem: (sourceItem: TSource) => Date | null | undefined,
  sortInfo: SingleSortInfo<TSource>,
): Array<TSource> => sortDataSource(sourceData, extractItem, (a, b) => compareAsc(a, b) * sortInfo.dir);

// ----------------------------------------------------------------------

const sortBy: SortBy<unknown> = {
  string: extractItem => ({ type: 'string', extractItem }),
  number: extractItem => ({ type: 'number', extractItem }),
  date: extractItem => ({ type: 'date', extractItem }),
};

export const createSortFunction = <TSource extends object>(config: SortConfig<TSource>) => {
  const sort = (sourceData: Array<TSource>, sortInfo: SingleSortInfo<TSource>) => {
    const { name } = sortInfo;
    const getSortConfig = config[name];

    if (!getSortConfig) {
      return sourceData;
    }

    const { type, extractItem } = getSortConfig(sortBy);

    if (type === 'string') {
      return sortByString(sourceData, sourceItem => extractItem(sourceItem[name]), sortInfo);
    }
    if (type === 'number') {
      return sortByNumber(sourceData, sourceItem => extractItem(sourceItem[name]), sortInfo);
    }
    if (type === 'date') {
      return sortByDate(sourceData, sourceItem => extractItem(sourceItem[name]), sortInfo);
    }

    throw new Error(`Unknown sort type: ${type}`);
  };

  return (sourceData: Array<TSource>, sortInfo: SortInfo<TSource> | null) => {
    if (!sortInfo) {
      return sourceData;
    }

    if (Array.isArray(sortInfo)) {
      return sortInfo.reduce((sortedData, sortInfoItem) => sort(sortedData, sortInfoItem), sourceData);
    }

    return sort(sourceData, sortInfo);
  };
};
