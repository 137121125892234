import { Outlet } from 'react-router';
import { Card, styled } from '@mui/material';

import { Header } from '@/layouts/Header';
import { PageContainer, PageContent } from '@/layouts/Page';
import { LayoutTabs } from '@/layouts/EimzoLayouts/LayoutTabs';

const StyledRoot = styled(Card)({
  position: 'relative',
  height: '100%',
  flexGrow: 1,
}) as typeof Card;

const StyledViewerContainer = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
});

export const EimzoDocumentLayout = () => (
  <PageContainer title="Dorim.Promo • Документы для подписи">
    <Header hasBreadcrumbs />
    <LayoutTabs />

    <PageContent>
      <StyledRoot variant="outlined">
        <StyledViewerContainer>
          <Outlet />
        </StyledViewerContainer>
      </StyledRoot>
    </PageContent>
  </PageContainer>
);
