import { createContext, useRef, useContext } from 'react';
import { useUnmount } from 'react-use';
import { useStore } from 'zustand';
import { useStoreWithEqualityFn } from 'zustand/traditional';
import { shallow } from 'zustand/shallow';

import type { CreateStoreResult } from '@/libs/zustand';
import { createModalTabbarStore, type ModalTabbarStore } from './modal-tabbar.store';

export const ModalTabbarStoreContext = createContext<CreateStoreResult<ModalTabbarStore> | null>(null);

export type ModalTabbarStoreProviderProps = React.PropsWithChildren<{}>;

export const ModalTabbarStoreProvider = (props: ModalTabbarStoreProviderProps) => {
  const { children } = props;

  const storeRef = useRef<CreateStoreResult<ModalTabbarStore>>();
  if (!storeRef.current) {
    storeRef.current = createModalTabbarStore();
  }

  return <ModalTabbarStoreContext.Provider value={storeRef.current}>{children}</ModalTabbarStoreContext.Provider>;
};

const useModalTabbarStore = () => {
  const context = useContext(ModalTabbarStoreContext);

  if (!context) {
    throw new Error(`useModalTabbarStore must be used inside a ModalTabbarStoreProvider`);
  }

  const [store] = context;

  return store;
};

const useModalTabbarResetStore = () => {
  const context = useContext(ModalTabbarStoreContext);

  if (!context) {
    throw new Error(`useModalTabbarResetStore must be used inside a ModalTabbarStoreProvider`);
  }

  const [, resetStore] = context;

  return resetStore;
};

export const useShouldInterceptTabChange = () =>
  useStore(useModalTabbarStore(), store => store.shouldInterceptTabChange);
export const useDialogType = () => useStore(useModalTabbarStore(), store => store.dialogType);
export const useStoreActions = () => useStoreWithEqualityFn(useModalTabbarStore(), store => store.actions, shallow);
export const useDialogProps = () => useStoreWithEqualityFn(useModalTabbarStore(), store => store.dialogProps, shallow);
export const useResetStoreOnUnmount = () => useUnmount(useModalTabbarResetStore());
