import { useLocation } from 'react-router-dom';
import { useAuthState } from '@/contexts/AuthContext';
import { RedirectWithoutAccess, RedirectWithoutAccessProps } from '@/guards/RedirectWithoutAccess';
import { AuthPaths } from '@/router/paths/auth.paths';

// ----------------------------------------------------------------------

type AuthGuardProps = Pick<RedirectWithoutAccessProps, 'children'>;

// ----------------------------------------------------------------------

export const AuthGuard = (props: AuthGuardProps) => {
  const { children } = props;

  const { pathname: from } = useLocation();
  const { isAuthenticated } = useAuthState();

  return (
    <RedirectWithoutAccess hasAccess={isAuthenticated} to={AuthPaths.SIGN_IN_PATH} state={{ from }}>
      {children}
    </RedirectWithoutAccess>
  );
};
