import { LabelColor } from '@/components/labels';

export type RecordStatusId =
  | typeof RECORD_STATUS_REMOVED
  | typeof RECORD_STATUS_DEDUPLICATED
  | typeof RECORD_STATUS_BLOCKED
  | typeof RECORD_STATUS_NEW
  | typeof RECORD_STATUS_NOT_CHECKED
  | typeof RECORD_STATUS_ACTIVE
  | typeof RECORD_STATUS_REVIEWED
  | typeof RECORD_STATUS_TO_BE_REMOVED
  | typeof RECORD_STATUS_PARTIALLY_REVIEWED;

export const RECORD_STATUS_REMOVED = 0;
export const RECORD_STATUS_DEDUPLICATED = 10;
export const RECORD_STATUS_BLOCKED = 20;
export const RECORD_STATUS_NEW = 100;
export const RECORD_STATUS_NOT_CHECKED = 105;
export const RECORD_STATUS_BINDING_TO_CANCEL = 120;
export const RECORD_STATUS_ACTIVE = 200;
export const RECORD_STATUS_PARTIALLY_REVIEWED = 205;
export const RECORD_STATUS_REVIEWED = 210;
export const RECORD_STATUS_TO_BE_REMOVED = 215;
export const RECORD_STATUS_UNKNOWN = 'Unknown';

export const NamesByRecordStatusId = {
  [RECORD_STATUS_REMOVED]: 'Удален',
  [RECORD_STATUS_DEDUPLICATED]: 'Дедуплицирован',
  [RECORD_STATUS_BLOCKED]: 'Заблокирован',
  [RECORD_STATUS_NEW]: 'Новый',
  [RECORD_STATUS_NOT_CHECKED]: 'Не проверен',
  [RECORD_STATUS_ACTIVE]: 'Активен',
  [RECORD_STATUS_PARTIALLY_REVIEWED]: 'Частично проверен',
  [RECORD_STATUS_REVIEWED]: 'Проверен',
  [RECORD_STATUS_TO_BE_REMOVED]: 'На удаление',
} as const;

export const RecordColorByStatusId: Record<RecordStatusId, LabelColor> = {
  [RECORD_STATUS_REMOVED]: 'default',
  [RECORD_STATUS_DEDUPLICATED]: 'default',
  [RECORD_STATUS_BLOCKED]: 'default',
  [RECORD_STATUS_NEW]: 'warning',
  [RECORD_STATUS_NOT_CHECKED]: 'default',
  [RECORD_STATUS_ACTIVE]: 'success',
  [RECORD_STATUS_PARTIALLY_REVIEWED]: 'tetriary',
  [RECORD_STATUS_REVIEWED]: 'tetriary',
  [RECORD_STATUS_TO_BE_REMOVED]: 'error',
};
