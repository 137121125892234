import { Stack } from '@mui/material';
import { ReactComponent as EmptySearchListSVG } from '@/assets/icons/common/empty_search_list.svg';

export type EmptyTextProps = React.PropsWithChildren<{
  icon?: React.ReactNode;
}>;

export const DataGridEmptyText = (props: EmptyTextProps) => {
  const { icon = <EmptySearchListSVG />, children } = props;

  return (
    <Stack alignItems="center">
      {icon}
      {children}
    </Stack>
  );
};
