import { lazy } from 'react';

// ----------------------------------------------------------------------

const MakersPage = lazy(
  () =>
    import(
      /* webpackPreload: true */
      /* webpackChunkName: "MakersPage" */
      '@/pages/makers/MakersPage'
    ),
);

export default MakersPage;
