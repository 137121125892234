import { useMemo } from 'react';
import { useLatest, useUnmount } from 'react-use';
import { debounce, type DebouncedFunc, type DebounceSettings } from 'lodash';

type UseDebouncedCallbackReturn<TCallback extends (...args: any) => any> = [
  DebouncedFunc<TCallback>,
  DebouncedFunc<TCallback>['cancel'],
];

export const useDebouncedCallback = <TCallback extends (...args: any) => any>(
  callback: TCallback,
  settings: DebounceSettings & { wait: number },
  deps: React.DependencyList = [],
): UseDebouncedCallbackReturn<TCallback> => {
  const { wait, ...options } = settings;

  const latestCallback = useLatest<TCallback>(callback);
  const debouncedCallback = useMemo(
    () =>
      debounce(
        // @ts-ignore
        (...args: Parameters<TCallback>): ReturnType<TCallback> => latestCallback.current(...args),
        wait,
        options,
      ),

    deps,
  );

  const { cancel } = debouncedCallback;

  useUnmount(cancel);

  return [debouncedCallback, cancel];
};
