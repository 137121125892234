import { useRef, useState, useTransition } from 'react';
import { useAutocomplete } from '@mui/base';
import type { Instance } from '@popperjs/core';

type UseClassificationFieldPopperOptions = {
  onPopperOpen?: () => void;
  onPopperClose?: () => void;
};

export const useClassificationFieldPopper = (options?: UseClassificationFieldPopperOptions) => {
  const [isOpen, setIsOpen] = useState(false);

  const openPopper = () => {
    if (!isOpen) {
      setIsOpen(true);
      options?.onPopperOpen?.();
    }
  };

  const closePopper = () => {
    if (isOpen) {
      setIsOpen(false);
      options?.onPopperClose?.();
    }
  };

  const popperRef = useRef<Instance | null>(null);
  const [, startTransition] = useTransition();

  const updatePopperPosition = () => {
    const popperInstance = popperRef.current;

    if (popperInstance) {
      startTransition(() => {
        popperInstance.update();
      });
    }
  };

  // useAutocomplete is used to control the behavior of the popper
  const {
    getRootProps,
    getInputProps,
    getPopupIndicatorProps,
    getListboxProps,
    focused: isFocused,
    anchorEl,
    setAnchorEl,
  } = useAutocomplete({
    open: isOpen,
    options: [],
    onClose: closePopper,
    onOpen: openPopper,
  });

  return {
    isOpen,
    isFocused,
    popperRef,
    anchorEl,
    setAnchorEl,
    getRootProps,
    getInputProps,
    getPopupIndicatorProps,
    getListboxProps,
    openPopper,
    closePopper,
    updatePopperPosition,
  };
};
