import { Divider, Stack, type StackProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledRoot = styled(Stack)(({ theme }) => ({
  minHeight: theme.dimensions.dataGrid.toolbar.minHeight,
  borderBottom: theme.shape.divider,
  overflowX: 'auto',

  '& > *': {
    flexShrink: 0,
  },
})) as typeof Stack;

export type DataGridToolbarProps = StackProps & {
  actions?: React.ReactNode;
  slotProps?: {
    innerContainer?: Omit<StackProps, 'children'>;
    actionsContainer?: Omit<StackProps, 'children'>;
  };
};

export const DataGridToolbar = (props: DataGridToolbarProps) => {
  const { children, actions, slotProps, ...restOfProps } = props;

  return (
    <StyledRoot direction="row" p={1} alignItems="center" {...restOfProps}>
      {children && (
        <Stack
          direction="row"
          spacing={1}
          flexGrow={1}
          mr={1}
          alignItems="center"
          divider={<Divider orientation="vertical" flexItem />}
          {...slotProps?.innerContainer}
        >
          {children}
        </Stack>
      )}
      {actions && (
        <Stack direction="row" spacing={1} alignItems="center" ml="auto" {...slotProps?.actionsContainer}>
          {actions}
        </Stack>
      )}
    </StyledRoot>
  );
};
