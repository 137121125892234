import { Link as RouterLink, To as RouterLinkTo } from 'react-router-dom';
import { ArrowRight as ArrowRightIcon } from '@mui/icons-material';
import { Stack, StackTypeMap, SvgIcon, Typography } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { styled } from '@mui/material/styles';
import { bindHover, PopupState } from 'material-ui-popup-state/hooks';
import { getLastPathElement } from '@/helpers/router.helpers';

// ----------------------------------------------------------------------

type StyledProps = {
  isActive?: boolean;
  isPopupOpen?: boolean;
};

export type SidebarButtonProps = Omit<StyledProps, 'isPopupOpen'> & {
  hasSubmenu: boolean;
  iconComponent: React.ComponentType;
  to: RouterLinkTo;
  title: string;
  popupState: PopupState;
  'data-testid'?: string;
};

// ----------------------------------------------------------------------

const StyledRoot = styled(Stack, {
  shouldForwardProp: propName => propName !== 'isActive' && propName !== 'isPopupOpen',
})<StyledProps>(
  ({ theme }) => ({
    width: '100%',
    padding: theme.spacing(1, 0),
    borderRadius: theme.spacing(0.5),
    textDecoration: 'none',
    color: '#637381',
    transition: theme.transitions.create(['color', 'backgroundColor']),

    ':hover': {
      backgroundColor: '#E6EBF0',
    },

    '.SidebarButtonIconsContainer': {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    '.SidebarButtonMainIconContainer': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 32,
      height: 32,
      borderRadius: '50%',
      backgroundColor: '#F6F7F8',
      transition: theme.transitions.create(['color', 'backgroundColor']),
    },

    '.SidebarButtonMainIcon': {
      width: 24,
      height: 24,
    },

    '.SidebarButtonCaretIcon': {
      position: 'absolute',
      top: '50%',
      right: 0,
      transform: 'translateY(-50%)',
      width: 24,
      height: 24,
      color: '#9AA7B5',
    },
  }),
  ({ isActive, theme }) =>
    isActive && {
      '.SidebarButtonMainIconContainer': {
        backgroundColor: theme.palette.custom.blue,
      },

      '.SidebarButtonMainIcon': {
        color: '#ffffff',
      },
    },
  ({ isPopupOpen }) =>
    isPopupOpen && {
      backgroundColor: '#E6EBF0',
    },
) as OverridableComponent<StackTypeMap<StyledProps>>;

// ----------------------------------------------------------------------

export const SidebarButton = (props: SidebarButtonProps) => {
  const { isActive, hasSubmenu, iconComponent, to, title, popupState } = props;

  return (
    <StyledRoot
      isActive={isActive}
      isPopupOpen={popupState.isOpen}
      component={RouterLink}
      to={to}
      spacing={0.5}
      data-testid={`sidebar-menu-${getLastPathElement(to)}`}
      {...bindHover(popupState)}
    >
      <div className="SidebarButtonIconsContainer">
        <div className="SidebarButtonMainIconContainer">
          <SvgIcon component={iconComponent} inheritViewBox className="SidebarButtonMainIcon" />
        </div>

        {hasSubmenu && <ArrowRightIcon className="SidebarButtonCaretIcon" />}
      </div>
      <Typography component="span" fontSize={10} align="center">
        {title}
      </Typography>
    </StyledRoot>
  );
};
