import { LabelColor } from '@/components/labels';

export type PromoContractStatusId =
  | typeof PROMO_CONTRACT_STATUS_REMOVED
  | typeof PROMO_CONTRACT_STATUS_CANCELLED
  | typeof PROMO_CONTRACT_STATUS_DRAFT
  | typeof PROMO_CONTRACT_STATUS_WILL_ACTIVE
  | typeof PROMO_CONTRACT_STATUS_ACTIVE
  | typeof PROMO_CONTRACT_STATUS_PAYMENT_CALCULATION
  | typeof PROMO_CONTRACT_STATUS_PAYMENT_EXPECTED
  | typeof PROMO_CONTRACT_STATUS_CLARIFICATION
  | typeof PROMO_CONTRACT_STATUS_PAYMENT_CALCULATED
  | typeof PROMO_CONTRACT_STATUS_PAYMENT_COMPLETED
  | typeof PROMO_CONTRACT_STATUS_PAYMENT_ARCHIVAL
  | typeof PROMO_CONTRACT_STATUS_NOT_CONFIRMED;

export const PROMO_CONTRACT_STATUS_REMOVED = 0;
export const PROMO_CONTRACT_STATUS_CANCELLED = 30;
export const PROMO_CONTRACT_STATUS_DRAFT = 50;
export const PROMO_CONTRACT_STATUS_WILL_ACTIVE = 275;
export const PROMO_CONTRACT_STATUS_ACTIVE = 280;
export const PROMO_CONTRACT_STATUS_PAYMENT_CALCULATION = 285;
export const PROMO_CONTRACT_STATUS_PAYMENT_EXPECTED = 286;
export const PROMO_CONTRACT_STATUS_CLARIFICATION = 287;
export const PROMO_CONTRACT_STATUS_PAYMENT_CALCULATED = 290;
export const PROMO_CONTRACT_STATUS_PAYMENT_COMPLETED = 295;
export const PROMO_CONTRACT_STATUS_PAYMENT_ARCHIVAL = 300;
export const PROMO_CONTRACT_STATUS_NOT_CONFIRMED = 510;
export const PROMO_CONTRACT_STATUS_UNKNOWN = 'Unknown';

export const PromoContractStatusNameByStatusId = {
  [PROMO_CONTRACT_STATUS_WILL_ACTIVE]: 'Будет активен',
  [PROMO_CONTRACT_STATUS_ACTIVE]: 'Активен',
  [PROMO_CONTRACT_STATUS_PAYMENT_CALCULATION]: 'Подсчет выплаты',
  [PROMO_CONTRACT_STATUS_PAYMENT_CALCULATED]: 'Выплата подсчитана',
  [PROMO_CONTRACT_STATUS_PAYMENT_EXPECTED]: 'Ожидается выплата',
  [PROMO_CONTRACT_STATUS_PAYMENT_COMPLETED]: 'Выплата проведена',
  [PROMO_CONTRACT_STATUS_CANCELLED]: 'Отменен',
  [PROMO_CONTRACT_STATUS_PAYMENT_ARCHIVAL]: 'Архивный',
  [PROMO_CONTRACT_STATUS_REMOVED]: 'Удален',
  [PROMO_CONTRACT_STATUS_DRAFT]: 'Черновик',
  [PROMO_CONTRACT_STATUS_CLARIFICATION]: 'Уточнение',
  [PROMO_CONTRACT_STATUS_NOT_CONFIRMED]: 'Не подтвержден',
};

export const PromoContractColorByStatusId: Record<PromoContractStatusId, LabelColor> = {
  [PROMO_CONTRACT_STATUS_REMOVED]: 'error',
  [PROMO_CONTRACT_STATUS_CANCELLED]: 'default',
  [PROMO_CONTRACT_STATUS_DRAFT]: 'warning',
  [PROMO_CONTRACT_STATUS_WILL_ACTIVE]: 'primary',
  [PROMO_CONTRACT_STATUS_ACTIVE]: 'success',
  [PROMO_CONTRACT_STATUS_PAYMENT_CALCULATION]: 'warning',
  [PROMO_CONTRACT_STATUS_PAYMENT_EXPECTED]: 'warning',
  [PROMO_CONTRACT_STATUS_CLARIFICATION]: 'warning',
  [PROMO_CONTRACT_STATUS_PAYMENT_CALCULATED]: 'tetriary',
  [PROMO_CONTRACT_STATUS_PAYMENT_COMPLETED]: 'success',
  [PROMO_CONTRACT_STATUS_PAYMENT_ARCHIVAL]: 'default',
  [PROMO_CONTRACT_STATUS_NOT_CONFIRMED]: 'default',
};

export const PromoContractsSelectableStatusesIds: PromoContractStatusId[] = [
  PROMO_CONTRACT_STATUS_PAYMENT_EXPECTED,
  PROMO_CONTRACT_STATUS_CLARIFICATION,
  PROMO_CONTRACT_STATUS_PAYMENT_CALCULATION,
];

export const TOTAL_PAYMENT_STATUSES: PromoContractStatusId[] = [
  PROMO_CONTRACT_STATUS_PAYMENT_CALCULATED,
  PROMO_CONTRACT_STATUS_PAYMENT_EXPECTED,
  PROMO_CONTRACT_STATUS_PAYMENT_COMPLETED,
  PROMO_CONTRACT_STATUS_CLARIFICATION,
  PROMO_CONTRACT_STATUS_PAYMENT_ARCHIVAL,
];

export const PROMO_CONTRACTS_FILTER_STATUSES: PromoContractStatusId[] = [
  PROMO_CONTRACT_STATUS_NOT_CONFIRMED,
  PROMO_CONTRACT_STATUS_WILL_ACTIVE,
  PROMO_CONTRACT_STATUS_ACTIVE,
  PROMO_CONTRACT_STATUS_PAYMENT_CALCULATION,
  PROMO_CONTRACT_STATUS_PAYMENT_CALCULATED,
  PROMO_CONTRACT_STATUS_PAYMENT_EXPECTED,
  PROMO_CONTRACT_STATUS_PAYMENT_COMPLETED,
  PROMO_CONTRACT_STATUS_CANCELLED,
  PROMO_CONTRACT_STATUS_PAYMENT_ARCHIVAL,
];
