import { useMemo } from 'react';
import { matchPath, resolvePath, To, useLocation } from 'react-router-dom';

export const useMatchLinks = (...links: Array<To>) => {
  const location = useLocation();
  const paths = useMemo(() => links.map(link => resolvePath(link)), [links]);

  return paths.find(path =>
    matchPath(
      {
        path: path.pathname,
        end: false,
      },
      location.pathname,
    ),
  );
};
