import { useModalTabContentContext } from './ModalTabContentProvider';

export type ModalTabContentProps = React.PropsWithChildren<{
  value: string;
}>;

export const ModalTabContent = (props: ModalTabContentProps) => {
  const { value, children } = props;

  const selectedTab = useModalTabContentContext();

  if (selectedTab !== value) {
    return null;
  }

  return children;
};
