import { alpha, Avatar, Box, styled } from '@mui/material';
import IconButton from '@mui/material/IconButton';

export const StyledAvatar = styled(Avatar)(({ theme: { palette }, src }) => ({
  width: 48,
  height: 48,
  bottom: 0,
  padding: '2px',
  border: `2px solid ${palette.custom.grey}`,
  borderRadius: '50%',
  backgroundColor: src ? palette.background.paper : palette.custom.grey,
  fontWeight: 700,

  '.MuiAvatar-img': {
    borderRadius: '50%',
  },
}));

export const StyledIconButton = styled(IconButton)(({ theme: { palette } }) => ({
  zIndex: 2,
  width: 28,
  height: 28,
  position: 'absolute',
  display: 'none',
  color: '#FFFFFF',
  right: '10px',
  top: '10px',
  backgroundColor: palette.error.main,

  '&:hover': {
    backgroundColor: palette.error.main,
    boxShadow: alpha(palette.common.black, 0.8),
  },
}));

export const StyledAvatarWrapper = styled(Box)({
  display: 'inline-block',
  position: 'relative',

  '&:hover': {
    '& .StyledAvatar': {
      filter: 'brightness(50%)',
    },
    '& .MuiButtonBase-root': {
      display: 'inline-flex',
    },
  },
});

export const StyledCircularProgressBox = styled(Box)({
  height: '48px',
  width: '48px',
  alignItems: 'center',
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  position: 'absolute',
  top: 0,
});
