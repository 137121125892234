import { useMemo } from 'react';
import { Link as RouterLink, useMatches, type LinkProps as RouterLinkProps } from 'react-router-dom';
import { KeyboardArrowRight } from '@mui/icons-material';
import { Skeleton, Stack, styled, Typography, type StackProps } from '@mui/material';
import { isFunction } from 'lodash';

import { BreadcrumbProvider, useBreadcrumb } from './BreadcrumbProvider';
import type { HandleBreadcrumb } from './breadcrumbs.types';

// ----------------------------------------------------------------------

export type LazyBreadcrumbProps = React.PropsWithChildren<{
  isLoading?: boolean;
}>;

export const LazyBreadcrumb = (props: LazyBreadcrumbProps) => {
  const { isLoading, children } = props;

  if (isLoading) {
    return <Skeleton variant="text" width={200} />;
  }

  return children;
};

// ----------------------------------------------------------------------

const trimStyles = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  transition: 'min-width 3s ease-in-out',

  '&:hover': {
    minWidth: 'max-content',
  },
};

const StyledLink = styled(RouterLink)(({ theme }) => ({
  ...theme.typography['Title/Bold/medium'],
  color: theme.palette.neutral['700'],
  textDecoration: 'none',
  ...trimStyles,

  '&:first-child': {
    overflow: 'visible',
  },

  '&:hover': {
    minWidth: 'max-content',
    textDecoration: 'underline',
  },
}));

export type BreadcrumbLinkProps = Pick<RouterLinkProps, 'to' | 'children'>;

export const BreadcrumbLink = (props: BreadcrumbLinkProps) => {
  const { to, children } = props;

  const { isCurrentPage } = useBreadcrumb();

  if (isCurrentPage) {
    return (
      <Typography color="neutral.900" variant="Title/Bold/medium" sx={{ ...trimStyles }}>
        {children}
      </Typography>
    );
  }

  return <StyledLink to={to}>{children}</StyledLink>;
};

// ----------------------------------------------------------------------

type BreadcrumbsMatch = {
  id: string;
  params: Record<string, string>;
  handle?: Partial<HandleBreadcrumb>;
};

export type BreadcrumbsProps = StackProps;

export const Breadcrumbs = (props: BreadcrumbsProps) => {
  const matches = useMatches() as BreadcrumbsMatch[];

  const breadcrumbs = useMemo(() => {
    const filteredMatches = matches.filter(match => Boolean((match.handle as Partial<HandleBreadcrumb>)?.breadcrumb));

    return filteredMatches.map(match => {
      const { breadcrumb } = match.handle as HandleBreadcrumb;

      return {
        ...match,
        breadcrumb: isFunction(breadcrumb) ? breadcrumb(match.params) : breadcrumb,
      };
    });
  }, [matches]);

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      divider={<KeyboardArrowRight sx={{ color: 'neutral.500' }} />}
      overflow="hidden"
      {...props}
    >
      {breadcrumbs.map(({ id, breadcrumb }, index, { length }) => {
        const { Component, element } = breadcrumb;

        const isCurrentPage = index === length - 1;

        return (
          <BreadcrumbProvider key={id} value={{ isCurrentPage }}>
            {Component && <Component isCurrentPage={isCurrentPage} />}
            {element}
          </BreadcrumbProvider>
        );
      })}
    </Stack>
  );
};
