import { Stack, StackProps } from '@mui/material';
import { ProfileMenu } from '@/modules/profile';
import { ProfilePaths } from '@/pages/profile';
import { HeaderTitle } from './HeaderTitle';
import { HeaderActionStyled, HeaderStyled } from './styles';
import { SupportMenu } from '@/modules/support/components/SupportMenu';
import { Breadcrumbs } from '@/components/Breadcrumbs';

export type HeaderProps = Omit<StackProps, 'component' | 'direction'> & {} & (
    | {
        leftSlot?: React.ReactNode;
        titleText?: React.ReactNode;
        hasBreadcrumbs?: never;
      }
    | {
        leftSlot?: never;
        titleText?: never;
        hasBreadcrumbs: boolean;
      }
  );

export const Header = (props: HeaderProps) => {
  const { children, leftSlot, titleText, hasBreadcrumbs, ...restOfProps } = props;

  return (
    <HeaderStyled component="header" direction="row" spacing={1} {...restOfProps} hasProfile>
      {hasBreadcrumbs && <Breadcrumbs />}
      {!hasBreadcrumbs && (
        <Stack>
          {titleText && <HeaderTitle>{titleText}</HeaderTitle>}
          {leftSlot}
        </Stack>
      )}

      <Stack direction="row" justifyContent="end" sx={{ flex: 1 }}>
        {children}

        <HeaderActionStyled>
          <SupportMenu />
        </HeaderActionStyled>

        <HeaderActionStyled>
          <ProfileMenu routeLink={ProfilePaths.ROOT} />
        </HeaderActionStyled>
      </Stack>
    </HeaderStyled>
  );
};
