// 0 - Удален
// 1 - Деактивирован
// 100 - Новый
// 101 - Черновик
// 201 - Опубликован

// ----------------------------------------------------------------------

export type DocumentStatusId =
  | typeof DOCUMENT_STATUS_REMOVED
  | typeof DOCUMENT_STATUS_DEACTIVATED
  | typeof DOCUMENT_STATUS_NEW
  | typeof DOCUMENT_STATUS_DRAFT
  | typeof DOCUMENT_STATUS_PUBLISHED;

// ----------------------------------------------------------------------

export const DOCUMENT_STATUS_REMOVED = 0;
export const DOCUMENT_STATUS_DEACTIVATED = 1;
export const DOCUMENT_STATUS_NEW = 100;
export const DOCUMENT_STATUS_DRAFT = 101;
export const DOCUMENT_STATUS_PUBLISHED = 201;
export const DOCUMENT_STATUS_UNKNOWN = 'Unknown';

// ----------------------------------------------------------------------

export const NamesByDocumentStatusId = {
  [DOCUMENT_STATUS_REMOVED]: 'Удален',
  [DOCUMENT_STATUS_DEACTIVATED]: 'Деактивирован',
  [DOCUMENT_STATUS_NEW]: 'Новый',
  [DOCUMENT_STATUS_DRAFT]: 'Черновик',
  [DOCUMENT_STATUS_PUBLISHED]: 'Опубликован',
} as const;
