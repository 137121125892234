import { Link } from 'react-router-dom';
import { StatusActionsBar } from '@/components/StatusActionsBar';
import { RoundedButton } from '@/components/buttons';
import { RegistersPaths } from '@/router';
import { useRegistryFile } from '../../shared';

const CloseButton = () => {
  const registryListFile = useRegistryFile();
  const path = registryListFile ? RegistersPaths.BINDING : RegistersPaths.ROOT_PATH;

  return (
    <RoundedButton component={Link} to={path}>
      Закрыть
    </RoundedButton>
  );
};

export const RegistryImportLogActionBar = () => <StatusActionsBar actions={<CloseButton />} />;
