import { usePopupState } from 'material-ui-popup-state/hooks';
import { useActiveRoutes } from '@/hooks/useActiveRoutes';
import { SidebarButton, SidebarButtonProps } from '@/layouts/Sidebar/SidebarButton';
import { SidebarSubmenu } from '@/layouts/Sidebar/SidebarSubmenu';
import { SidebarSubmenuButton } from '@/layouts/Sidebar/SidebarSubmenuButton';

export type SidebarItemProps = Omit<SidebarButtonProps, 'hasSubmenu' | 'popupState'> & {
  submenu?: Array<Pick<SidebarItemProps, 'to' | 'title'>>;
};

export const SidebarItem = (props: SidebarItemProps) => {
  const { iconComponent, to, title, submenu = [] } = props;

  const hasSubmenu = submenu.length > 0;
  const isActiveRoutes = useActiveRoutes(to, ...submenu.map(({ to }) => to));
  const popupState = usePopupState({ variant: 'popover', popupId: 'SidebarSubmenu' });

  return (
    <>
      <SidebarButton
        isActive={isActiveRoutes}
        hasSubmenu={hasSubmenu}
        iconComponent={iconComponent}
        to={to}
        title={title}
        popupState={popupState}
      />

      {hasSubmenu && (
        <SidebarSubmenu popupState={popupState}>
          {submenu.map(({ to, title }) => (
            <SidebarSubmenuButton key={title} to={to} title={title} onClick={popupState.close} />
          ))}
        </SidebarSubmenu>
      )}
    </>
  );
};
