import { Permissions, PermissionsAction } from '@/api/domains/profile.types';
import { LoadingIndicator } from '@/components/LoadingIndicator';
import { usePermissions } from '@/hooks/useCheckPermissions';
import { RedirectWithoutAccess } from '../RedirectWithoutAccess';

type ActionAccessGuardProps = React.PropsWithChildren & {
  entityName: Permissions['name'];
  actionName: PermissionsAction['name'];
};

export const ActionAccessGuard = (props: ActionAccessGuardProps) => {
  const { entityName, actionName, children } = props;

  const { isLoadingPermissions, can } = usePermissions();

  if (isLoadingPermissions) return <LoadingIndicator />;

  return (
    <RedirectWithoutAccess hasAccess={can(actionName, entityName)} to={'/'}>
      {children}
    </RedirectWithoutAccess>
  );
};
