import { useState } from 'react';
import { Controller, ControllerRenderProps, useFormContext } from 'react-hook-form';
import { OnValueChange } from 'react-number-format';
import { MaskedPhoneTextField, MaskedPhoneTextFieldProps } from '@/components/form-controls/MaskedPhoneTextField';
import { ClearAdornment } from './ClearAdornment';

// ----------------------------------------------------------------------

type RHFMaskedPhoneTextFieldProps = Omit<
  MaskedPhoneTextFieldProps,
  'name' | 'label' | 'error' | 'helperText' | keyof ControllerRenderProps
> & {
  name: string;
  isClearableField?: boolean;
  onClear?: VoidFunction;
};

export const RHFMaskedPhoneTextField = (props: RHFMaskedPhoneTextFieldProps) => {
  const { name, onClear, isClearableField = false, ...restTextFieldProps } = props;

  const isClearableFieldVisible = isClearableField && !props.disabled;

  const { control } = useFormContext();
  const [isHover, setIsHover] = useState(false);
  const handleMouseEnter = () => setIsHover(true);
  const handleMouseLeave = () => setIsHover(false);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value, ...fieldProps }, fieldState: { error } }) => {
        const reassignedOnChange: OnValueChange = ({ value }) => {
          onChange(value);
        };

        return (
          <MaskedPhoneTextField
            valueIsNumericString
            error={!!error}
            helperText={error?.message}
            {...fieldProps}
            {...restTextFieldProps}
            onValueChange={reassignedOnChange}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            value={value}
            InputProps={{
              endAdornment: isClearableFieldVisible && (
                <ClearAdornment
                  isClearButtonVisible={value && isHover}
                  onClear={() => {
                    onClear?.();
                    onChange('');
                  }}
                  position="end"
                />
              ),
            }}
          />
        );
      }}
    />
  );
};
