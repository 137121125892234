import { Typography, TypographyProps, TypographyTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { styled } from '@mui/material/styles';

// ----------------------------------------------------------------------

type StyledTypographyProps =
  | {
      noWrap: true;
      lineClamp?: number;
    }
  | {
      noWrap?: false;
      lineClamp?: never;
    };

export type CustomTypographyProps<
  D extends React.ElementType = TypographyTypeMap['defaultComponent'],
  P = {},
> = TypographyProps<D, P> & StyledTypographyProps;

// ----------------------------------------------------------------------

const StyledTypography = styled(Typography, {
  shouldForwardProp: prop => prop !== 'lineClamp',
})<StyledTypographyProps>(
  ({ noWrap, lineClamp }) =>
    noWrap &&
    lineClamp &&
    lineClamp > 1 && {
      display: '-webkit-box',
      whiteSpace: 'normal',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: lineClamp,
    },
) as typeof Typography;

// ----------------------------------------------------------------------

export const CustomTypography: OverridableComponent<TypographyTypeMap<StyledTypographyProps>> = (
  props: CustomTypographyProps,
) => <StyledTypography {...props} />;
