import { useEffect, useState } from 'react';

import { Scrollbar } from '@/components/Scrollbar';
import { Box, Divider, Stack } from '@mui/material';
import { useDeleteControlDrugsDialogStore } from './useDeleteControlDrugsDialogStore';
import { DialogDrugCard } from './DialogDrugCard';
import { WarningInfoDialog } from '../WarningInfoDialog';

const DeleteControlDrugsDialogScrollbarSx = {
  padding: 2,
  maxHeight: 340,
  '@media (max-width: 1280px)': {
    maxHeight: 120,
  },
  '@media (min-width: 1280px) and (max-width: 1920px)': {
    maxHeight: 180,
  },
};

export const DeleteControlDrugsDialog = () => {
  const { deleteControlDrugs, setDeleteControlDrugs } = useDeleteControlDrugsDialogStore();

  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);
  const handleReset = () => setDeleteControlDrugs(null);

  const drugs = deleteControlDrugs?.drugs;
  const count = deleteControlDrugs?.count;

  useEffect(() => {
    if (drugs) {
      handleOpen();
    }
  }, [drugs]);

  if (!drugs) {
    return null;
  }

  return (
    <WarningInfoDialog
      open={isOpen}
      onClose={handleClose}
      TransitionProps={{ onExited: handleReset }}
      title="Удаление запрещено"
      mainText={`На запись ссылаются другие справочники, кол-во записей: ${count}`}
    >
      <Box sx={{ width: '100%', backgroundColor: '#F6F7F8', borderRadius: '8px' }}>
        <Scrollbar autoHide={false} sx={DeleteControlDrugsDialogScrollbarSx}>
          {drugs && (
            <Stack spacing={1} divider={<Divider sx={{ borderStyle: 'dashed' }} flexItem />}>
              {drugs.map(drug => (
                <DialogDrugCard key={drug.id} drug={drug} />
              ))}
            </Stack>
          )}
        </Scrollbar>
      </Box>
    </WarningInfoDialog>
  );
};
