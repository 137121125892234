import { useMakersDataGrid } from '@/components/makers/useMakersDataGrid';

export const useMakersFilterData = () => {
  const { filterData } = useMakersDataGrid();

  const {
    makerFilterData = [],
    countryFilterData = [],
    notesFilterData = [],
    initiatorFilterData = [],
    statusFilterData = [],
    idsFilterData = [],
  } = filterData ?? {};

  return {
    makerFilterData,
    countryFilterData,
    notesFilterData,
    initiatorFilterData,
    statusFilterData,
    idsFilterData,
  };
};
