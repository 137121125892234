import { httpClient } from '@/libs/axios/http-client';
import type { PaginationParams, PaginatedList } from '../@types/pagination.types';
import type { PlanogramTask, PlanogramControlTask, UpdatedBBox } from './planogram.types';
import { PlanogramTaskStatusId } from '@/constants/planogram-task-status.constants';

export type FetchPlanogramControlTasksParams = PaginationParams & {
  date_from?: string;
  date_to?: string;
  record_statuses?: PlanogramTaskStatusId[];
  query?: string;
};

export type FetchPlanogramTasksParams = PaginationParams & {
  templateId: number;
};

export type PlanogramTaskChangeableFields = Pick<
  PlanogramTask,
  | 'marketing_org_id'
  | 'bonus'
  | 'task_text'
  | 'photo_requirements'
  | 'periods'
  | 'photos'
  | 'plan'
  | 'photos_count'
  | 'name'
>;

export type CreatePlanogramTaskParams = {
  templateId: number;
} & PlanogramTaskChangeableFields;

export type UpdatePlanogramTaskParams = {
  templateId: number;
  taskId: number;
} & Partial<PlanogramTaskChangeableFields>;

export type DeletePlanogramTaskParams = {
  templateId: number;
  taskId: number;
};

export type FetchPlanogramTaskParams = {
  templateId: number;
  taskId: number;
};

export type FetchPlanogramControlTaskParams = {
  taskId: number;
};

export type UpdatePlanogramControlTaskParams = {
  taskId: number;
} & Partial<{
  photo_id: number;
  record_status_id: PlanogramTaskStatusId;
  rejected_reasons: string[];
  bboxes: UpdatedBBox[];
}>;

export const PlanogramApi = {
  createPlanogramTask: async ({ templateId, ...payload }: CreatePlanogramTaskParams) => {
    const { data } = await httpClient.post<PlanogramTask>(
      `specification_templates/${templateId}/display-tasks`,
      payload,
    );

    return data;
  },

  updatePlanogramTask: async ({ templateId, taskId, ...payload }: UpdatePlanogramTaskParams) => {
    const { data } = await httpClient.patch<PlanogramTask>(
      `specification_templates/${templateId}/display-tasks/${taskId}`,
      payload,
    );
    return data;
  },

  fetchPlanogramTasks: async ({ templateId, ...params }: FetchPlanogramTasksParams) => {
    const { data } = await httpClient.get<PaginatedList<'items', PlanogramTask>>(
      `specification_templates/${templateId}/display-tasks`,
      { params },
    );

    return data;
  },

  deletePlanogramTask: async ({ templateId, taskId }: DeletePlanogramTaskParams) =>
    httpClient.delete<void>(`specification_templates/${templateId}/display-tasks/${taskId}`),

  fetchPlanogramTask: async ({ templateId, taskId }: FetchPlanogramTaskParams) => {
    const { data } = await httpClient.get<PlanogramTask>(
      `specification_templates/${templateId}/display-tasks/${taskId}`,
    );

    return data;
  },

  fetchPlanogramControlTasks: async (params: FetchPlanogramControlTasksParams) => {
    const { data } = await httpClient.get<PaginatedList<'items', PlanogramControlTask>>(`agreements/display-tasks`, {
      params,
    });

    return data;
  },

  fetchPlanogramControlTask: async ({ taskId }: FetchPlanogramControlTaskParams) => {
    const { data } = await httpClient.get<PlanogramControlTask>(`agreements/display-tasks/${taskId}`);

    return data;
  },

  updatePlanogramControlTask: async ({ taskId, ...payload }: UpdatePlanogramControlTaskParams) => {
    const { data } = await httpClient.put(`agreements/display-tasks/${taskId}`, payload);

    return data;
  },
};
