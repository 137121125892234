export type ContractorTypeAlias =
  | typeof CONTRACTOR_FORM_TYPE_PHARMACY_OFFICE
  | typeof CONTRACTOR_TYPE_PHARMACY_WAREHOUSE
  | typeof CONTRACTOR_TYPE_PHARMACY
  | typeof CONTRACTOR_TYPE_DISTRIBUTOR
  | typeof CONTRACTOR_TYPE_DISTRIBUTOR_BRANCH
  | typeof CONTRACTOR_TYPE_MAKER
  | typeof CONTRACTOR_TYPE_MARKET_ORG
  | typeof CONTRACTOR_TYPE_PHARMACY_OFFICE;

// ----------------------------------------------------------------------

/**
 * Аптечный офис
 */
export const CONTRACTOR_FORM_TYPE_PHARMACY_OFFICE = 'contractor_type.pharmacy_office';
export const CONTRACTOR_TYPE_PHARMACY_OFFICE = 'pharmacy_office';
export const CONTRACTOR_TYPE_PHARMACY_OFFICE_ID = 9;

/**
 * Аптечный склад
 */
export const CONTRACTOR_TYPE_PHARMACY_WAREHOUSE = 'contractor_type.pharmacy_wh';
export const CONTRACTOR_TYPE_PHARMACY_WAREHOUSE_ID = 10;

/**
 * Аптека
 */
export const CONTRACTOR_TYPE_PHARMACY = 'contractor_type.pharmacy';
export const CONTRACTOR_TYPE_PHARMACY_ID = 11;

/**
 * Дистрибьютор
 */
export const CONTRACTOR_FORM_TYPE_DISTRIBUTOR = 'contractor_type.distributor';
export const CONTRACTOR_TYPE_DISTRIBUTOR = 'distributor';
export const CONTRACTOR_TYPE_DISTRIBUTOR_ID = 12;

/**
 * Дистрибьютор (филиал)
 */
export const CONTRACTOR_FORM_TYPE_DISTRIBUTOR_BRANCH = 'contractor_type.distributor_branch';
export const CONTRACTOR_TYPE_DISTRIBUTOR_BRANCH = 'distributor_branch';
export const CONTRACTOR_TYPE_DISTRIBUTOR_BRANCH_ID = 13;

/**
 * Производитель
 */
export const CONTRACTOR_TYPE_MAKER = 'contractor_type.maker';

/**
 * Маркетирующая организация
 */

export const CONTRACTOR_FORM_TYPE_MARKET_ORG = 'contractor_type.market_org';
export const CONTRACTOR_TYPE_MARKET_ORG = 'market_org' as const;

/**
 * Список типов дистрибьюторов
 */
export const DISTRIBUTOR_ALIASES = [CONTRACTOR_TYPE_DISTRIBUTOR, CONTRACTOR_TYPE_DISTRIBUTOR_BRANCH] as const;
export const DISTRIBUTOR_IDS = [CONTRACTOR_TYPE_DISTRIBUTOR_ID, CONTRACTOR_TYPE_DISTRIBUTOR_BRANCH_ID];
