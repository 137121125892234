import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactComponent as HelpSVG } from '@/assets/icons-2.0/help.svg';

type Props = {
  width: number;
  isAura?: boolean;
};

export const StyledSupportMenu = styled(Stack)(() => ({
  alignItems: 'center',
  flexShrink: 0,
  cursor: 'pointer',
  padding: 6,

  '&:hover': {
    borderRadius: '50%',
    backgroundColor: '#E6EBF0',
  },
})) as typeof Stack;

export const StyledSupportMenuIcon = styled(HelpSVG)<Props>(({ theme: { palette }, width }) => ({
  color: palette.common.white,
  height: width,
  width,
}));

export const StyledSupportMenuAura = styled(Stack, { shouldForwardProp: prop => prop !== 'isAura' })<Props>(
  ({ theme: { palette }, width, isAura }) => ({
    alignItems: 'center',
    height: width,
    justifyContent: 'center',
    width,

    '&:before': {
      borderRadius: '50%',
      boxShadow: `0 0 0 2px ${palette.common.white}`,
      content: isAura ? '""' : undefined,
      height: width,
      position: 'absolute',
      width,
    },

    '&:after': {
      borderRadius: '50%',
      boxShadow: `0 0 0 2px ${palette.tetriary['-1']}`,
      content: isAura ? '""' : undefined,
      height: width + 4,
      position: 'absolute',
      width: width + 4,
    },
  }),
);
