import {
  ConfirmationDialogActionCancelButton,
  ConfirmationDialogActionProceedButton,
  ConfirmationDialogActions,
  ConfirmationDialogContainer,
  ConfirmationDialogContainerProps,
  ConfirmationDialogHeader,
} from '@/components/dialogs/elements.confirmation-dialog';

// ----------------------------------------------------------------------

export type ConfirmationDeleteDialogProps = Omit<ConfirmationDialogContainerProps, 'subject'> & {
  isLoading: boolean;
  onProceed: VoidFunction;
};

// ----------------------------------------------------------------------

export const ConfirmationDeleteDialog = (props: ConfirmationDeleteDialogProps) => {
  const { isLoading, onClose, onProceed, children, ...restOfDialogProps } = props;

  return (
    <ConfirmationDialogContainer subject="delete" onClose={onClose} {...restOfDialogProps}>
      <ConfirmationDialogHeader>Вы уверены что хотите удалить?</ConfirmationDialogHeader>

      {children}

      <ConfirmationDialogActions>
        <ConfirmationDialogActionCancelButton onClick={onClose} />
        <ConfirmationDialogActionProceedButton autoFocus loading={isLoading} color="error" onClick={onProceed}>
          Удалить
        </ConfirmationDialogActionProceedButton>
      </ConfirmationDialogActions>
    </ConfirmationDialogContainer>
  );
};
