import { lazy } from 'react';

export const PlanogramTasksControlPage = lazy(
  () =>
    import(
      /* webpackPreload: true */
      /* webpackChunkName: "PlanogramTasksControlPage" */
      './PlanogramTasksControlPage'
    ),
);
