import { ActionButton, ActionButtonProps } from '@/components/buttons';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';

export type ImportActionButtonProps = ActionButtonProps;

export const ImportActionButton = (props: ImportActionButtonProps) => {
  const { children = 'Импорт' } = props;

  return (
    <ActionButton startIcon={<FileUploadOutlinedIcon fontSize="small" />} {...props}>
      {children}
    </ActionButton>
  );
};
