import { lazy } from 'react';

// ----------------------------------------------------------------------

const DistributorReportsPage = lazy(
  () =>
    import(
      /* webpackPreload: true */
      /* webpackChunkName: "DistributorReportsPage" */
      '@/pages/distributor-reports/DistributorReportsPage'
    ),
);

export default DistributorReportsPage;
