import { useEffect } from 'react';
import { useEffectOnce, useLatest, useUpdateEffect } from 'react-use';
import { type UseFormHandleSubmit, type UseFormReturn } from 'react-hook-form';

import { useResetStoreOnUnmount, useStoreActions } from './ModalTabbarProvider';

type FormMethods = UseFormReturn<any>;
type SubmitCallback = Parameters<UseFormHandleSubmit<any>>[0];

type UseModalTabChangeInterceptorOptions = {
  type: 'create' | 'edit';
  methods: FormMethods;
  submitHandler: SubmitCallback;
};

export const useModalTabChangeInterceptor = (options: UseModalTabChangeInterceptorOptions) => {
  const { type, methods, submitHandler } = options;

  const {
    handleSubmit,
    formState: { isSubmitting, isDirty },
  } = methods;

  const { initDialog, setDialogProps, closeDialog, changeTabIfNeeded, setShouldInterceptTabChange } = useStoreActions();

  useEffectOnce(() => {
    initDialog(type);

    if (type === 'create') {
      setShouldInterceptTabChange(true);
    }
  });

  useUpdateEffect(() => {
    if (type !== 'edit') {
      return;
    }

    setShouldInterceptTabChange(isDirty);
  }, [isDirty]);

  const latestSubmit = useLatest(
    handleSubmit(async (...rest) => {
      await submitHandler(...rest);
      closeDialog();
    }, closeDialog),
  );

  useEffect(() => {
    setDialogProps({
      isLoading: isSubmitting,
      onProceed: () => latestSubmit.current(),
    });
  }, [isSubmitting]);

  useResetStoreOnUnmount();

  return { changeTabIfNeeded };
};
