import { PaginatedList, PaginationParams } from '@/api/@types/pagination.types';
import { Classification, ClassificationAlias, ClassificationWithChildren } from '@/api/domains/classifications.types';
import { httpClient } from '@/libs/axios/http-client';

// ----------------------------------------------------------------------

export type ClassificationsParams = PaginationParams & {
  alias?: ClassificationAlias;
  parent_id?: number;
  release_form_id?: number;
};

// ----------------------------------------------------------------------

export type ClassificationsSearchParams = {
  alias?: string;
  query?: string;
};

// ----------------------------------------------------------------------

export type ClassificationsFormData = {
  parent_id: number;
  name: string;
  description: string;
  alias: string;
};

// ----------------------------------------------------------------------

export type ClassificationsDeleteParams = {
  ids: Array<number>;
};

// ----------------------------------------------------------------------

export const ClassificationsApi = {
  fetchClassifications: async (params?: ClassificationsParams) => {
    const { data } = await httpClient.get<PaginatedList<'items', Classification>>('classifications', { params });

    return data;
  },

  // ----------------------------------------------------------------------

  fetchClassification: async (classificationId: number) => {
    const { data } = await httpClient.get<Classification>(`classifications/${classificationId}`);

    return data;
  },

  // ----------------------------------------------------------------------

  searchClassifications: async (params: ClassificationsSearchParams) => {
    const { data } = await httpClient.get<PaginatedList<'items', ClassificationWithChildren>>(
      'classifications/search',
      { params },
    );

    return data;
  },

  // ----------------------------------------------------------------------

  createClassification: async (formData: ClassificationsFormData) => {
    const { data } = await httpClient.post<Classification>('classifications', formData);

    return data;
  },

  // ----------------------------------------------------------------------

  updateClassification: async (classificationId: number, formData: ClassificationsFormData) => {
    const { data } = await httpClient.put<Classification>(`classifications/${classificationId}`, formData);

    return data;
  },

  // ----------------------------------------------------------------------

  deleteClassification: (params: ClassificationsDeleteParams) =>
    httpClient.delete<void>('classifications', { data: params }),
};
