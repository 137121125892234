import {
  ConfirmationDialogActionCancelButton,
  ConfirmationDialogActionProceedButton,
  ConfirmationDialogActions,
  ConfirmationDialogBodyMutedText,
  ConfirmationDialogContainer,
  ConfirmationDialogContainerProps,
  ConfirmationDialogHeader,
} from '@/components/dialogs/elements.confirmation-dialog';

// ----------------------------------------------------------------------

type Props = Omit<ConfirmationDialogContainerProps, 'subject'> & {
  onCancel: VoidFunction;
  onProceed: VoidFunction;
};

// ----------------------------------------------------------------------

export const ConfirmationDuplicateDialog = (props: Props) => {
  const { onClose, onCancel, onProceed, children, ...restOfDialogProps } = props;

  return (
    <ConfirmationDialogContainer subject="duplicate" onClose={onClose} {...restOfDialogProps}>
      <ConfirmationDialogHeader>В справочник добавляется дубликат</ConfirmationDialogHeader>

      {children}

      <ConfirmationDialogBodyMutedText>Измените одно из полей или отмените операцию</ConfirmationDialogBodyMutedText>

      <ConfirmationDialogActions>
        <ConfirmationDialogActionCancelButton onClick={onCancel}>
          Отменить создание
        </ConfirmationDialogActionCancelButton>
        <ConfirmationDialogActionProceedButton autoFocus onClick={onProceed}>
          Изменить
        </ConfirmationDialogActionProceedButton>
      </ConfirmationDialogActions>
    </ConfirmationDialogContainer>
  );
};
