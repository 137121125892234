import { useFieldArray, useFormContext } from 'react-hook-form';
import { Box, ButtonProps, FormHelperText, FormHelperTextProps, IconButton, Stack, SvgIcon } from '@mui/material';
import { ReactComponent as DeleteIconSVG } from '@/assets/icons-2.0/delete.svg';
import { ReactComponent as AddIcon } from '@/assets/icons-2.0/add.svg';
import { filter, get, keyBy } from 'lodash';
import { RoundedButton } from '../buttons';

export type FieldArrayItemProps = {
  index: number;
  isSingleField: boolean;
};

type FieldsProps = {
  name: string;
  allowAdding?: boolean;
  emptyFieldData: Record<string, unknown> | null;
  addButtonText?: string;
  helperText?: FormHelperTextProps['children'];
  slotProps?: {
    helperText?: FormHelperTextProps;
    button?: ButtonProps;
  };
  renderFieldItem: (props: FieldArrayItemProps) => React.ReactNode;
};

export const RHFFieldArray = (props: FieldsProps) => {
  const {
    name,
    emptyFieldData,
    addButtonText = 'Добавить',
    helperText,
    slotProps,
    renderFieldItem,
    allowAdding = true,
  } = props;

  const {
    control,
    formState: { errors },
  } = useFormContext();
  const { fields, append, remove, update } = useFieldArray({ control, name });

  const fieldsWithError = filter(fields, (_, index) => !!get(errors, [name, index]));
  const fieldsWithErrorById = keyBy(fieldsWithError, 'id');

  const addEmptyField = () => {
    append(emptyFieldData);
  };

  const removeField = (index: number) => {
    if (fields.length > 1) {
      remove(index);
    } else {
      update(index, emptyFieldData);
    }
  };

  return (
    <Stack spacing={2}>
      {fields.map((field, index) => {
        const hasError = !!fieldsWithErrorById[field.id];

        return (
          <Stack key={field.id} direction="row" spacing={2} alignItems="flex-end">
            {renderFieldItem({ index, isSingleField: fields.length === 1 })}

            {allowAdding && fields.length > 1 && (
              <Box sx={{ flexShrink: 0, pb: hasError ? 3.25 : 0 }}>
                <IconButton onClick={() => removeField(index)} data-testid={`${name}-delete-btn`}>
                  <SvgIcon component={DeleteIconSVG} sx={{ color: 'text.secondary' }} inheritViewBox />
                </IconButton>
              </Box>
            )}
          </Stack>
        );
      })}

      {helperText && <FormHelperText {...slotProps?.helperText}>{helperText}</FormHelperText>}

      {allowAdding && (
        <RoundedButton
          data-testid={`${name}-add-btn`}
          variant="outlined"
          onClick={addEmptyField}
          startIcon={<SvgIcon component={AddIcon} sx={{ color: 'text.secondary' }} inheritViewBox />}
          color="secondary"
          sx={{ alignSelf: 'flex-start' }}
          {...slotProps?.button}
        >
          {addButtonText}
        </RoundedButton>
      )}
    </Stack>
  );
};
