import { Controller, ErrorOption, useFormContext } from 'react-hook-form';
import { Gallery, GalleryProps } from '../Gallery';
import { FILE_LIMIT_ERROR } from '@/constants/images.constants';
import { ImagePrefix } from '@/api/domains/image.api';

export const FILE_LIMIT_RHF_ERROR: ErrorOption = {
  type: 'file-limit',
  message: FILE_LIMIT_ERROR,
};

export type RHFGalleryProps = {
  name: string;
  isLoading: GalleryProps['isLoading'];
  onImageRemove: GalleryProps['onImageRemove'];
  onImagesEdit: GalleryProps['onImagesEdit'];
  imagePrefix: ImagePrefix;
};

export const RHFGallery = (props: RHFGalleryProps) => {
  const { name, onImageRemove, onImagesEdit, isLoading, imagePrefix } = props;
  const {
    control,
    setValue,
    formState: { errors },
    clearErrors,
  } = useFormContext();

  const handleEditImage: GalleryProps['onImagesEdit'] = async images => {
    setValue('file', null);

    await onImagesEdit?.(images);
  };

  const handleImageRemove: GalleryProps['onImageRemove'] = async images => {
    if (errors.file?.type === FILE_LIMIT_RHF_ERROR.type) {
      clearErrors('file');
    }

    await onImageRemove?.(images);
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value = [], onChange } }) => (
        <Gallery
          images={value}
          onChange={onChange}
          onImagesEdit={handleEditImage}
          onImageRemove={handleImageRemove}
          isLoading={isLoading}
          imagePrefix={imagePrefix}
        />
      )}
    />
  );
};
