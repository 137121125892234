import type { TypeFilterValue } from '@inovua/reactdatagrid-community/types';
import { DataGrid, type DataGridProps, useDataGridSorting, useResetDataGridFilters } from '@/components/DataGrid';
import { sortMakers } from '@/components/makers/helpers';
import type { MakersDataGridRowData } from '@/components/makers/types';
import { useMakersDataGrid } from '@/components/makers/useMakersDataGrid';
import { useMakersSelection } from '@/components/makers/useMakersSelection';
import {
  selectMakersStoreIsFilterPanelVisible,
  useMakersStore,
  useMakersStoreActions,
} from '@/components/makers/useMakersStore';

export type MakersDataGridBaseProps = Pick<DataGridProps<MakersDataGridRowData>, 'columns' | 'instanceId'>;

export const filterValue: TypeFilterValue = [
  {
    name: 'id',
    type: 'select',
    operator: 'inlist',
    value: null,
  },
  {
    name: 'maker',
    type: 'select',
    operator: 'inlist',
    value: null,
  },
  {
    name: 'country',
    type: 'select',
    operator: 'inlist',
    value: null,
  },
  {
    name: 'notes',
    type: 'select',
    operator: 'inlist',
    value: null,
  },
  {
    name: 'updateDate',
    type: 'date',
    operator: 'inrange',
    value: '',
  },
  {
    name: 'initiator',
    type: 'select-by-id',
    operator: 'inlist',
    value: null,
  },
  {
    name: 'status',
    type: 'select',
    operator: 'inlist',
    value: null,
  },
];

export const MakersDataGridBase = (props: MakersDataGridBaseProps) => {
  const { isFetching, hasNextPage, loadedPagesLength, makers, fetchNextPage } = useMakersDataGrid();

  const isFilterPanelVisible = useMakersStore(selectMakersStoreIsFilterPanelVisible);

  useResetDataGridFilters(props.instanceId, [makers, isFilterPanelVisible]);
  const { dataSource, sortInfo, handleSortInfoChange } = useDataGridSorting(makers, sortMakers);

  const { openEditModal } = useMakersStoreActions();
  const { handleSelectionChange } = useMakersSelection();

  return (
    <DataGrid
      hasPagination
      hasNextPage={hasNextPage}
      pagesLength={loadedPagesLength}
      enableFiltering={isFilterPanelVisible}
      enableColumnFilterContextMenu={false}
      loading={isFetching}
      idProperty="id"
      defaultFilterValue={filterValue}
      dataSource={dataSource}
      sortInfo={sortInfo}
      onRowDoubleClick={openEditModal}
      onSortInfoChange={handleSortInfoChange}
      onSelectionChange={handleSelectionChange}
      onShowMore={fetchNextPage}
      {...props}
    />
  );
};
