import { DeleteOutline as DeleteOutlineIcon } from '@mui/icons-material';
import { ActionButton, ActionButtonProps, ActionIconButtonProps } from '@/components/buttons';
import { DataGridActionDelete } from '@/components/DataGrid';
import {
  useMakersStoreActions,
  useMakersStoreContextWindowsState,
  useMakersStoreIsContextWindowsVisible,
} from '@/components/makers/useMakersStore';
import { useShortcut } from '@/hooks/useShortcut';

// ----------------------------------------------------------------------
// MAIN BUTTON

export const MakersMainDeleteButton = (props: ActionIconButtonProps) => {
  const { disabled } = props;

  const isContextWindowsVisible = useMakersStoreIsContextWindowsVisible();
  const canOpenDeleteDialog = !disabled && !isContextWindowsVisible;

  const { openDeleteDialog } = useMakersStoreActions();

  useShortcut(
    'ctrl + -',
    () => {
      if (canOpenDeleteDialog) {
        openDeleteDialog();
      }
    },
    [canOpenDeleteDialog],
  );

  return <DataGridActionDelete {...props} onClick={openDeleteDialog} />;
};

// ----------------------------------------------------------------------
// INLINE BUTTON

export const MakersInlineDeleteButton = (props: ActionIconButtonProps) => {
  const { onClick } = props;

  const { openDeleteDialog } = useMakersStoreActions();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onClick?.(event);
    openDeleteDialog();
  };

  return <DataGridActionDelete {...props} onClick={handleClick} />;
};

// ----------------------------------------------------------------------
// MODAL BUTTON

export const MakersModalDeleteButton = (props: ActionButtonProps) => {
  const { disabled } = props;

  const { isDuplicateModalVisible, isDeleteDialogVisible } = useMakersStoreContextWindowsState();
  const canOpenDeleteDialog = !disabled && !isDuplicateModalVisible && !isDeleteDialogVisible;

  const { openDeleteDialog } = useMakersStoreActions();

  useShortcut(
    'ctrl + -',
    () => {
      if (canOpenDeleteDialog) {
        openDeleteDialog();
      }
    },
    [canOpenDeleteDialog],
  );

  return (
    <ActionButton
      color="error"
      startIcon={<DeleteOutlineIcon fontSize="small" />}
      onClick={openDeleteDialog}
      {...props}
    >
      Удалить
    </ActionButton>
  );
};
