import { Stack, StackProps } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledRoot = styled(Stack)(({ theme }) => ({
  flexShrink: 0,
  alignItems: 'center',
  height: theme.dimensions.modal.toolbar.height,
  padding: theme.spacing(1),
  borderBottom: theme.shape.divider,
})) as typeof Stack;

export type ModalToolbarProps = Omit<StackProps, 'direction'>;

export const ModalToolbar = (props: ModalToolbarProps) => (
  <StyledRoot direction="row" spacing={1} className="ModalToolbar" {...props} />
);
