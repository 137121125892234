import { useLocalStorage } from 'react-use';
import {
  LOCAL_STORAGE_SCHEME,
  SEARCH_HINT_MAX_COUNT,
  SearchHintName,
  SearchHintsLocalStorageScheme,
} from '@/constants/storage';

const MAX_HINTS_COUNT = SEARCH_HINT_MAX_COUNT - 1;

type UseSearchHints = (name: SearchHintName) => {
  hintList: string[];
  addToHintList: (searchText: string) => void;
};

export const useSearchHints: UseSearchHints = (name: SearchHintName) => {
  const [value, setValue] = useLocalStorage<SearchHintsLocalStorageScheme>(
    'searchHints',
    LOCAL_STORAGE_SCHEME.searchHints,
  );
  const currentHints = value?.[name] || [];

  const addToHintList = (searchText: string) => {
    const searchTextTrimmed = searchText.trim();
    const canAddToHintList =
      Boolean(searchTextTrimmed) && !currentHints.some(hint => hint.toLowerCase() === searchTextTrimmed.toLowerCase());

    if (!canAddToHintList) {
      return;
    }

    setValue(
      (prevState): SearchHintsLocalStorageScheme => ({
        ...prevState,
        [name]: [searchTextTrimmed, ...currentHints.slice(0, MAX_HINTS_COUNT)],
      }),
    );
  };

  return {
    hintList: currentHints,
    addToHintList,
  };
};
