import { Outlet } from 'react-router';
import { styled } from '@mui/material/styles';
import { BaseLayout } from '@/layouts/BaseLayout';

const StyledContainer = styled('main')({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  overflow: 'auto',
});

export const MainLayout = () => (
  <BaseLayout>
    <StyledContainer>
      <Outlet />
    </StyledContainer>
  </BaseLayout>
);
