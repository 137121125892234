import { FormControl } from '@mui/material';
import { CustomFormLabel, type CustomFormLabelProps } from './CustomFormLabel';
import { RHFContractorSelectField, type RHFContractorSelectFieldProps } from './RHFContractorSelectField';
import { MARKETING_ORGANISATION_FIELD_FETCH_PARAMS } from '@/constants/form-fields.constants';

type RHFMarketingOrgFieldProps = Omit<RHFContractorSelectFieldProps<false, false, false>, 'name'> & {
  name?: RHFContractorSelectFieldProps<false, false, false>['name'];
  isRequired?: CustomFormLabelProps['isRequired'];
};

export const RHFMarketingOrgField = (props: RHFMarketingOrgFieldProps) => {
  const { isRequired, name = 'marketingOrg', ...contractorSelectProps } = props;

  return (
    <FormControl fullWidth>
      <CustomFormLabel htmlFor="marketingOrg" isRequired={isRequired}>
        Маркетирующая организация
      </CustomFormLabel>
      <RHFContractorSelectField
        disablePortal
        name={name}
        id={name}
        TextFieldProps={{ variant: 'filled' }}
        fetchParams={MARKETING_ORGANISATION_FIELD_FETCH_PARAMS}
        {...contractorSelectProps}
      />
    </FormControl>
  );
};
